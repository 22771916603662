
/* -----------------------------------------------------------------------------HOME ------------------ */

#home-posts .owl-controls {
  margin-top: 19px;
}

#home-posts .owl-controls .owl-page span {
  width: 52px;
  height: 5px;
  border-radius: 0px;
  opacity: 1;
  background: #eeeeee;
}

#home-posts .owl-controls .owl-page span:hover {
  background: #dddddd;
}

.home-info {
  padding: 100px 0 90px;
}

.home-info-content {
  margin-bottom: 30px
}

.home-info-content h4 {
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 21px 0 20px;
}

.home-info-content h4 a {
  color: #222;
}

.home-info-content h4 i {
  font-size: 26px;
  margin-right: 9px;
}

.home-info-content p {
  margin-bottom: 20px;
}

.home-info-thumb {
  position: relative;
}

.home-info-thumb i {
  color: #FFF;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.home-info-thumb:hover i {
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.home-quotes {
  background: url(../images/bg/2.jpg) no-repeat center center;
  background-size: cover;
  padding: 34px 0px 106px;
}

.home-quotes2 {
  background: url(../images/bg/test-bg.jpg) no-repeat center center;
}

.quote-info {
  max-width: 780px;
  margin: 0px auto;
  padding-top: 6px;
}

.quote-info p {
  font-size: 21px;
  font-style: italic;
  line-height: 1.71;
  color: #FFF;
}

.quote-info i {
  border-radius: 50%;
  color: #FFF;
  font-size: 16px;
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 23px auto 49px;
}

.quote-info cite {
  font-size: 14px;
  line-height: 19px;
  color: #FFF;
  font-style: normal;
}

.quote-info cite span {
  font-size: 12px;
  color: #8F8F8F;
  display: block;
}

#home-quotes .owl-controls {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  left: 5px;
  right: 0;
  bottom: 71px;
}

#home-quotes .owl-prev {
  opacity: 0.5;
  border-radius: 0px;
  background: transparent;
  font-size: 0;
  position: relative;
  padding: 0 13px;
}

#home-quotes .owl-prev:after {
  content: "\f177";
  font-family: 'FontAwesome';
  font-size: 21px;
  display: block;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
}

#home-quotes .owl-next {
  opacity: 0.5;
  border-radius: 0px;
  background: transparent;
  font-size: 0;
  position: relative;
  padding: 0 13px;
}

#home-quotes .owl-next:after {
  content: "\f178";
  font-family: 'FontAwesome';
  font-size: 21px;
  display: block;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
}

#home-quotes .owl-prev:hover,
#home-quotes .owl-next:hover {
  opacity: 1;
}

.home-projects-full {
  padding: 43px 0px 102px;
}

.home-projects-full-filter {
  padding: 34px 0px 102px;
}

.space-top-10 {
  margin-top: 10px;
}

.space-top-20 {
  margin-top: 20px;
}

.space-top-30 {
  margin-top: 30px;
}

.space-top-40 {
  margin-top: 40px;
}

.space-top-50 {
  margin-top: 50px;
}

.space-top-60 {
  margin-top: 60px;
}

.space-top-70 {
  margin-top: 70px;
}

.space-top-80 {
  margin-top: 80px;
}

.space-top-15 {
  margin-top: 15px;
}

.space-top-25 {
  margin-top: 25px;
}

.space-top-35 {
  margin-top: 35px;
}

.space-top-45 {
  margin-top: 45px;
}

.space-top-55 {
  margin-top: 55px;
}

.counter-box {
  background: url(../images/pattern.jpg);
  padding: 80px 0px 90px;
}

.home-features-side .feature-box {
  width: 50%;
  float: left;
}

.home-features-side .feature-box p {
  padding-left: 0;
  line-height: 24px;
  margin-bottom: -21px;
}

.home-features-side .feature-box h3 {
  padding-left: 49px;
  margin: 0 0 24px;
}

.home-features-side .feature-box-ico {
  display: block;
  height: 40px;
  width: 40px;
  float: left;
  text-align: center;
  background-color: transparent;
  border: 0 none !important;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  font-size: 40px;
  line-height: 0;
}

.home-features-side .feature-box:hover .feature-box-ico {
  background-color: transparent;
  border: 0 none;
  color: #222;
}

.home-projects.projects-content .folio-filter-wrap {
  background: transparent !important;
}

.home-projects.projects-content .folio-filter li a {
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .folio-filter li a {
    line-height: 29px;
    padding: 0px 12px;
    font-size: 9px;
  }
}

@import "home/info-projects";
@import "home/portfolio";
@import "home/profile";