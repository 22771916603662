/* -----------------------------------------------------------------------------TOPBAR ------------------ */
.top-bar {
  background-color: rgb(250,250,250);
  border-bottom: 1px solid rgb(240,240,240);
  height: 40px;
  .dropdown-menu>li a,
  .mega-dropdown-menu > li > ul > li > a {
     background: #fff !important;
    &:hover {
      color: $default-theme-color !important;
    }
  }
  .dropdown-menu>li.active a {
    color: $default-theme-color !important;
  }
}

.top-bar ul.links {
  margin: 0px;
  padding: 0px;
}

.top-bar ul.links li {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  list-style-type: none;
  padding-right: 20px;
}

.top-bar ul.links li a {
  color: #fff;
  text-decoration: none;
}

.top-bar ul.links li a i {
  padding-right: 4px;
  font-size: 16px;
  text-decoration: none;
}

.top-bar ul.links li a:hover {
  color: #b2b2b2;
  text-decoration: none;
}

.top-bar ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.top-bar ul.social-icons li {
  font-size: 14px;
  color: #fff;
  display: inline-block;
  list-style-type: none;
  padding-left: 12px;
  text-align: center;
}

.top-bar ul.social-icons li a {
  color: #fff;
}

.top-bar-02 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.top-bar-02 ul.links {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.top-bar-02 ul.links li {
  display: inline-block;
  font-size: 12px;
  color: #222;
  list-style-type: none;
  padding-right: 20px;
}

.top-bar-02 ul.links li a {
  color: #222;
  text-decoration: none;
}

.top-bar-02 ul.links li a i {
  padding-right: 4px;
  font-size: 16px;
  text-decoration: none;
}

.top-bar-02 ul.links li a:hover {
  color: #b2b2b2;
  text-decoration: none;
}

.top-bar-02 .button {
  text-align: right;
}

.top-bar-02 .navbar-brand {
  float: left;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
}

