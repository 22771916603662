@charset "UTF-8";
a,
.top-bar ul.links li a i,
.top-bar ul.social-icons li a:hover,
.top-bar-02 ul.links li a i,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-nav > li > a:hover,
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.navbar-default .navbar-nav > li a:hover i,
.searchlink,
.navigation-02 ul.social-icons li a:hover,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.mega-dropdown-menu > li > ul > li > a:hover,
.dropdown-submenu a:hover,
.dropdown-submenu:hover,
.nav .open.dropdown-submenu > a,
.nav .open.dropdown-submenu > a:focus,
.nav .open.dropdown-submenu > a:hover.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover,
.header-3 .main-menu-custom-text > i.fa,
.tb-left ul li i,
.tb-right ul li i,
.tp-caption.darnaverylargetextprimary,
.darnaverylargetextprimary,
.s1-box h5 a,
.project-filter a:hover,
.project-filter a.active,
.project-filter a i,
.project-overlay h4 a:hover,
.project-overlay .project-expand,
.video-content h4,
.blog-excerpt h3 a:hover,
.blog-meta a:hover,
.home-info-content h4 a:hover,
.home-info-content h4 i,
.home-info-thumb:hover i,
.counter-box-info .count,
.list li:before,
.s1-box h5,
.s3-box-ico,
.project-overlay-dark .project-expand:hover,
.error-content h2,
.error-social a:hover,
.sidebar ul.product_list_widget li a:hover,
.shopcart-table .media-body a:hover,
.shopcart-table .btn:hover,
table.shop_table.cart tbody td.product-name .product-name-wrap .product-name-inner a:hover,
.primary-color,
.result-count b,
.footer-widget a:hover,
.footer-newsletter button,
.footer-menu li.active a,
.footer-menu li a:hover,
.footer-copyright p a:hover,
.tweets_txt:before,
.home-features-side .feature-box-ico,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #02A8EB;
}

.navigation-03.navbar-inverse,
.navigation-03.navbar-inverse,
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navigation-04.navbar-inverse,
.navigation-04 .navbar-inverse .navbar-nav > .active > a,
.navigation-04 .navbar-inverse .navbar-nav > .active > a:focus,
.navigation-04 .navbar-inverse .navbar-nav > .active > a:hover,
.search-form button[type=submit],
.header-customize-item .get-a-quote-button,
.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button:hover,
.header-5 .navbar-brand,
ul.vertical-images li,
.darna-button.style1,
.darna-button.style2:hover,
.darna-button.style3:hover,
.darna-button.style4:hover,
.bttn,
.project-overlay .project-view,
#home-posts .owl-controls .owl-page.active span,
.quote-info i,
.home-profile h3 i,
.s2-box-ico,
.quote-info2 i,
.error-search button,
.process-box:hover,
.spinner,
.blog-inner .entry-p-format-icon,
.darna-post .darna-post-image .entry-p-format-icon,
.blog-inner .entry-p-format-icon:after,
.darna-post .darna-post-image .entry-p-format-icon:after,
.blog-paging-wrapper .pagination > li > a.current,
.unused-pagination .pagination > li > a.current,
.blog-paging-wrapper .pagination > li > span.current,
.unused-pagination .pagination > li > span.current,
.blog-paging-wrapper .pagination > li > a:hover,
.unused-pagination .pagination > li > a:hover,
.blog-paging-wrapper .pagination > li > span:hover,
.unused-pagination .pagination > li > span:hover,
.blog-paging-wrapper .pagination > li > a:active,
.unused-pagination .pagination > li > a:active,
.blog-paging-wrapper .pagination > li > span:active,
.unused-pagination .pagination > li > span:active,
.blog-paging-wrapper .pagination > li > a:focus,
.unused-pagination .pagination > li > a:focus,
.blog-paging-wrapper .pagination > li > span:focus,
.unused-pagination .pagination > li > span:focus,
.sidebar .widget-social-profile.social-icon-bordered li a:hover i.fa,
h4.widget-title:after,
.modal-header .popup-close,
.entry-content input[type=submit],
.comment-text input[type=submit],
.cart table.cart td.actions .button:hover,
.shipping-calculator .button:hover,
.cart table.cart td.actions input.button:hover,
.shipping-calculator input.button:hover,
.cart table.cart td.actions button.button:hover,
.shipping-calculator button.button:hover,
.cart table.cart td.actions .wc-forward,
.shipping-calculator .wc-forward,
.pagination > li + li > a:focus,
.pagination li a.active,
.pagination > li > a:hover {
  background-color: #02A8EB;
}

body::-webkit-scrollbar-thumb {
  background-color: #02A8EB;
}

.shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon > i.fa-shopping-cart + span,
#search .btn,
#quote .btn,
#search .close,
#quote .close,
#search .close,
#quote .close,
.bttn-black:hover,
.bttn-bdr-white:hover,
.bttn-bdr-black:hover,
#home-projects .owl-prev:hover,
#home-projects .owl-next:hover,
.blog-meta:after,
.team-social li a:hover,
.team-staff:hover .staff-info,
#team-wrap .owl-prev:hover,
#team-wrap .owl-next:hover,
#service-box3 .owl-prev:hover,
#service-box3 .owl-next:hover,
.folio-filter li a.active,
.folio-filter li a:hover,
.nav-tabs li.active:after,
#accordion .panel-heading.actives a,
.feature-box:hover .feature-box-ico,
.sidebar .tagcloud a:hover,
.ui-slider .ui-slider-range {
  background: #02A8EB;
}

.navigation-03.navbar-inverse,
.navigation-04.navbar-inverse,
.search-form input[type=text]:focus,
.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button:hover,
#search .btn,
#quote .btn,
.darna-button.style1,
.darna-button.style2:hover,
.darna-button.style3:hover,
.darna-button.style4:hover,
.bttn-black:hover,
.bttn-bdr-white:hover,
.bttn-bdr-black:hover,
.error-search input:focus,
.blog-paging-wrapper .pagination > li > a.current,
.unused-pagination .pagination > li > a.current,
.blog-paging-wrapper .pagination > li > span.current,
.unused-pagination .pagination > li > span.current,
.blog-paging-wrapper .pagination > li > a:hover,
.unused-pagination .pagination > li > a:hover,
.blog-paging-wrapper .pagination > li > span:hover,
.unused-pagination .pagination > li > span:hover,
.blog-paging-wrapper .pagination > li > a:active,
.unused-pagination .pagination > li > a:active,
.blog-paging-wrapper .pagination > li > span:active,
.unused-pagination .pagination > li > span:active,
.blog-paging-wrapper .pagination > li > a:focus,
.unused-pagination .pagination > li > a:focus,
.blog-paging-wrapper .pagination > li > span:focus,
.unused-pagination .pagination > li > span:focus,
.sidebar .widget_archive li.current-cat:before,
.sidebar .widget_categories li.current-cat:before,
.sidebar .widget_product_categories li.current-cat:before,
.sidebar .widget_nav_menu > div li.current-cat:before,
.sidebar .widget_meta li.current-cat:before,
.sidebar .widget_pages li.current-cat:before,
.sidebar .widget_recent_comments li.current-cat:before,
.sidebar .widget_recent_entries li.current-cat:before,
.sidebar .widget_rss li.current-cat:before,
.sidebar .widget_archive li.current-menu-item:before,
.sidebar .widget_categories li.current-menu-item:before,
.sidebar .widget_product_categories li.current-menu-item:before,
.sidebar .widget_nav_menu > div li.current-menu-item:before,
.sidebar .widget_meta li.current-menu-item:before,
.sidebar .widget_pages li.current-menu-item:before,
.sidebar .widget_recent_comments li.current-menu-item:before,
.sidebar .widget_recent_entries li.current-menu-item:before,
.sidebar .widget_rss li.current-menu-item:before,
.sidebar .widget-social-profile.social-icon-bordered li a:hover i.fa,
.entry-content input[type=submit],
.comment-text input[type=submit],
.border-primary-color,
.border-primary-color:after,
.border-primary-color:before,
.pagination > li + li > a:focus,
.pagination li a.active,
.pagination > li > a:hover,
.bttn,
.content-head:before,
.content-head:after,
.ui-slider .ui-slider-handle {
  border-color: #02A8EB;
}

.nav-tabs li.active:before,
.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper,
.project-filter:before,
.project-filter:after,
#accordion .panel-heading.actives a,
.contact-form form input:focus,
.contact-form form textarea:focus {
  border-top-color: #02A8EB;
}

.blog-inner article.format-quote .entry-c-quote blockquote,
.darna-post .darna-post-image article.format-quote .entry-c-quote blockquote,
.entry-content blockquote,
.comment-text blockquote,
.entry-c-quote blockquote {
  border-left-color: #02A8EB;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-nav > li > a:hover,
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  border-bottom-color: #02A8EB;
}

.bg-theme-color {
  background-color: #02A8EB !important;
}

.bg-theme-color h2 a {
  color: #fff !important;
  text-shadow: 2px 2px #016086;
}

.bg-theme-color i {
  color: #000 !important;
  opacity: 0.1;
}

.bg-theme-color:hover i {
  color: #fff !important;
  opacity: 0.5;
}

/* -----------------------------------------------------------------------------RESET ------------------ */
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #19394B;
}

:root {
  overflow-x: hidden !important;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  color: #8f8f8f;
}

p {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #8F8F8F;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  color: #222;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover,
a:focus,
input,
input:focus,
button,
button:focus,
textarea,
textarea:focus {
  outline: 0;
  text-decoration: none;
}

table th p, table th {
  font-weight: bold;
  margin-top: 5px !important;
}

table td p {
  margin-top: 5px !important;
}

/* -----------------------------------------------------------------------------PRELOADER ------------------ */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* change if the mask should be a color other than white */
  z-index: 9999999;
  /* makes sure it stays on top */
}

#status {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background: #FFF;
  height: 36px;
  width: 36px;
  margin-left: -18px;
  margin-top: -18px;
}

/* -----------------------------------------------------------------------------TOPBAR ------------------ */
.top-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  height: 40px;
}

.top-bar .dropdown-menu > li a,
.top-bar .mega-dropdown-menu > li > ul > li > a {
  background: #fff !important;
}

.top-bar .dropdown-menu > li a:hover,
.top-bar .mega-dropdown-menu > li > ul > li > a:hover {
  color: #02A8EB !important;
}

.top-bar .dropdown-menu > li.active a {
  color: #02A8EB !important;
}

.top-bar ul.links {
  margin: 0px;
  padding: 0px;
}

.top-bar ul.links li {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  list-style-type: none;
  padding-right: 20px;
}

.top-bar ul.links li a {
  color: #fff;
  text-decoration: none;
}

.top-bar ul.links li a i {
  padding-right: 4px;
  font-size: 16px;
  text-decoration: none;
}

.top-bar ul.links li a:hover {
  color: #b2b2b2;
  text-decoration: none;
}

.top-bar ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.top-bar ul.social-icons li {
  font-size: 14px;
  color: #fff;
  display: inline-block;
  list-style-type: none;
  padding-left: 12px;
  text-align: center;
}

.top-bar ul.social-icons li a {
  color: #fff;
}

.top-bar-02 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.top-bar-02 ul.links {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.top-bar-02 ul.links li {
  display: inline-block;
  font-size: 12px;
  color: #222;
  list-style-type: none;
  padding-right: 20px;
}

.top-bar-02 ul.links li a {
  color: #222;
  text-decoration: none;
}

.top-bar-02 ul.links li a i {
  padding-right: 4px;
  font-size: 16px;
  text-decoration: none;
}

.top-bar-02 ul.links li a:hover {
  color: #b2b2b2;
  text-decoration: none;
}

.top-bar-02 .button {
  text-align: right;
}

.top-bar-02 .navbar-brand {
  float: left;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
}

/* -----------------------------------------------------------------------------NAVIGATION ------------------ */
.navbar-brand {
  float: left;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 999999;
  position: relative;
}

.navbar-brand img {
  max-height: 60px;
}

.navbar {
  position: relative;
  min-height: auto;
  margin-bottom: 0px;
  border: 0px solid transparent;
  border-radius: 0px;
}

.navbar-default {
  border-color: #fff;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-nav > li > a:hover,
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.navbar-nav > li > a {
  text-transform: uppercase;
  color: #222222;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}

.navbar-default .navbar-nav > li a i {
  color: #333;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.navbar-default .navbar-nav > li a:hover i {
  opacity: 0.8;
}

.searchlink {
  display: block;
  position: relative;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 70px;
  line-height: 75px;
  margin-left: 15px;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.searchlink:hover,
.searchlink.open {
  color: #ccc;
}

.searchlink.open > .searchform {
  display: block;
}

/** search field **/
.searchform {
  display: none;
  position: absolute;
  width: 300px;
  height: 50px;
  line-height: 30px;
  top: 78px;
  right: -25px;
  padding: 0 15px;
  cursor: default;
  z-index: 10;
}

.searchform:hover i {
  color: #000;
}

.searchlink.open .searchform {
  display: block;
}

#search {
  display: block;
  position: relative;
  font-size: 16px;
}

#s {
  width: 270px;
  background: #f5f5f5;
  padding: 8px 11px;
  border: 0;
  color: #000;
  border-radius: 2px;
}

.sbtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 7px;
  background: none;
  border: none;
  color: #bcbcbc;
  font-size: 20px;
  cursor: pointer;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
  font-size: 12px;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  font-size: 12px;
}

.navigation-02 ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.navigation-02 ul.social-icons li {
  font-size: 16px;
  color: #d9d9d9;
  display: inline-block;
  list-style-type: none;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}

.navigation-02 ul.social-icons li a {
  font-size: 16px;
  color: #d9d9d9;
  padding-right: 0px;
  padding-left: 0px;
}

.navigation-03 .navbar-nav > li > a {
  padding-top: 18px;
  padding-bottom: 13px;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  border-top: 3px solid transparent;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  border-top: 3px solid #fff;
}

.navigation-03.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
  color: #fff;
  font-size: 12px;
}

.navigation-03 ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.navigation-03 ul.social-icons li {
  color: #d9d9d9;
  display: inline-block;
  list-style-type: none;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}

.navigation-03 ul.social-icons li a {
  font-size: 16px;
  color: #fff;
  padding-right: 0px;
  padding-left: 0px;
}

.navigation-03 ul.social-icons li a:hover {
  color: #fff;
}

.navigation-04 .navbar-nav > li > a {
  padding-top: 18px;
  padding-bottom: 13px;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  border-top: 3px solid transparent;
}

.navigation-04 .navbar-inverse .navbar-nav > .active > a,
.navigation-04 .navbar-inverse .navbar-nav > .active > a:focus,
.navigation-04 .navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  border-top: 3px solid #fff;
}

.navigation-04.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
  color: #fff;
  font-size: 12px;
}

.navigation-04 ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.navigation-04 ul.social-icons li {
  color: #d9d9d9;
  display: inline-block;
  list-style-type: none;
  padding-right: 10px;
  padding-left: 8px;
  text-align: center;
}

.navigation-04 ul.social-icons li a {
  font-size: 16px;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 8px;
  padding-right: 0px;
  padding-left: 0px;
}

.navigation-04 ul.social-icons li a:hover {
  color: #fff;
}

.navigation-04 .searchlink {
  display: block;
  position: relative;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin-left: 15px;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.navigation-04 .searchlink:hover,
.navigation-04 .searchlink.open {
  color: #ccc;
}

.navigation-04 .searchlink.open > .searchform {
  display: block;
}

/** search field **/
.navigation-04 .searchform {
  display: none;
  position: absolute;
  width: 300px;
  height: 50px;
  line-height: 30px;
  top: 55px;
  right: -25px;
  padding: 0 15px;
  cursor: default;
  z-index: 10;
}

.navigation-04 .searchform:hover i {
  color: #000;
}

.navigation-04 .searchlink.open .searchform {
  display: block;
}

nav.navigation-07 {
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
}

nav.navigation-07.navbar-default {
  background-color: transparent;
  border-bottom: 1px solid;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

nav.navigation-07.navbar-default .navbar-nav {
  width: 100%;
  text-align: center;
}

nav.navigation-07.navbar-default .navbar-nav > li {
  float: none;
  display: inline-block;
}

nav.navigation-07.navbar-default .navbar-nav > li:first-child {
  min-width: 200px;
  padding-right: 30px;
  border-right: 1px solid;
  border-right-color: rgba(255, 255, 255, 0.5);
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child {
  min-width: 100px;
  margin-left: 150px;
  border-left: 1px solid;
  border-left-color: rgba(255, 255, 255, 0.5);
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child ul li {
  display: inline-block;
  text-align: right;
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child ul li a i {
  color: #fff !important;
  font-size: 20px;
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child ul li a:hover {
  opacity: 0.8;
}

nav.navigation-07.navbar-default .navbar-nav .searchlink {
  display: block;
  position: relative;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 80px;
  line-height: 80px;
  margin-left: 15px;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

nav.navigation-07.navbar-default .navbar-nav > li > a.navbar-brand {
  padding-top: 20px;
  padding-bottom: 16px;
}

nav.navigation-07.navbar-default .navbar-nav > li > a {
  color: #fff;
}

nav.navigation-07.navbar-default .navbar-nav > .active > a,
nav.navigation-07.navbar-default .navbar-nav > .active > a:focus,
nav.navigation-07.navbar-default .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: transparent;
  border-bottom: 4px solid #fff;
}

nav.fill-black.normal {
  background: rgba(20, 20, 20, 0);
  position: fixed;
  width: 100%;
  z-index: 999999;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

nav.fill-black.sticky {
  position: fixed;
  width: 100%;
  background: #141414;
  z-index: 999999;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

nav.navigation-07.navbar-default ul.nav .active a {
  color: #fff;
  background-color: transparent;
  border-bottom: 4px solid #fff;
}

nav.navigation-10 {
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
}

nav.navigation-10.navbar-default {
  background-color: transparent;
  border-bottom: 1px solid;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

#search {
  display: block;
  position: relative;
  font-size: 16px;
}

#s {
  width: 225px;
  background: #f5f5f5;
  padding: 8px 11px;
  border: 0;
  color: #000;
  border-radius: 2px;
}

.sbtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 7px;
  background: none;
  border: none;
  color: #bcbcbc;
  font-size: 20px;
  cursor: pointer;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent;
}

.search-form {
  position: relative;
}

.search-form input[type=text] {
  width: 100%;
  padding-right: 48px;
  height: 38px;
  border-color: #F3F3F3;
}

.search-form input[type=text]:focus + button {
  background-color: #222;
}

.search-form button[type=submit] {
  position: absolute;
  border: none;
  right: 0;
  top: 0;
  padding: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  font-size: 14px;
}

.search-form button[type=submit]:hover {
  background-color: #222;
}

@media screen and (max-width: 500px) {
  .navbar-brand img {
    margin: 5px 0;
    height: 40px;
  }
}

/* -----------------------------------------------------------------------------MENU ------------------ */
.dropdown-menu {
  padding: 0;
  margin: 0;
  font-size: 14px;
  /*border: solid 1px #eee;*/
  border-radius: 0;
  border: none;
  background-color: #02A8EB;
  position: absolute;
}

.dropdown-submenu .dropdown-menu {
  position: absolute;
  min-width: 350px;
}

.dropdown-submenu .dropdown-menu li a {
  white-space: nowrap;
  /* width: auto;
      display: inline-block; */
}

.dropdown-menu > li a.dropdown-toggle::before,
.mega-dropdown-menu > li > ul > li > a::before {
  content: "\f105";
  font-family: 'FontAwesome';
  padding-right: 5px;
}

.dropdown-menu > li a,
.mega-dropdown-menu > li > ul > li > a {
  font-family: "Lato", sans-serif;
  color: #fff;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-size: 12px;
  padding: 7px 15px;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: 0;
  /*-webkit-transition: 0.6s all ease;
  transition: 0.6s all ease;*/
  background: #02A8EB;
}

.dropdown-menu li + li > a {
  border-top: solid 1px #0284b8;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.mega-dropdown-menu > li > ul > li > a:hover {
  text-decoration: none;
  /*margin-left: 12px;*/
  /*-webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;*/
  color: #fff;
  background: #626262;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a:hover,
.dropdown-submenu:hover,
.nav .open.dropdown-submenu > a,
.nav .open.dropdown-submenu > a:focus,
.nav .open.dropdown-submenu > a:hover {
  border-bottom: 0 none transparent !important;
  border-color: transparent !important;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  border-top: solid 1px #626262;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.mega-dropdown {
  position: static;
}

.mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}

.mega-dropdown-menu > li > ul > li {
  list-style: none;
}

.mega-dropdown-menu > li > ul > li > a {
  display: block;
  padding: 3px 5px;
}

.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
}

.mega-dropdown-menu .dropdown-header {
  text-transform: uppercase;
  padding: 5px 60px 5px 0px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  letter-spacing: 0.01em;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  color: #222;
}

.mega-dropdown-menu .dropdown-header:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  border-top-width: 4px;
  border-style: solid;
}

ul.dropdown-menu.mega-dropdown-menu {
  margin-top: 24px;
}

.navbar-default .navbar-nav > li > a {
  padding: 40px 0px;
  height: 100px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  letter-spacing: -0.01px;
  margin-right: 10px;
}

@media screen and (min-width: 992px) {
  .x-caret:before {
    content: "\203A";
    font-family: monospace, tahoma;
    display: inline-block;
    color: #666;
    font-size: 16px;
    line-height: 14px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-left: 6px;
  }
  .y-caret:before {
    content: "\203A";
    font-family: monospace, tahoma;
    display: inline-block;
    color: #666;
    font-size: 16px;
    line-height: 14px;
    margin-left: 6px;
  }
}

.header-customize-item {
  font-size: 14px;
  float: right;
  margin: 0 0 0 20px !important;
}

.cart_list_wrapper {
  display: none;
}

@media screen and (max-width: 1200px) {
  .navbar-default .navbar-nav > li > a {
    padding: 40px 0px;
  }
  .header-customize-item {
    margin: 0 0 0 8px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1075px) {
  .navbar-default .navbar-nav > li > a {
    padding: 40px 0px;
  }
}

header .collapse.in {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 99;
  background-color: #fff;
}

.burger-menu {
  cursor: pointer;
  margin: 0 auto;
  padding-left: 1.25em;
  position: absolute;
  width: auto;
  height: auto;
  z-index: 999;
  background: transparent;
  border: 0;
  top: 20px;
  right: 3%;
}

.burger-menu:before {
  content: "\f0c9";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-size: 36px;
  position: relative;
  /* right: 29px;*/
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  background: #626262;
}

/*menu*/
.overlay:last-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
  color: white;
  display: inline-block;
}

.easy-sidebar-toggle {
  display: none;
}

@media screen and (max-width: 1000px) {
  .easy-sidebar-toggle {
    display: block;
  }
  .top-bar {
    /*display: none;*/
  }
  .dropdown-menu > li a,
  .mega-dropdown-menu > li > ul > li > a {
    color: #777 !important;
    background: none;
  }
}

.navbar.easy-sidebar .nav.navbar-nav > li > a {
  height: auto;
}

.navbar.easy-sidebar .nav.navbar-nav > li > a:hover {
  border-bottom: 0 transparent none;
}

.navbar.easy-sidebar .burger-menu {
  top: 15px;
}

.navbar.easy-sidebar .burger-menu:before {
  content: "\f00d";
  color: #fff;
  font-size: 30px;
}

header .navbar.easy-sidebar .burger-menu:before,
header.header-4 .navbar.easy-sidebar .burger-menu:before {
  color: #222;
}

header.header-2 .navbar.easy-sidebar .burger-menu:before,
header.header-3 .navbar.easy-sidebar .burger-menu:before,
header.header-5 .navbar.easy-sidebar .burger-menu:before {
  color: #fff;
}

.header-3.shrink.sticky-header {
  height: 92px;
}

.navbar.easy-sidebar {
  padding: 0;
}

.navbar.easy-sidebar .header-customize {
  display: none;
}

.navbar.easy-sidebar #search {
  opacity: 1;
  position: relative;
  left: 0;
  height: 50px;
  width: 100%;
  -webkit-transform: initial;
  transform: initial;
  background: #f1f1f1;
}

.navbar.easy-sidebar #search input[type=search] {
  top: 70px;
  color: #333;
  margin-top: -55px;
  padding-left: 11px;
  padding-right: 20px;
  font-size: 14px;
  text-align: left;
}

.navbar.easy-sidebar #search .btn {
  position: absolute;
  top: 5px;
  left: auto;
  right: 15px;
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
  background-color: transparent;
  border-color: transparent;
  width: 11px;
  color: #333;
}

.navbar.easy-sidebar .nav.navbar-nav {
  margin: 7.5px 0px;
  width: 100%;
}

/* -----------------------------------------------------------------------------HEADER ------------------ */
.header-customize {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0px;
  font-size: 0px;
  color: #222;
  line-height: 100px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.header-customize-item {
  font-size: 14px;
  position: relative;
}

.header-customize-item .icon-search-menu {
  display: block;
}

.header-customize-item .custom-text-wrapper,
.header-customize-item .get-a-quote-button {
  display: inline-block;
  vertical-align: middle;
}

.header-customize-item .get-a-quote-button {
  height: 38px;
  line-height: 38px;
  color: #fff;
  padding: 0 15px;
  text-transform: uppercase;
}

.header-customize-item .get-a-quote-button i {
  color: #fff !important;
}

.header-customize-item .get-a-quote-button > i.fa + span {
  margin-left: 5px;
}

.header-customize-item .header-customize-item {
  display: inline-block;
  vertical-align: middle;
}

.header-customize-item .header-customize-item + .header-customize-item {
  margin-left: 30px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon > i.fa-shopping-cart + span {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  font-weight: normal;
  color: #fff;
  font-size: 10px;
  top: 50%;
  margin-top: -20px;
  right: -12px;
  text-align: center;
  line-height: 17px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  header.header-1 .header-customize-item + .header-customize-item,
  header.header-1 .header-customize,
  header.header-1 .x-nav-menu > li.x-menu-item + li {
    margin-left: 20px;
  }
  header.header-4 .x-nav-menu > li.x-menu-item + li {
    margin-left: 20px;
  }
  header.header-4 .header-customize {
    margin-left: 35px;
  }
}

.shopping-cart-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  position: relative;
}

.shopping-cart-wrapper .widget_shopping_cart_content {
  display: block;
  text-align: left;
}

.shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon {
  position: relative;
  display: inline-block;
}

.shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon > i.fa-shopping-cart {
  cursor: pointer;
  padding: 0 15px 0 0;
}

.shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon > i.fa-shopping-cart + span {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  font-weight: normal;
  color: #fff;
  font-size: 10px;
  top: 50%;
  margin-top: -20px;
  right: 0;
  text-align: center;
  line-height: 17px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 9998;
  min-width: 300px;
  background: #F8F8F8;
  border: solid 1px #eee;
  border-top-width: 2px;
  border-top-style: solid;
  padding: 0;
  font-family: "Lato", sans-serif;
  line-height: 2em;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper .cart-total {
  padding: 0 15px 20px;
  position: relative;
  color: #222;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li.empty {
  padding: 15px;
  text-align: center;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li.empty h4 {
  text-transform: uppercase;
  color: #222;
  padding: 0 0 15px;
  margin: 0;
  font-size: 16px;
  word-spacing: 0.2em;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li.empty p {
  color: #8f8f8f;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li {
  padding: 15px 0;
  margin: 0;
  position: relative;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li + li {
  border-top: solid 1px #eee;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-left {
  float: left;
  overflow: hidden;
  margin-right: 10px;
  border: solid 1px #eee;
  background: #fff;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-left > a > img {
  height: auto;
  width: 58px;
  margin: 0;
  display: block;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-right {
  overflow: hidden;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-right > a {
  text-transform: uppercase;
  word-spacing: 0.2em;
  color: #222;
  font-size: 12px;
  font-weight: 700;
  display: block;
  padding-right: 15px;
  margin-bottom: 6px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-right > span.quantity {
  font-size: 12px;
  font-weight: 700;
  display: block;
  text-align: right;
  color: #8f8f8f;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-right > span.quantity .amount {
  float: left;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li .cart-right > a.mini-cart-remove {
  position: absolute;
  right: 0;
  top: 15px;
  padding-right: 0;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper li:after {
  content: "";
  display: block;
  clear: both;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.total {
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  line-height: 43px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.total strong {
  text-transform: uppercase;
  font-weight: 400;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.total > span.amount {
  font-weight: 700;
  float: right;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons {
  text-align: center;
  margin: 0;
  padding: 15px 0 0;
  font-size: 0;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button {
  color: #fff;
  background: #222;
  border-color: #222;
  border-radius: 0;
  line-height: 20px;
  padding: 5px 0;
  font-size: 12px;
  width: 48%;
  display: inline-block;
  text-transform: uppercase;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button > i.fa {
  margin-right: 5px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button + .button {
  margin-left: 4%;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper dl {
  border: none;
  margin: 0;
  padding: 0;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper dl dt {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
  line-height: 25px;
  clear: none;
  font-size: 11px;
  font-weight: 400;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper dl dd {
  padding: 0;
  line-height: 25px;
  display: block;
  float: none;
  margin: 0;
  font-size: 11px;
}

.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper dl p {
  margin: 0;
  padding: 0;
}

.shopping-cart-wrapper .widget_shopping_cart_content ul.cart_list,
.shopping-cart-wrapper .widget_shopping_cart_content ul.product_list_widget {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 15px 15px 0;
}

.shopping-cart-wrapper .widget_shopping_cart_content:hover .cart_list_wrapper {
  display: block;
}

@media screen and (min-width: 992px) {
  .nav-menu-toggle-wrapper {
    display: none;
  }
}

.sticky-header {
  position: fixed !important;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  height: 100px;
}

.sticky-header.shrink {
  top: 0px;
  height: 84px;
  background: rgba(255, 255, 255, 0.92);
  border-bottom: 0 none;
}

.sticky-header.shrink .navbar-default .navbar-nav > li > a {
  height: 80px;
}

.sticky-header.shrink ul.dropdown-menu.mega-dropdown-menu {
  margin-top: 8px;
}

.sticky-header.shrink .shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper {
  top: 92%;
}

.header-2 {
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 30;
  width: 100%;
  left: 0;
  right: 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}

.header-2 .navbar-default {
  background: transparent;
}

.header-2 .navbar-default .navbar-nav > li > a,
.header-2 .navbar-default .x-caret:before {
  color: #fff;
}

.header-2 .header-customize-item i {
  padding: 12px !important;
  background: #fff;
  display: inline-block;
  border-radius: 100%;
  color: #333 !important;
}

.header-2 .header-customize-item .get-a-quote-button {
  height: auto;
  line-height: 42px;
  background-color: transparent;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
}

.header-2.sticky-header.shrink {
  top: 0px;
  height: 84px;
  background: rgba(56, 59, 62, 0.92);
}

.header-2 .navbar.easy-sidebar #navbar {
  background: rgba(56, 59, 62, 0.92);
}

.header-2 .burger-menu span {
  border-color: #fff;
}

header-2 > .container {
  width: auto;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .header-3 .container {
    width: auto;
    padding-right: 0;
  }
  .header-3 .header-customize {
    width: calc(100% + 45px);
    background: #222;
    margin: 0 -15px 0 -15px;
    float: none !important;
    padding: 0 50px;
  }
  .header-3 .header-customize .fa.fa-shopping-cart,
  .header-3 .header-customize .fa.fa-search {
    color: #fff;
    border: solid 2px #fff;
  }
}

@media screen and (min-width: 1010px) {
  .header-3 .navbar-default {
    display: block;
  }
}

.header-3 .navbar-default {
  clear: both;
  height: 60px;
  background: #222;
}

.header-3 .navbar-brand {
  padding: 15px 15px;
}

.header-3 .navbar-default .navbar-nav > li > a {
  height: 60px !important;
  color: #fff;
  padding: 20px 0;
}

.header-3 .main-menu-custom-text > span {
  font-size: 34px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 1;
}

.header-3 .main-menu-custom-text > i.fa {
  font-size: 41px;
  vertical-align: middle;
  margin-right: 5px;
}

.header-3 .main-menu-custom-text {
  float: right;
  display: block;
  line-height: 60px;
}

.header-3 .navbar-header {
  width: 100%;
}

.header-3 .header-customize {
  float: right;
  line-height: 92px;
}

.header-3 .fa.fa-shopping-cart,
.header-3 .fa.fa-search {
  width: 45px;
  height: 37px;
  line-height: 33px;
  border: solid 2px #222;
  color: #222;
  text-align: center;
  padding: 0;
}

.header-3 .shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon > i.fa-shopping-cart + span {
  top: 44%;
  right: -6px;
}

.header-3 .shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon > i.fa-shopping-cart {
  padding: 0;
}

.header-3 ul.dropdown-menu.mega-dropdown-menu {
  margin-top: 0px;
}

.header-3.sticky-header.shrink ul.dropdown-menu.mega-dropdown-menu {
  margin-top: 0;
}

.header-3 .navbar.easy-sidebar .menu-global {
  border-top: 3px solid #fff;
}

.header-3 .navbar.easy-sidebar .fa.fa-shopping-cart,
.header-3 .navbar.easy-sidebar .fa.fa-search {
  width: auto;
  height: auto;
  line-height: inherit;
  border: 0 none;
}

.header-3 .navbar.easy-sidebar .navbar-nav > li > a {
  height: auto !important;
  color: #fff;
  padding: 15px 0 !important;
}

.header-4 {
  position: absolute;
  top: 40px;
  z-index: 80;
  width: 100%;
}

.header-4 .navbar-default .navbar-nav > li > a {
  padding: 34px 0 0;
  height: 80px;
}

.header-4 .navbar-default .navbar-nav li.header-customize-item a {
  padding: 0;
}

.header-4 .header-customize {
  line-height: 80px;
}

.header-4 .header-customize-item i {
  padding: 12px !important;
  display: inline-block;
  border-radius: 100%;
  background-color: #F4F4F4;
  text-align: center;
  color: #333 !important;
}

.header-4 .header-customize-item .get-a-quote-button {
  height: auto;
  line-height: 42px;
  background-color: transparent;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
}

.header-4 > .container {
  background: #fff;
  padding-left: 0;
  top: -30px;
}

.header-4 .navbar-default {
  float: right;
}

.header-4 .navbar-collapse {
  border-color: transparent;
}

.header-4.sticky-header.shrink {
  background-color: transparent;
}

.header-4.sticky-header.shrink .navbar-header {
  padding: 2px 15px;
}

.header-4 .navbar.easy-sidebar .container {
  padding: 0;
  width: auto;
}

.header-4.sticky-header.shrink > .container {
  border-radius: 0 0 20px 20px;
  box-shadow: -1px 13px 21px -8px rgba(0, 0, 0, 0.3);
}

.header-4.sticky-header.shrink > .container .navbar-header {
  border-radius: 0px 0 0 20px;
}

.header-4.sticky-header.shrink .container .navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-4.sticky-header.shrink .container .navbar-brand img {
  max-height: 56px;
}

.easy-sidebar-active .toggled .header-4 {
  position: absolute;
  top: 0px;
  z-index: 90;
  width: 100%;
  left: 0;
}

@media screen and (max-width: 1009px) {
  .header-4 .navbar-header {
    float: left;
    margin: 0;
  }
  .header-4 > .container {
    margin: 0 auto;
    width: 100%;
  }
}

.header-5 .header-customize-item i {
  padding: 12px !important;
  display: inline-block;
  border-radius: 100%;
  background-color: #F4F4F4;
  text-align: center;
  color: #333 !important;
}

/*.header-5 >.container{width:auto;}*/
.header-5 .top-bar {
  background-color: transparent;
}

.header-5 .tb-right ul li,
.header-5 .tb-right ul li a {
  color: #fff;
}

.header-5 {
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 30;
  width: 100%;
  left: 0;
  right: 0;
}

.header-5 .navbar-default {
  background: transparent;
}

.header-5 .navbar-default .navbar-nav > li > a,
.header-5 .navbar-default .x-caret:before {
  color: #fff;
}

.header-5 .header-customize-item i {
  padding: 12px !important;
  background: rgba(243, 243, 243, 0.4);
  display: inline-block;
  border-radius: 100%;
  color: #fff !important;
}

.header-5 .header-customize-item .get-a-quote-button {
  height: auto;
  line-height: 42px;
  background-color: transparent;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
}

.header-5.sticky-header.shrink {
  top: 0px;
  height: 84px;
  background: rgba(56, 59, 62, 0.92);
}

.header-5 .navbar.easy-sidebar #navbar {
  background: rgba(56, 59, 62, 0.92);
}

.header-5 .burger-menu span {
  border-color: #fff;
}

.header-5 .navbar-brand {
  position: relative;
  padding: 45px 25px 45px 15px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.header-5 .navbar-brand:after {
  content: '';
  display: block;
  left: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  border-top-width: 132px;
  border-top-style: solid;
  border-right: solid 30px transparent;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.header-5 .navbar-header {
  margin-top: -45px;
}

@media screen and (max-width: 1000px) {
  .header-5 .navbar-header {
    margin-top: -13px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  nav.navbar.navbar-default a {
    font-size: x-small;
  }
}

.header-5 .navbar-default .navbar-nav > li > a {
  padding: 30px 0px 16px;
  height: auto;
}

.header-5 .header-customize {
  line-height: 78px;
}

.header-5 ul.dropdown-menu.mega-dropdown-menu {
  margin-top: -9px;
}

.header-5.sticky-header .navbar-header {
  margin-top: 0;
}

.header-5.sticky-header .navbar-brand:after {
  border-top-style: solid;
  border-top-width: 85px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.header-5.sticky-header.shrink .navbar-brand {
  padding: 21px;
}

.header-5.sticky-header.shrink .navbar-default .navbar-nav > li > a {
  height: 84px;
}

.header-5.sticky-header.shrink .header-customize {
  margin-top: 0;
}

.header-5.sticky-header .top-bar {
  display: none;
}

.slider-2-buttons .tp-rightarrow:after {
  content: "";
  display: block;
  height: 100%;
  border-top: solid 70px transparent !important;
  border-right: solid 26px rgba(0, 0, 0, 0.5) !important;
  position: absolute;
  right: 100%;
  top: 0;
}

/* -----------------------------------------------------------------------------HEADER CUSTOMIZATION ------------------ */
#search,
#quote {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 9999;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
}

#search.open,
#quote.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
}

#search input[type=search],
#quote textarea {
  position: absolute;
  top: 50%;
  width: 100%;
  color: white;
  background: rgba(0, 0, 0, 0);
  font-size: 60px;
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
}

@media screen and (max-width: 800px) {
  #search input[type=search],
  #quote textarea {
    font-size: xx-large;
  }
}

#quote textarea {
  min-height: 100px;
}

#search .btn,
#quote .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px;
  border-radius: 0;
  width: 120px;
  height: 40px;
}

#search .btn:hover,
#search .close:hover,
#quote .btn:hover,
#quote .close:hover {
  background-color: #333;
  border-color: #333;
}

#search .close,
#quote .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  opacity: 1;
  padding: 10px 17px;
  font-size: 27px;
}

/* ----------------------------------------------------------------------------- SLIDER ------------------ */
.tp-splitted:empty {
  background: #ff0000;
}

ul.vertical-images {
  margin: 0px;
  padding: 0px;
}

ul.vertical-images li {
  list-style-type: none;
  height: 25%;
  width: 100%;
  position: absolute;
  z-index: 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

ul.vertical-images li .bg {
  background-color: rgba(16, 134, 223, 0.6);
  position: absolute !important;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
}

ul.vertical-images li:hover .bg {
  opacity: 1;
}

.tp-splitted:empty {
  display: none !important;
}

ul.vertical-images li .overlay1 {
  -webkit-backface-visibility: hidden;
  display: block;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 1;
  -webkit-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
}

ul.vertical-images li .overlay1 .icons {
  display: table;
  width: 100%;
  height: 100%;
}

ul.vertical-images li .overlay1 .icons > :first-child {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

ul.vertical-images li .icon {
  font-size: 32px;
  color: #fff;
  padding-bottom: 6px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

ul.vertical-images li .button {
  margin-top: 30px;
  margin-bottom: 0px;
  opacity: 0;
}

ul.vertical-images li h1 {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  padding-top: 0px;
  margin-top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

ul.vertical-images li .line {
  height: 2px;
  width: 34px;
  background-color: #fff;
  margin-top: 14px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

ul.vertical-images li:nth-child(1) {
  background-image: url(../images/vertical-images/1.html);
  background-position: center center;
  top: 0;
  bottom: 0;
}

ul.vertical-images li:nth-child(2) {
  background-image: url(../images/vertical-images/2.html);
  background-position: center center;
  top: 25%;
  bottom: 0;
}

ul.vertical-images li:nth-child(3) {
  background-image: url(../images/vertical-images/3.html);
  background-position: center center;
  top: 50%;
  bottom: 0;
}

ul.vertical-images li:nth-child(4) {
  background-image: url(../images/vertical-images/4.html);
  background-position: center center;
  top: 75%;
  bottom: 0;
}

ul.vertical-images li:hover .icon {
  opacity: 1;
}

ul.vertical-images li:hover .button {
  opacity: 1;
}

.tb-right {
  float: right;
  padding: 0;
  text-align: right;
}

.tb-left ul li,
.tb-right ul li {
  line-height: 40px;
  font-size: 12px;
  color: #222;
  margin-right: 12px;
}

.tb-right ul li {
  margin-left: 0;
  margin-right: 0;
}

.tb-left ul li i,
.tb-right ul li i {
  font-size: 14px;
  padding-right: 5px;
}

.tb-left ul li:first-child i,
.tb-right ul li:first-child i {
  position: relative;
  top: 2px;
}

.tb-left ul li a,
.tb-right ul li a {
  color: #222;
}

.top-social li {
  line-height: 42px;
  margin-left: 4px;
}

.top-social li a {
  font-size: 15px;
  color: #02A8EB;
}

.top-social li a:hover {
  color: #626262;
}

.navbar-default {
  border: none;
  border-radius: 0px;
  height: 76px;
  margin: 0;
  width: auto;
  padding: 0 15px;
  background: transparent;
}

.slider {
  background: #aaa;
  max-height: 700px;
  overflow: hidden;
  width: 100%;
}

.home-about {
  padding: 100px 0;
  background-color: white;
}

.v-align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.home-about p {
  margin-bottom: 33px;
}

.content-head {
  position: relative;
  padding-top: 15px;
  margin: 57px 0 60px;
}

@media screen and (min-width: 0\0) and (min-resolution: 72dpi) {
  /* IE9+ CSS*/
  .content-head:after,
  .content-head:before {
    left: 47%;
  }
}

.content-head h4 {
  font-size: 48px;
  font-weight: 400;
  color: #222;
  line-height: 1;
  position: relative;
  margin: 22px 0 6px;
  font-family: "Oswald", sans-serif;
}

.content-head p {
  line-height: 1.85;
  color: #616161;
  margin: 0px;
}

@media only screen and (min-width: 501px) {
  .darnalargetext {
    font-size: 300% !important;
    line-height: 48px !important;
  }
  .tp-caption.darnasmalltext,
  .darnasmalltext {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .tp-caption.darnaverylargetextprimary,
  .darnaverylargetextprimary {
    font-weight: 700 !important;
    font-size: 400% !important;
    line-height: 60px !important;
  }
  .tp-caption.darnaverylargetext,
  .darnaverylargetext {
    font-weight: 700 !important;
    font-size: 400% !important;
    line-height: 60px !important;
  }
  .darnamediumtext {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }
  .tp-caption.darnalargeprimarybg,
  .darnalargeprimarybg {
    font-size: 300% !important;
    line-height: 50px !important;
    font-weight: 400 !important;
  }
  .tp-caption.darnasmalltext2,
  .darnasmalltext2 {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 24px !important;
  }
}

@media only screen and (max-width: 500px) {
  .darnalargetext {
    font-size: 150% !important;
    line-height: 100% !important;
  }
  .tp-caption.darnasmalltext,
  .darnasmalltext {
    font-weight: 400 !important;
    font-size: 60% !important;
    line-height: 16px !important;
  }
  .tp-caption.darnaverylargetextprimary,
  .darnaverylargetextprimary {
    font-weight: 700 !important;
    font-size: 150% !important;
    line-height: 150% !important;
  }
  .tp-caption.darnaverylargetext,
  .darnaverylargetext {
    font-weight: 700 !important;
    font-size: 150% !important;
    line-height: 150% !important;
  }
  .darnamediumtext {
    font-weight: 400 !important;
    font-size: 60% !important;
    line-height: 16px !important;
  }
  .tp-caption.darnalargeprimarybg,
  .darnalargeprimarybg {
    font-size: 100% !important;
    line-height: 50% !important;
    font-weight: 400 !important;
  }
  .tp-caption.darnasmalltext2,
  .darnasmalltext2 {
    font-weight: 400 !important;
    font-size: 60% !important;
    line-height: 16px !important;
  }
}

.tp-caption.darnalargetext,
.darnalargetext {
  color: #ffffff;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  text-decoration: none;
  background-color: transparent;
  border-width: 0px;
  border-color: white;
  border-style: none;
  margin: 0px;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.tp-caption.darnasmalltext,
.darnasmalltext {
  color: #ffffff;
  font-family: "Lato", sans-serif;
  background-color: transparent;
  text-decoration: none;
  border-width: 0px;
  border-color: white;
  border-style: none;
  margin: 0px;
  max-width: 600px;
  text-align: center;
}

.tp-caption.darnaverylargetextprimary,
.darnaverylargetextprimary {
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  text-decoration: none;
  border-width: 0px;
  border-color: white;
  border-style: none;
  margin: 0px;
  white-space: nowrap;
  text-transform: uppercase;
}

.tp-caption.darnaverylargetext,
.darnaverylargetext {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  text-decoration: none;
  border-width: 0px;
  border-color: white;
  border-style: none;
  margin: 0px;
  white-space: nowrap;
  text-transform: uppercase;
}

.tp-caption.darnamediumtext,
.darnamediumtext {
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  text-decoration: none;
  background-color: transparent;
  border-width: 0px;
  border-color: white;
  border-style: none;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
}

.tp-caption.darnalargeprimarybg,
.darnalargeprimarybg {
  font-family: "Oswald", sans-serif;
  color: white;
  text-decoration: none;
  padding: 15px 20px;
  background-color: #ffb600;
  border-width: 0px;
  border-color: white;
  border-style: none;
  text-transform: uppercase;
}

.tp-caption.darnasmalltext2,
.darnasmalltext2 {
  color: #ffffff;
  font-family: "Lato", sans-serif;
  text-decoration: none;
  background-color: transparent;
  border-width: 0px;
  border-color: white;
  border-style: none;
  margin: 0px;
  max-width: 600px;
  text-align: center;
}

.darna-button {
  display: inline-block;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none;
  padding: 7px 14px;
}

.darna-button:hover,
.darna-button:active {
  text-decoration: none !important;
  outline: none !important;
}

.darna-button.size-xs {
  padding: 1px 18px;
  font-size: 12px;
}

.darna-button.size-sm {
  padding: 2px 20px;
  font-size: 13px;
}

.darna-button.size-md {
  padding: 6px 20px;
  font-size: 13px;
}

.darna-button.size-lg {
  padding: 6px 22px;
  font-size: 14px;
}

.darna-button.style1 {
  color: #fff;
}

.darna-button.style1:hover {
  background-color: #222;
  border-color: #222;
  color: #fff;
}

.darna-button.style2 {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.darna-button.style2:hover {
  color: #fff;
}

.darna-button.style3 {
  background-color: #222;
  border-color: #222;
  color: #FFF;
}

.darna-button.style3:hover {
  color: #fff;
}

.darna-button.style4 {
  background-color: #FFF;
  border-color: #222;
  color: #222;
}

.darna-button.style4:hover {
  color: #fff;
}

.darna-button i {
  margin-right: 5px;
}

.darna-button.icon-right i {
  margin-right: 0;
  margin-left: 5px;
}

.slider-2-buttons .tparrows.tp-rightarrow:before {
  padding-right: 15px;
}

.slider-2-buttons .tparrows.tp-rightarrow:before {
  content: '\e825';
}

.slider-2-buttons .tparrows:before {
  font-size: 20px;
  line-height: 70px;
}

.slider-2-buttons .tparrows:before {
  font-family: "revicons";
  color: #fff;
  display: block;
  text-align: center;
}

.slider-2-buttons .tparrows {
  width: 53px !important;
  height: 70px !important;
}

.slider-2-buttons .tparrows.tp-leftarrow:before {
  padding-left: 15px;
}

.slider-2-buttons .tparrows.tp-leftarrow:before {
  content: '\e824';
}

.slider-2-buttons .tp-leftarrow:after {
  content: "";
  display: block;
  height: 100%;
  border-top: solid 70px transparent !important;
  border-left: solid 26px rgba(0, 0, 0, 0.5) !important;
  position: absolute;
  left: 100%;
  top: 0;
}

.slider-2-buttons .tp-rightarrow:hover.tp-rightarrow:after {
  border-right-color: #000000 !important;
}

.slider-2-buttons .tp-leftarrow:hover.tp-leftarrow:after {
  border-left-color: #000000 !important;
}

.item-slider.owl-theme .owl-controls .owl-buttons div {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: auto;
  display: block;
  top: 0;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  background-color: black;
  z-index: 200;
  padding: 0;
  opacity: 0;
  color: #fff;
  font-size: 24px;
  border-radius: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.item-slider.owl-theme .owl-controls .owl-buttons div {
  color: #FFF;
  display: inline-block;
  zoom: 1;
  margin: 5px;
  padding: 0px 10px;
  font-size: 12px;
  filter: Alpha(Opacity=50);
  opacity: .5;
  top: 50%;
  font-size: 18px;
}

.item-slider.owl-theme .owl-next {
  right: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

.item-slider .owl-prev {
  left: 0;
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}

.item-slider {
  overflow: hidden;
}

/* -----------------------------------------------------------------------------BUTTONS ------------------ */
.bttn {
  color: #FFF;
  display: table;
  text-transform: uppercase;
  -webkit-transition: .4s;
  transition: .4s;
  border-style: solid;
  border-width: 2px;
}

.bttn-xs {
  padding: 4px 18px;
  font-size: 12px;
}

.bttn-sm {
  padding: 5px 12px;
  font-size: 14px;
}

.bttn-md {
  padding: 9px 20px;
  font-size: 13px;
}

.bttn:hover {
  background-color: #222;
  border: 2px solid #222;
  color: #FFF;
  -webkit-transition: .4s;
  transition: .4s;
}

.bttn-black {
  background: #222222;
  border-color: #222222;
}

.bttn-bdr-black {
  background-color: #FFF;
  border-color: #222;
  color: #222;
}

.bttn-bdr-white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.bttn-bdr-white:hover,
.bttn-bdr-black:hover {
  color: #fff;
}

.service-box1 {
  background: #000 url(../images/bg/1.jpg) no-repeat center top;
  background-size: cover;
  padding: 100px 0 90px;
}

.s1-box p {
  color: #FFF;
  line-height: 24px;
}

.overlay-wrap {
  position: relative;
}

.overlay-wrap .container {
  position: relative;
  z-index: 55;
}

.darna-button {
  display: inline-block;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  text-transform: uppercase;
  border: 2px solid;
}

.darna-button:hover,
.darna-button:active {
  text-decoration: none !important;
  outline: none !important;
}

.darna-button.size-xs {
  padding: 1px 18px;
  font-size: 12px;
}

.darna-button.size-sm {
  padding: 2px 20px;
  font-size: 13px;
}

.darna-button.size-md {
  padding: 6px 20px;
  font-size: 13px;
}

.darna-button.size-lg {
  padding: 6px 22px;
  font-size: 14px;
}

.darna-button.style1 {
  color: #fff;
}

.darna-button.style1:hover {
  background-color: #222;
  border-color: #222;
  color: #fff;
}

.darna-button.style2 {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.darna-button.style2:hover {
  color: #fff;
}

.darna-button.style3 {
  background-color: #222;
  border-color: #222;
  color: #FFFFFF;
}

.darna-button.style3:hover {
  color: #fff;
}

.darna-button.style4 {
  background-color: #FFFFFF;
  border-color: #222;
  color: #222;
}

.darna-button.style4:hover {
  color: #fff;
}

.darna-button i {
  margin-right: 5px;
}

.darna-button.icon-right i {
  margin-right: 0;
  margin-left: 5px;
}

/* -----------------------------------------------------------------------------PAGE ------------------ */
.page-head-shop.overlay-wrap {
  /*height: 300px;*/
  width: 100%;
  position: relative;
  color: #fff;
  margin-bottom: 100px;
  background-color: #2a2a2a;
}

.page-head-shop.overlay-wrap .container {
  height: 100%;
}

.page-head-shop.overlay-wrap .block-center {
  text-align: left;
}

.overlay {
  background: #3c3c3c;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
}

.content-head-lite h4 {
  color: #fff;
}

.content-head-lite p {
  color: #979797;
}

/* -----------------------------------------------------------------------------PAGE HEAD------------------ */
.page-head,
.page-head-blog,
.page-head-shop,
.page-head-project {
  background: #000 url(../images/head/bg-page-title.jpg) no-repeat center;
  background-size: cover;
  padding: 132px 0 40px;
}

.page-head-blog {
  background: #000 url(../images/head/bg-blog-title.jpg) no-repeat center;
}

.page-head-shop {
  background: #000 url(../images/head/bg-shop-title.jpg) no-repeat center;
}

.page-head-project {
  background: #000 url(../images/head/bg-project.title.jpg) no-repeat center;
}

.page-head h1 {
  font-size: 30px;
  margin: -7px 0 10px;
  color: #fff;
  font-weight: bold;
}

/* -----------------------------------------------------------------------------BREAD CRUMBS ------------------ */
.bcrumbs li {
  font-size: 14px;
  color: #fff;
  float: left;
}

.bcrumbs li + li::before {
  content: "\/";
  display: inline-block;
  padding: 0px 5px;
}

.page-head .overlay {
  opacity: 0.8;
}

/* -----------------------------------------------------------------------------PROJECTS ------------------ */
.projects-content {
  background: url(../images/pattern.jpg);
  padding: 42px 0px 101px;
}

.project-filter {
  display: table;
  margin: 27px auto 39px;
  position: relative;
  padding: 0 20px;
}

.project-filter:before {
  position: absolute;
  top: 50%;
  width: 20px;
  content: "";
  left: 0;
  margin-top: 0;
  border-style: solid;
  border-width: 2px 0 0 0;
}

.project-filter:after {
  position: absolute;
  top: 50%;
  width: 20px;
  content: "";
  right: 0;
  margin-top: 0;
  border-style: solid;
  border-width: 2px 0 0 0;
}

.project-filter a {
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 2;
  position: relative;
  color: #fff;
}

.project-filter a i {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 14px;
  top: 16px;
  left: 0;
  right: 0;
  opacity: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

#home-projects {
  padding-bottom: 50px;
}

#home-projects .owl-item {
  float: left;
}

.project-item {
  position: relative;
  overflow: hidden;
}

.project-item img {
  max-width: 100%;
  display: block;
  position: relative;
  -webkit-transition: .4s;
  transition: .4s;
}

.project-item:hover img {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}

.project-overlay {
  background: #fff;
  padding: 14px 20px 12px;
  -webkit-transition: .4s;
  transition: .4s;
  position: absolute;
  width: 100%;
  height: 68px;
  bottom: -68px;
  left: 0;
  right: 0;
}

.project-overlay h4 {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  margin: 0 0 4px;
}

.project-overlay h4 a {
  color: #222;
}

.project-cat {
  display: inline-block;
  padding-top: 0px;
  font-size: 11px;
  text-transform: uppercase;
  white-space: nowrap;
}

.project-overlay .project-view,
.project-overlay .project-expand {
  position: absolute;
  top: 0;
  padding: 20px 24px;
  height: 100%;
  -webkit-transition: .4s;
  transition: .4s;
  font-size: 20px;
}

.project-overlay .project-view {
  color: #fff;
  right: 65px;
}

.project-overlay .project-view:hover {
  color: #212121;
}

.project-overlay .project-expand {
  right: 0;
  background-color: #212121;
}

.project-overlay .project-expand:hover {
  color: #fff;
}

.project-item:hover .project-overlay {
  width: 100%;
  bottom: 0px;
  -webkit-transition: .4s;
  transition: .4s;
}

#home-projects .owl-controls {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
}

#home-projects .owl-prev {
  background: #3f3f3f;
  opacity: 1;
  font-size: 0px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 40px;
  top: 0px;
  -webkit-transition: .4s;
  transition: .4s;
}

#home-projects .owl-next {
  background: #3f3f3f;
  opacity: 1;
  font-size: 0px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transition: .4s;
  transition: .4s;
}

#home-projects .owl-prev:hover,
#home-projects .owl-next:hover {
  color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

#home-projects .owl-prev:after {
  content: "\f104";
  font-size: 16px;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

@media screen and (max-width: 800px) {
  .project-overlay .project-view,
  .project-overlay .project-expand {
    padding: 20px 19% 5px;
  }
  .project-overlay .project-view {
    right: auto;
    left: 0;
  }
}

@media screen and (max-width: 600px) {
  .project-overlay {
    zoom: 0.5;
  }
  .pp_content,
  div.ppt,
  div.pp_pic_holder,
  div.ppt img#fullResImage {
    width: 100% !important;
  }
  .project-item:hover img {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
  }
}

#home-projects .owl-next:after {
  content: "\f105";
  font-size: 16px;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.video-content {
  background: #f7f7f7;
  padding: 100px 0px;
}

.video-content h4 {
  font-size: 32px;
  font-weight: 400;
  margin: 39px 0 30px;
}

.video-content h4 span {
  color: #222;
}

.video-content p {
  margin-bottom: 34px;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* -----------------------------------------------------------------------------BLOG ------------------ */
.home-blog {
  padding: 42px 0px 90px;
}

.blog-thumb {
  position: relative;
}

.blog-thumb a {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(2, 168, 235, 0.7);
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.blog-thumb a i {
  position: absolute;
  top: 0px;
  width: 56px;
  height: 55px;
  right: 0px;
  line-height: 54px;
  font-size: 16px;
  text-align: center;
  color: #FFF;
  opacity: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.blog-thumb:hover a {
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.blog-thumb:hover a i {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.blog-post {
  margin-top: 6px;
  padding: 0 15px;
}

.blog-excerpt h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25;
  padding-top: 19px;
  padding-bottom: 9px;
  margin: 0;
}

.blog-excerpt h3 a {
  color: #222;
  letter-spacing: -0.01em;
}

.blog-meta {
  margin-bottom: 31px;
  position: relative;
}

.blog-meta:after {
  content: "";
  height: 4px;
  position: absolute;
  width: 50px;
  bottom: -20px;
  left: 0;
}

.blog-meta,
.blog-meta a {
  color: #8F8F8F;
  font-size: 12px;
}

.blog-meta span {
  padding-left: 8px;
  margin-right: 16px;
}

.blog-excerpt p {
  line-height: 24px;
  font-family: "Roboto Condensed", sans-serif;
}

/* -----------------------------------------------------------------------------BLOG ------------------ */
.blog-content-title-wrap {
  text-align: center;
  width: 100%;
  position: relative;
  color: #fff;
  margin-bottom: 100px;
  background-color: #2a2a2a;
}

.blog-content-title-wrap .container {
  height: 100%;
}

.blog-content-title-wrap .block-center {
  text-align: left;
}

.blog-content-title-inner {
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.blog-content-title-inner h1 {
  font-size: 30px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
}

.blog-content-title-overlay {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-color: #000;
  opacity: .4;
}

.blog-content-title-no-margin-bottom {
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .blog-content-title-inner h1 {
    font-size: 42px;
  }
  #clients2 .item img,
  .project-item img,
  .folio-item img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .blog-content-title-inner h1 {
    font-size: 32px;
  }
}

ul.breadcrumbs {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
}

ul.breadcrumbs li {
  display: inline;
  font-size: 14px;
  text-transform: uppercase;
}

ul.breadcrumbs li a {
  color: #fff;
}

ul.breadcrumbs li a.home {
  font-size: 16px;
}

ul.breadcrumbs li + li:before {
  content: "//";
  display: inline-block;
  padding: 0 5px;
}

ul.breadcrumbs li span {
  color: #fff;
}

.block-center:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.block-center {
  height: 100%;
  width: 100%;
  text-align: center;
}

.block-center-inner {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  max-width: 95%;
}

.block-center-inner > * {
  margin: auto;
}

#parent-content {
  background-color: #fff;
  z-index: 1;
  position: relative;
}

#parent-content > main[role=main] {
  overflow: hidden;
}

.blog-inner .entry-thumbnail-wrap,
.darna-post .darna-post-image .entry-thumbnail-wrap {
  margin-bottom: 40px;
}

.blog-inner .entry-thumbnail,
.darna-post .darna-post-image .entry-thumbnail {
  position: relative;
}

.blog-inner .entry-thumbnail a.prettyPhoto,
.darna-post .darna-post-image .entry-thumbnail a.prettyPhoto {
  position: absolute;
  top: 0;
  width: 56px;
  height: 55px;
  right: 0;
  line-height: 54px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.blog-inner .entry-thumbnail:hover a.entry-thumbnail_overlay:before,
.darna-post .darna-post-image .entry-thumbnail:hover a.entry-thumbnail_overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.blog-inner .entry-thumbnail:hover a.prettyPhoto,
.darna-post .darna-post-image .entry-thumbnail:hover a.prettyPhoto {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.blog-inner .entry-thumbnail_overlay img,
.darna-post .darna-post-image .entry-thumbnail_overlay img {
  width: 100%;
  display: block;
}

.blog-inner h3.entry-title,
.darna-post .darna-post-image h3.entry-title {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 5px;
}

.blog-inner h3.entry-title a,
.darna-post .darna-post-image h3.entry-title a {
  color: #222;
}

.blog-inner .entry-excerpt,
.darna-post .darna-post-image .entry-excerpt {
  margin-bottom: 30px;
}

.blog-inner .entry-excerpt p,
.darna-post .darna-post-image .entry-excerpt p {
  margin-bottom: 0;
}

.blog-inner .audiojs a:hover {
  color: #e74c3c;
}

.audiojs {
  width: 100%;
  height: 80px;
  background: #222;
  overflow: hidden;
  font-family: monospace;
  font-size: 12px;
  background-image: none;
  -o-box-shadow: none;
  box-shadow: none;
  padding: 26px 0;
}

.audiojs .scrubber {
  width: 83%;
  background: #5a5a5a;
  height: 4px;
  margin: 10px;
  border-top: 0 none;
  border-left: 0px;
  border-bottom: 0px;
  overflow: hidden;
}

.audiojs .time {
  float: left;
  line-height: 24px;
  margin: 0;
  padding: 0px 6px 0px 12px;
  border-left: 0 none;
  color: #ddd;
  text-shadow: none;
}

.audiojs .play-pause {
  width: 25px;
  height: 20px;
  padding: 0 6px;
  margin: 0px;
  border-right: 0 none;
  margin-top: 0;
}

.audiojs .loaded {
  height: 4px;
  width: 100% !important;
  background: #4e4e4e;
  background-image: none;
  background-image: none;
}

.audiojs .progress {
  height: 4px;
  background: #e74c3c;
  background-image: none;
  background-image: none;
}

.audiojs p {
  margin: -2px 0 0 0;
}

.blog-inner article,
.darna-post .darna-post-image article {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #eee;
}

.blog-inner .entry-p-meta-wrap ul.entry-m,
.darna-post .darna-post-image .entry-p-meta-wrap ul.entry-m {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0;
}

.blog-inner .entry-p-meta-wrap ul.entry-m li,
.darna-post .darna-post-image .entry-p-meta-wrap ul.entry-m li {
  display: inline;
  font-size: 12px;
  font-style: italic;
  margin-right: 25px;
  text-transform: uppercase;
}

.blog-inner .entry-p-meta-wrap ul.entry-m li:last-child,
.darna-post .darna-post-image .entry-p-meta-wrap ul.entry-m li:last-child {
  margin-right: 0;
}

.blog-inner .entry-p-meta-wrap ul.entry-m li i,
.darna-post .darna-post-image .entry-p-meta-wrap ul.entry-m li i {
  font-size: 12px;
  margin-right: 4px;
}

.blog-inner .entry-c-top-wrap,
.darna-post .darna-post-image .entry-c-top-wrap {
  margin-bottom: 20px;
}

.blog-inner .entry-p-format-icon,
.darna-post .darna-post-image .entry-p-format-icon {
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 21px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 24px;
}

.blog-inner .entry-p-format-icon:after,
.darna-post .darna-post-image .entry-p-format-icon:after {
  content: "";
  height: 4px;
  width: 100%;
  display: block;
  margin-top: 20px;
}

.blog-inner .entry-c-top-right,
.darna-post .darna-post-image .entry-c-top-right {
  position: relative;
  overflow: hidden;
}

.blog-inner article.format-quote .entry-c-quote blockquote,
.darna-post .darna-post-image article.format-quote .entry-c-quote blockquote {
  padding: 20px 30px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  background-color: #f3f3f3;
  color: #2a2a2a;
  margin-bottom: 0;
  border-left-width: 10px;
  border-left-style: solid;
}

.blog-inner article.format-quote .entry-c-quote blockquote cite,
.darna-post .darna-post-image article.format-quote .entry-c-quote blockquote cite {
  font-size: 14px;
}

.blog-inner article.format-link .entry-c-link,
.darna-post .darna-post-image article.format-link .entry-c-link {
  padding: 20px 30px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  background-color: #f3f3f3;
  color: #2a2a2a;
}

.blog-inner article.format-link .entry-c-link p,
.darna-post .darna-post-image article.format-link .entry-c-link p {
  margin-bottom: 0;
}

.post-meta-line {
  height: 3px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}

.post-meta-line:before {
  content: "";
  border: 1px dotted #eee;
  display: block;
}

.site-content-archive {
  margin-bottom: 40px;
}

.site-content-archive img {
  max-width: 100%;
}

.blog-paging-wrapper {
  margin-bottom: 60px;
  height: 40px;
}

.blog-paging-wrapper ul.pagination,
.unused-pagination ul.pagination {
  padding: 0;
  margin: 0;
}

.blog-paging-wrapper .pagination > li > a,
.unused-pagination .pagination > li > a,
.blog-paging-wrapper .pagination > li > span,
.unused-pagination .pagination > li > span {
  font-size: 14px;
  color: #8f8f8f;
  padding: 0;
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #eee;
  border-radius: 0;
}

.blog-paging-wrapper .pagination > li + li > a,
.unused-pagination .pagination > li + li > a,
.blog-paging-wrapper .pagination > li + li > span,
.unused-pagination .pagination > li + li > span {
  margin-left: 10px;
}

.blog-paging-wrapper .pagination > li > a.current,
.unused-pagination .pagination > li > a.current,
.blog-paging-wrapper .pagination > li > span.current,
.unused-pagination .pagination > li > span.current,
.blog-paging-wrapper .pagination > li > a:hover,
.unused-pagination .pagination > li > a:hover,
.blog-paging-wrapper .pagination > li > span:hover,
.unused-pagination .pagination > li > span:hover,
.blog-paging-wrapper .pagination > li > a:active,
.unused-pagination .pagination > li > a:active,
.blog-paging-wrapper .pagination > li > span:active,
.unused-pagination .pagination > li > span:active,
.blog-paging-wrapper .pagination > li > a:focus,
.unused-pagination .pagination > li > a:focus,
.blog-paging-wrapper .pagination > li > span:focus,
.unused-pagination .pagination > li > span:focus {
  color: #fff;
}

.blog-paging-default {
  text-align: right;
}

.blog-paging-wrapper.blog-paging-load-more {
  text-align: center;
}

.blog-wrap .entry-thumbnail-wrap .owl-theme .owl-controls,
.single-product-image-inner .owl-theme .owl-controls,
.darna-post .darna-post-image .owl-theme .owl-controls {
  margin: 0;
}

.blog-wrap .entry-thumbnail-wrap .owl-pagination,
.single-product-image-inner .owl-pagination,
.darna-post .darna-post-image .owl-pagination {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.blog-wrap .entry-thumbnail-wrap .owl-theme .owl-controls .owl-page span,
.single-product-image-inner .owl-theme .owl-controls .owl-page span,
.darna-post .darna-post-image .owl-theme .owl-controls .owl-page span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 50%;
}

.blog-wrap .entry-thumbnail-wrap .owl-theme .owl-controls .owl-page.active span,
.single-product-image-inner .owl-theme .owl-controls .owl-page.active span,
.darna-post .darna-post-image .owl-theme .owl-controls .owl-page.active span {
  background-color: #FFFFFF;
}

.blog-wrap .entry-thumbnail-wrap .owl-theme .owl-controls .owl-buttons div,
.single-product-image-inner .owl-theme .owl-controls .owl-buttons div,
.darna-post .darna-post-image .owl-theme .owl-controls .owl-buttons div {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: auto;
  display: block;
  top: 0;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
  padding: 0;
  opacity: 0;
  color: #fff;
  font-size: 24px;
  border-radius: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.blog-wrap .entry-thumbnail-wrap .owl-next,
.single-product-image-inner .owl-next,
.darna-post .darna-post-image .owl-next {
  right: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

.blog-wrap .entry-thumbnail-wrap .owl-prev,
.single-product-image-inner .owl-prev,
.darna-post .darna-post-image .owl-prev {
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}

.blog-wrap .entry-thumbnail-wrap:hover .owl-theme .owl-controls .owl-buttons div,
.single-product-image-inner:hover .owl-theme .owl-controls .owl-buttons div,
.darna-post .darna-post-image:hover .owl-theme .owl-controls .owl-buttons div {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.site-content-archive {
  margin-bottom: 40px;
}

.entry-thumbnail {
  overflow: hidden;
  position: relative;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 66.25%;
}

.embed-responsive.embed-responsive-blog-classic {
  padding-bottom: 41.0256%;
}

@media (max-width: 480px) {
  .entry-comments .entry-comments-list .comment-avatar-wrap {
    width: 100%;
    float: none;
    margin-right: 0;
    text-align: center;
  }
  .entry-comments .entry-comments-list .commentlist ul.children {
    padding-left: 0 !important;
  }
  .blog-inner .entry-p-format-icon {
    display: none;
  }
  .blog-content-title-wrap {
    height: 200px;
    margin-bottom: 60px;
  }
}

/* -----------------------------------------------------------------------------HOME ------------------ */
#home-posts .owl-controls {
  margin-top: 19px;
}

#home-posts .owl-controls .owl-page span {
  width: 52px;
  height: 5px;
  border-radius: 0px;
  opacity: 1;
  background: #eeeeee;
}

#home-posts .owl-controls .owl-page span:hover {
  background: #dddddd;
}

.home-info {
  padding: 100px 0 90px;
}

.home-info-content {
  margin-bottom: 30px;
}

.home-info-content h4 {
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 21px 0 20px;
}

.home-info-content h4 a {
  color: #222;
}

.home-info-content h4 i {
  font-size: 26px;
  margin-right: 9px;
}

.home-info-content p {
  margin-bottom: 20px;
}

.home-info-thumb {
  position: relative;
}

.home-info-thumb i {
  color: #FFF;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.home-info-thumb:hover i {
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.home-quotes {
  background: url(../images/bg/2.jpg) no-repeat center center;
  background-size: cover;
  padding: 34px 0px 106px;
}

.home-quotes2 {
  background: url(../images/bg/test-bg.jpg) no-repeat center center;
}

.quote-info {
  max-width: 780px;
  margin: 0px auto;
  padding-top: 6px;
}

.quote-info p {
  font-size: 21px;
  font-style: italic;
  line-height: 1.71;
  color: #FFF;
}

.quote-info i {
  border-radius: 50%;
  color: #FFF;
  font-size: 16px;
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 23px auto 49px;
}

.quote-info cite {
  font-size: 14px;
  line-height: 19px;
  color: #FFF;
  font-style: normal;
}

.quote-info cite span {
  font-size: 12px;
  color: #8F8F8F;
  display: block;
}

#home-quotes .owl-controls {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  left: 5px;
  right: 0;
  bottom: 71px;
}

#home-quotes .owl-prev {
  opacity: 0.5;
  border-radius: 0px;
  background: transparent;
  font-size: 0;
  position: relative;
  padding: 0 13px;
}

#home-quotes .owl-prev:after {
  content: "\f177";
  font-family: 'FontAwesome';
  font-size: 21px;
  display: block;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
}

#home-quotes .owl-next {
  opacity: 0.5;
  border-radius: 0px;
  background: transparent;
  font-size: 0;
  position: relative;
  padding: 0 13px;
}

#home-quotes .owl-next:after {
  content: "\f178";
  font-family: 'FontAwesome';
  font-size: 21px;
  display: block;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
}

#home-quotes .owl-prev:hover,
#home-quotes .owl-next:hover {
  opacity: 1;
}

.home-projects-full {
  padding: 43px 0px 102px;
}

.home-projects-full-filter {
  padding: 34px 0px 102px;
}

.space-top-10 {
  margin-top: 10px;
}

.space-top-20 {
  margin-top: 20px;
}

.space-top-30 {
  margin-top: 30px;
}

.space-top-40 {
  margin-top: 40px;
}

.space-top-50 {
  margin-top: 50px;
}

.space-top-60 {
  margin-top: 60px;
}

.space-top-70 {
  margin-top: 70px;
}

.space-top-80 {
  margin-top: 80px;
}

.space-top-15 {
  margin-top: 15px;
}

.space-top-25 {
  margin-top: 25px;
}

.space-top-35 {
  margin-top: 35px;
}

.space-top-45 {
  margin-top: 45px;
}

.space-top-55 {
  margin-top: 55px;
}

.counter-box {
  background: url(../images/pattern.jpg);
  padding: 80px 0px 90px;
}

.home-features-side .feature-box {
  width: 50%;
  float: left;
}

.home-features-side .feature-box p {
  padding-left: 0;
  line-height: 24px;
  margin-bottom: -21px;
}

.home-features-side .feature-box h3 {
  padding-left: 49px;
  margin: 0 0 24px;
}

.home-features-side .feature-box-ico {
  display: block;
  height: 40px;
  width: 40px;
  float: left;
  text-align: center;
  background-color: transparent;
  border: 0 none !important;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  font-size: 40px;
  line-height: 0;
}

.home-features-side .feature-box:hover .feature-box-ico {
  background-color: transparent;
  border: 0 none;
  color: #222;
}

.home-projects.projects-content .folio-filter-wrap {
  background: transparent !important;
}

.home-projects.projects-content .folio-filter li a {
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .folio-filter li a {
    line-height: 29px;
    padding: 0px 12px;
    font-size: 9px;
  }
}

/* -----------------------------------------------------------------------------HOME INFO AND PROJECTS ------------------ */
.home-info2 {
  background: #000 url(../images/bg/3.jpg) no-repeat center;
  background-size: cover;
  padding: 95px 0;
}

.home-info2 p {
  color: #FFF;
  margin-bottom: 20px;
}

.home-info2 h3 {
  color: #FFF;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
  display: inline-block;
  margin: 0px 0px 26px;
}

.home-info2 .bttn {
  float: left;
  margin-right: 10px;
  margin-bottom: 15px;
}

.home-info2 .list li {
  color: #fff;
}

.home-projects-grid {
  padding: 43px 0px 101px;
  background: #fff;
}

.home-projects-grid .col-md-4 {
  margin-bottom: 30px;
}

.project-overlay-dark {
  background: #222;
}

.project-overlay-dark h4 a,
.project-overlay-dark span {
  color: #fff;
}

.project-overlay-dark .project-expand {
  color: #fff;
}

/* -----------------------------------------------------------------------------PORTOFOLIO AND HOME PROCESS ------------------ */
.portfolio-wrap-main {
  padding: 100px 0 90px;
}

.portfolio-grid-4col .folio-item {
  width: 25%;
  padding: 0 15px 30px;
}

.portfolio-grid-3col .folio-item {
  width: 33.3333%;
  padding: 0 15px 30px;
}

.portfolio-full-3col .folio-item {
  width: 33.3333%;
}

.folio-item {
  float: left;
}

.folio-item img {
  width: 100%;
}

.home-process {
  padding: 43px 0 100px;
}

.process-box {
  padding: 50px 20px;
  background-color: #F7F7F7;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.process-box i {
  text-align: left;
  font-size: 135px;
  color: #F2F2F2;
  position: absolute;
  left: 20px;
  bottom: 10px;
  z-index: -1;
}

.process-box h3 {
  font-size: 60px;
  color: #DDD;
  line-height: 1;
  margin: 0 0 20px;
}

.process-box h2 {
  font-size: 21px;
  font-weight: 400;
  color: #222;
  line-height: 1;
  padding-top: 6px;
  margin: 0 0 30px;
}

.process-box h2 a {
  color: #222;
  font-family: "Oswald", sans-serif;
}

.process-box p {
  line-height: 24px;
  margin: 0;
}

.process-box:hover h2,
.process-box:hover h3,
.process-box:hover h2 a,
.process-box:hover p {
  color: #fff;
}

.process-box:hover i {
  color: #000;
  opacity: 0.1;
}

@media screen and (max-width: 990px) {
  .process-box {
    margin-bottom: 30px;
  }
}

.button-wrap {
  padding: 43px 0 100px;
}

.button-wrap .content-head {
  margin: 57px 0px 80px;
}

.bg-dark {
  background-color: #1c1c1c !important;
}

.bg-grey {
  background-color: #f7f7f7 !important;
}

.counter-box2 {
  background: url(../images/bg/bg-counter.jpg) no-repeat center;
  background-size: cover;
}

.counter-box2 .counter-box-info .count {
  color: #fff;
}

/* -----------------------------------------------------------------------------PROFILE ------------------ */
.home-profile {
  padding: 25px 0px 25px;
}

.home-profile h3 {
  margin: 0 0 34px;
  display: block;
}

.home-profile h3 i {
  height: 57px;
  width: 62px;
  font-size: 26px;
  line-height: 57px;
  color: #FFF;
  text-align: center;
  float: left;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.home-profile h3 a {
  color: #222;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
  padding-left: 21px;
  display: inline-block;
  padding-top: 16px;
}

.home-profile p {
  line-height: 24px;
}

.home-profile .bttn {
  float: left;
  margin-right: 10px;
  margin-bottom: 15px;
}

/* -----------------------------------------------------------------------------COUNTER ------------------ */
.counter-box-info .count {
  font-family: "Oswald", sans-serif;
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
}

.counter-box-info p {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #FFF;
  line-height: 1;
  padding-top: 21px;
}

.team-wrap {
  padding: 43px 0px 90px;
}

.staff-pic {
  position: relative;
}

.staff-info h3 {
  font-size: 18px;
  color: #292929;
  line-height: 1.25;
  letter-spacing: -0.01em;
  font-weight: 400;
  margin: 0px;
  padding-top: 30px;
}

.staff-info p {
  font-size: 11px;
  color: #8F8F8F;
  line-height: 1;
  font-weight: bold;
  margin: 0px;
  display: block;
  padding: 12px 0px 30px;
}

/* -----------------------------------------------------------------------------TEAM ------------------ */
.team-staff {
  margin-top: 6px;
}

.staff-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.team-social {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.team-social li {
  position: relative;
  top: 30px;
  padding: 0;
  opacity: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.team-social li a {
  width: 30px;
  height: 30px;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  display: table;
}

.team-social li a:hover {
  color: #FFF;
}

.staff-info {
  background: #F8F8F8;
}

.team-staff:hover .staff-info h3 {
  color: #fff;
  letter-spacing: -0.01em;
}

.team-staff:hover .staff-info p {
  color: #222;
}

.team-staff:hover .staff-overlay {
  opacity: 1;
  visibility: visible;
}

.team-staff:hover .team-social li {
  position: relative;
  top: 0px;
  opacity: 1;
  -webkit-transition: .4s;
  transition: .4s;
}

.team-staff:hover .team-social li:first-child {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.team-staff:hover .team-social li:nth-child(2) {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.team-staff:hover .team-social li:nth-child(3) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.team-staff:hover .team-social li:nth-child(4) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.team-staff:hover .team-social li:nth-child(5) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

#team-wrap .item {
  padding: 0 15px;
}

#team-wrap .owl-prev {
  width: 40px;
  height: 40px;
  background: #2B2B2B;
  opacity: 1;
  margin: 1px 0 0;
  padding: 0px;
  position: absolute;
  left: -45px;
  top: 41%;
  font-size: 0;
}

#team-wrap .owl-next {
  width: 40px;
  height: 40px;
  background: #2B2B2B;
  opacity: 1;
  margin: 1px 0 0;
  padding: 0px;
  position: absolute;
  right: -45px;
  top: 41%;
  font-size: 0;
}

#team-wrap .owl-prev:hover,
#team-wrap .owl-next:hover {
  color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

#team-wrap .owl-prev:after {
  content: "\f104";
  font-size: 16px;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 21px;
  color: #FFF;
}

#team-wrap .owl-next:after {
  content: "\f105";
  font-size: 16px;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 21px;
  color: #FFF;
}

/* -----------------------------------------------------------------------------LIST ------------------ */
.list {
  margin-top: -1px;
  margin-bottom: 38px;
}

.list li {
  line-height: 1.7;
  color: #8F8F8F;
  position: relative;
}

.list li:before {
  content: "\f0a9";
  font-family: "FontAwesome";
  font-size: 14px;
  margin: 0px 10px 0px 0px;
}

.img-border {
  position: relative;
}

.img-border:after {
  content: "";
  background: transparent;
  border: 5px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 14px;
  left: 14px;
  bottom: 14px;
  right: 14px;
}

@media screen and (max-width: 500px) {
  .home-profile .img-border img {
    width: 100%;
  }
}

/* -----------------------------------------------------------------------------SERVICE ------------------ */
.service-text {
  color: #ffffff;
  max-width: 670px;
  margin: auto;
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 21px;
  font-style: italic;
  font-weight: 100;
  line-height: 1.71px;
  text-align: center;
}

.s1-box h4 {
  font-size: 30px;
  font-weight: 400;
  color: #FFF;
  line-height: 1;
}

.s1-box h5 {
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  margin: 20px 0px 32px;
  text-transform: uppercase;
}

.service-box2 {
  padding: 43px 0px 71px;
}

.s2-box {
  margin-bottom: 29px;
}

.s2-box-ico {
  display: block;
  height: 68px;
  width: 68px;
  border-top-width: 4px;
  border-style: solid;
  margin: 7px auto 0;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  font-size: 32px;
  line-height: 60px;
  color: #FFF;
}

.s2-box:hover .s2-box-ico {
  background-color: #fff;
  border-top-width: 4px;
  border-style: solid;
  color: #222222;
}

.s2-box h3 {
  font-size: 21px;
  padding-top: 19px;
  margin: 0 0 11px;
  text-transform: uppercase;
  font-weight: 400;
}

.s2-box h3 a {
  color: #222;
}

.s2-box p {
  line-height: 24px;
}

.service-box3 {
  padding: 43px 0px 158px;
  background: #f8f8f8;
}

.service-box4 {
  background: #000 url(../images/bg/1.jpg) no-repeat center top;
  background-size: cover;
  padding: 146px 0;
}

.service-box4 p {
  color: #ffffff;
  max-width: 670px;
  margin: auto;
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 21px;
  font-style: italic;
  font-weight: 100;
  line-height: 1.71;
  margin-bottom: 30px;
}

.s3-box {
  padding: 0 0px;
}

.s3-box h3 {
  line-height: 1;
  font-size: 18px;
  padding-top: 10px;
  font-weight: 400;
  margin: 0 0 20px;
}

.s3-box h3 a {
  color: #222;
}

.s3-box-ico {
  background-color: transparent;
  border: 4px solid transparent;
  font-size: 40px;
  line-height: 60px;
  width: 68px;
  height: 68px;
  display: table;
  margin: 0px auto 2px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.s3-box:hover .s3-box-ico {
  border-top-width: 4px;
  border-style: solid;
  color: #222;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.s3-box p {
  line-height: 24px;
  padding: 0 10px;
}

#service-box3 .item {
  padding: 0 15px;
}

#service-box3 .owl-prev {
  width: 40px;
  height: 40px;
  background: #2B2B2B;
  opacity: 1;
  margin: 2px 0px 0px;
  padding: 0px;
  position: absolute;
  left: 50%;
  bottom: -66px;
  font-size: 0px;
  margin-left: -42px;
}

#service-box3 .owl-next {
  width: 40px;
  height: 40px;
  background: #2B2B2B;
  opacity: 1;
  margin: 2px 0px 0px;
  padding: 0px;
  position: absolute;
  left: 50%;
  bottom: -66px;
  font-size: 0px;
  margin-left: 2px;
}

#service-box3 .owl-prev:hover,
#service-box3 .owl-next:hover {
  color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

#service-box3 .owl-prev:after {
  content: "\f104";
  font-size: 16px;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 21px;
  color: #FFF;
}

#service-box3 .owl-next:after {
  content: "\f105";
  font-size: 16px;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 21px;
  color: #FFF;
}

/* -----------------------------------------------------------------------------FOLIO ISOTOP ------------------ */
.folio-filter-wrap {
  background: #f4f4f4;
  height: 44px;
  margin: 6px auto 30px;
}

.folio-filter {
  display: table;
}

.folio-filter li {
  margin: 0 -2px;
  padding: 0;
}

.folio-filter li a {
  background-color: transparent;
  line-height: 44px;
  padding: 0px 25px;
  text-transform: uppercase;
  font-size: 11px;
  display: table;
  color: #222;
}

.folio-filter li a.active,
.folio-filter li a:hover {
  color: #fff;
}

.folio-full .folio-item {
  width: 25%;
}

/* -----------------------------------------------------------------------------CLINETS ------------------ */
.clients {
  background: #fff;
  padding: 57px 0px;
}

.clients .grid-4 {
  text-align: center;
}

.clients img {
  opacity: 0.8;
  -webkit-transition: .4s;
  transition: .4s;
  cursor: pointer;
  margin-bottom: 15px;
}

.clients img:hover {
  opacity: 1;
  -webkit-transition: .4s;
  transition: .4s;
}

.client2 {
  padding: 83px 0px 80px;
  background: #222 url(../images/pattern.jpg);
}

#clients2 .item {
  padding: 0 15px;
}

#clients2 .item img {
  display: table;
  margin: 0 auto;
  opacity: 0.5;
}

#clients2 .item img:hover {
  opacity: 1;
}

.home-contents {
  padding: 90px 0;
  background: #fafafa;
}

.home-contents h2 {
  font-weight: 400;
  font-size: 24px;
  color: #222;
  line-height: 1;
  margin-bottom: 44px;
}

/* -----------------------------------------------------------------------------TABS AND ACCORDION ------------------ */
.nav-tabs li {
  border: 1px solid #ececec;
}

.tab-content {
  border-top: 1px solid #ececec;
  margin-top: -1px;
}

.nav-tabs li.active {
  position: relative;
}

.nav-tabs li.active:after {
  content: "";
  height: 4px;
  position: absolute;
  width: 100%;
  top: -4px;
  left: 0;
  right: 0;
}

.nav-tabs li.active:before {
  content: "";
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top-width: 4px;
  border-style: solid;
  display: block !important;
  left: 0px;
  right: 0px;
  width: 8px;
  position: absolute;
  top: 0px;
  margin: auto;
  z-index: 77;
}

.nav-tabs li a {
  border: none !important;
  border-radius: 0px;
  background: #fff !important;
  font-size: 14px;
  line-height: 1;
  color: #222;
  text-align: center;
  min-width: 114px;
  line-height: 50px;
  padding: 0 25px;
  margin: 0;
}

.tab-pane {
  border: none !important;
  border-radius: 0px;
  padding: 20px 0;
  background: #fff !important;
}

.img-left {
  float: left;
  margin-right: 30px;
}

@media screen and (max-width: 500px) {
  .img-responsive {
    float: none;
    display: inline-block;
  }
}

@media screen and (max-width: 990px) {
  .img-responsive {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.tab-pane p {
  margin: -10px 0 0;
}

#accordion .panel-heading {
  color: #222;
  background: transparent !important;
  border: none;
  padding: 0;
}

#accordion .panel-heading a {
  padding: 11px 20px;
  border: 1px solid #ECECEC;
  display: table;
  width: 100%;
  font-size: 14px;
  color: #222;
}

#accordion .panel-heading.actives a {
  border-width: 1px;
  border-style: solid;
  color: #fff;
}

#accordion .panel-heading a i {
  margin-right: 14px;
}

#accordion .panel-group .panel {
  margin-bottom: 0px;
  border-radius: 0;
  border: none;
}

#accordion .panel,
#accordion .panel + .panel {
  margin-bottom: 10px;
  box-shadow: none;
  border: none;
}

#accordion .panel-body {
  border: 1px solid #ECECEC;
  border-top: none;
  padding: 15px 20px;
}

#accordion .panel-body p {
  margin: 0;
}

/* -----------------------------------------------------------------------------VEDIO ------------------ */
.video-bg-wrap {
  background: url(../images/bg/bg-video.jpg) no-repeat center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.video-bg-wrap .overlay {
  opacity: 0.45;
}

.video-bg-wrap .overlay.active {
  opacity: 1;
}

.video_player {
  position: absolute;
  left: -10%;
  right: 0px;
  z-index: 9999;
  width: 100%;
  height: 140%;
  top: -15%;
  opacity: 0;
  z-index: 11;
}

.video_player.active {
  opacity: 0.50;
}

.video_player.active {
  opacity: 0.75;
}

a#playVid {
  width: 100px;
  height: 100px;
  border-width: 10px;
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

a#playVid i {
  background-color: #FFF;
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 80px;
  font-size: 36px;
}

.video-bg-wrap {
  overflow: hidden;
  position: relative;
  height: 497px;
  padding-top: 121px;
}

.video-bg-wrap .container {
  position: relative;
  z-index: 55;
}

.video-text h2 {
  color: #FFF;
  font-size: 42px;
  text-transform: uppercase;
  font-weight: 400;
  padding-top: 35px;
  line-height: 1.1;
  margin-bottom: 20px;
}

.video-text p {
  color: #FFF;
  font-size: 16px;
  line-height: 1.61;
  max-width: 573px;
  margin-left: auto;
  margin-right: auto;
}

/* -----------------------------------------------------------------------------FEATURES ------------------ */
.home-features {
  padding: 43px 0px 106px;
  background: #fff;
}

.feature-box {
  margin-top: 56px;
}

.feature-box-ico {
  display: block;
  height: 68px;
  width: 68px;
  float: left;
  text-align: center;
  background-color: transparent;
  border: 1px solid #DDD;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  font-size: 30px;
  line-height: 66px;
  color: #DDD;
}

.feature-box:hover .feature-box-ico {
  border: 1px solid #DDD;
  color: #fff;
}

.feature-box h3 {
  padding-left: 100px;
  font-size: 21px;
  color: #222;
  margin: 0 0 15px;
  line-height: 0.95;
  padding-top: 5px;
  font-weight: 400;
  text-transform: uppercase;
}

.feature-box h3 a {
  color: #222;
}

.feature-box p {
  padding-left: 100px;
  line-height: 24px;
  margin-bottom: -21px;
}

/* -----------------------------------------------------------------------------QUOTES ------------------ */
.quote-info2 i {
  border-radius: 50%;
  color: #FFF;
  font-size: 27px;
  display: inline-block;
  width: 63px;
  height: 63px;
  line-height: 63px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  margin: auto;
}

/* -----------------------------------------------------------------------------CONTACT ------------------ */
.contact-info {
  padding: 90px 0;
}

.contact-info i {
  font-size: 32px;
  line-height: 110px;
  color: #FFF;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background-color: #222;
  margin: 0 auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact-info h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  color: #222;
  margin: 30px 0 12px;
  line-height: 1.11;
}

.contact-info h3 a {
  color: #222;
}

.contact-info p {
  line-height: 24px;
  margin: 0;
}

.google-map {
  background: #000;
  height: 540px;
}

.contact-form {
  padding: 43px 0 100px;
}

.contact-form form input,
.contact-form form textarea {
  padding: 0px 20px;
  font-size: 14px;
  color: #8F8F8F;
  width: 100%;
  height: 40px;
  line-height: 1;
  background-color: #FFF;
  border: 1px solid #EEE;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  margin-bottom: 30px;
}

.contact-form form textarea {
  height: auto;
  padding: 15px 20px;
}

.contact-form form input:focus,
.contact-form form textarea:focus {
  border-width: 1px;
  border-style: solid;
}

.ct-block {
  font-family: "Roboto Condensed", sans-serif;
  display: inline-block;
  position: relative;
  border: 1px solid #fff;
  margin: 0 10px;
  font-size: 68px;
  line-height: 1;
  padding: 35px 35px 0;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.ct-block span {
  padding: 18px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  display: block;
  margin: 35px -35px 0;
}

#countdown-wrap {
  background: url(../images/countdown.jpg) no-repeat top center;
  background-size: cover;
  height: 100vh;
}

#countdown-wrap img {
  margin: 65px auto 60px;
}

#countdown-wrap p {
  font-weight: 400;
  margin: 0px 0px 70px;
  color: #fff;
  line-height: 1.4;
  font-size: 20px;
}

.copyright {
  color: #fff;
  margin-top: 70px;
  font-size: 12px;
}

/* -----------------------------------------------------------------------------MESSAGES ------------------ */
#error-wrap {
  background: #fff url(../images/404.jpg) no-repeat center;
  background-size: cover;
}

#demo-wrap {
  background: #fff url(../images/demo.jpg) no-repeat center;
  background-size: cover;
}

.error-content {
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
}

.error-content h2 {
  font-size: 162px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0px;
}

.error-content h2 span {
  display: block;
  font-weight: 400;
  font-size: 32px;
  color: #222;
  line-height: 1;
  display: block;
  letter-spacing: 6px;
  margin: 10px 0 0;
}

.error-content img {
  padding: 40px 0 60px;
}

.error-search {
  height: 38px;
  position: relative;
  width: 100%;
  max-width: 35%;
  margin: 85px auto 60px;
}

.error-search input {
  width: 100%;
  padding: 0 48px 0 15px;
  height: 38px;
  border: 1px solid #F3F3F3;
}

.error-search button {
  position: absolute;
  border: medium none;
  right: 0px;
  top: 0px;
  padding: 0px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #FFF;
  font-size: 14px;
}

.error-search input:focus + button,
.error-search button:hover {
  background-color: #222;
  color: #fff;
}

.error-social a {
  padding: 0 15px;
  font-size: 22px;
  color: #8F8F8F;
}

.error-content .copyright {
  color: #000;
  font-size: 14px !important;
}

.lmore {
  padding: 5px 55px;
}

/* -----------------------------------------------------------------------------SIDEBARIN RESPONSIVE VIEW ------------------ */
.container .container,
.has-sidebar .site-content-page-inner .container,
.has-sidebar .site-content-archive-inner .container {
  width: 100%;
  padding: 0;
}

.sidebar .tagcloud {
  font-size: 0;
}

.sidebar .tagcloud a {
  -webkit-transition: all .2s;
  transition: all .2s;
  display: inline-block;
  padding: 9px 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  line-height: 1;
  font-size: 11px;
  text-transform: uppercase;
  background-color: #f3f3f3;
  color: #8f8f8f;
}

.sidebar .tagcloud a:last-child {
  margin-right: 0;
}

.sidebar .tagcloud a:hover {
  color: #FFFFFF;
  text-decoration: none;
}

.sidebar select {
  width: 100%;
}

.sidebar .widget_archive > ul,
.sidebar .widget_archive > ol,
.sidebar .widget_categories > ul,
.sidebar .widget_categories > ol,
.sidebar .widget_product_categories > ul,
.sidebar .widget_product_categories > ol,
.sidebar .widget_nav_menu > div > ul,
.sidebar .widget_nav_menu > div > ol,
.sidebar .widget_meta > ul,
.sidebar .widget_meta > ol,
.sidebar .widget_pages > ul,
.sidebar .widget_pages > ol,
.sidebar .widget_recent_comments > ul,
.sidebar .widget_recent_comments > ol,
.sidebar .widget_recent_entries > ul,
.sidebar .widget_recent_entries > ol,
.sidebar .widget_rss > ul,
.sidebar .widget_rss > ol {
  list-style: none;
  padding-left: 0;
  overflow: auto;
  margin: 0;
  line-height: 2;
  font-size: 12px;
}

.sidebar .widget_archive li,
.sidebar .widget_categories li,
.sidebar .widget_product_categories li,
.sidebar .widget_nav_menu > div li,
.sidebar .widget_meta li,
.sidebar .widget_pages li,
.sidebar .widget_recent_comments li,
.sidebar .widget_recent_entries li,
.sidebar .widget_rss li {
  padding-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.sidebar .widget_archive li:hover > ul,
.sidebar .widget_categories li:hover > ul,
.sidebar .widget_product_categories li:hover > ul,
.sidebar .widget_nav_menu > div li:hover > ul,
.sidebar .widget_meta li:hover > ul,
.sidebar .widget_pages li:hover > ul,
.sidebar .widget_recent_comments li:hover > ul,
.sidebar .widget_recent_entries li:hover > ul,
.sidebar .widget_rss li:hover > ul {
  color: #8f8f8f;
}

.sidebar .widget_archive li .show_count,
.sidebar .widget_categories li .show_count,
.sidebar .widget_product_categories li .show_count,
.sidebar .widget_nav_menu > div li .show_count,
.sidebar .widget_meta li .show_count,
.sidebar .widget_pages li .show_count,
.sidebar .widget_recent_comments li .show_count,
.sidebar .widget_recent_entries li .show_count,
.sidebar .widget_rss li .show_count {
  float: right;
}

.sidebar .widget_archive li:before,
.sidebar .widget_categories li:before,
.sidebar .widget_product_categories li:before,
.sidebar .widget_nav_menu > div li:before,
.sidebar .widget_meta li:before,
.sidebar .widget_pages li:before,
.sidebar .widget_recent_comments li:before,
.sidebar .widget_recent_entries li:before,
.sidebar .widget_rss li:before {
  content: "\f0da";
  font: normal normal normal 14px/1 FontAwesome;
  display: inline-block;
  margin-right: 5px;
  margin-top: -4px;
  font-size: 14px;
  vertical-align: middle;
  line-height: 14px;
}

.sidebar .widget_archive > ul > li:first-child,
.sidebar .widget_categories > ul > li:first-child,
.sidebar .widget_product_categories > ul > li:first-child,
.sidebar .widget_nav_menu > div > ul > li:first-child,
.sidebar .widget_meta > ul > li:first-child,
.sidebar .widget_pages > ul > li:first-child,
.sidebar .widget_recent_comments > ul > li:first-child,
.sidebar .widget_recent_entries > ul > li:first-child,
.sidebar .widget_rss > ul > li:first-child {
  padding-top: 0;
}

.sidebar .widget_archive li + li,
.sidebar .widget_categories li + li,
.sidebar .widget_product_categories li + li,
.sidebar .widget_nav_menu > div li + li,
.sidebar .widget_meta li + li,
.sidebar .widget_pages li + li,
.sidebar .widget_recent_comments li + li,
.sidebar .widget_recent_entries li + li,
.sidebar .widget_rss li + li {
  margin-top: 6px;
}

.sidebar .widget_archive a,
.sidebar .widget_categories a,
.sidebar .widget_product_categories a,
.sidebar .widget_nav_menu > div a,
.sidebar .widget_meta a,
.sidebar .widget_pages a,
.sidebar .widget_recent_comments a,
.sidebar .widget_recent_entries a,
.sidebar .widget_rss a {
  color: inherit;
}

.sidebar .widget_archive a:hover,
.sidebar .widget_categories a:hover,
.sidebar .widget_product_categories a:hover,
.sidebar .widget_nav_menu > div a:hover,
.sidebar .widget_meta a:hover,
.sidebar .widget_pages a:hover,
.sidebar .widget_recent_comments a:hover,
.sidebar .widget_recent_entries a:hover,
.sidebar .widget_rss a:hover {
  text-decoration: none;
}

.sidebar .widget_archive ul ul,
.sidebar .widget_categories ul ul,
.sidebar .widget_product_categories ul ul,
.sidebar .widget_nav_menu ul ul,
.sidebar .widget_meta ul ul,
.sidebar .widget_pages ul ul,
.sidebar .widget_recent_comments ul ul,
.sidebar .widget_recent_entries ul ul,
.sidebar .widget_rss ul ul {
  padding-left: 18px;
  padding-top: 6px;
  list-style-type: none;
  text-transform: none;
}

.sidebar .widget_archive ul ul li:before,
.sidebar .widget_categories ul ul li:before,
.sidebar .widget_product_categories ul ul li:before,
.sidebar .widget_nav_menu ul ul li:before,
.sidebar .widget_meta ul ul li:before,
.sidebar .widget_pages ul ul li:before,
.sidebar .widget_recent_comments ul ul li:before,
.sidebar .widget_recent_entries ul ul li:before,
.sidebar .widget_rss ul ul li:before {
  color: #8f8f8f;
}

.sidebar .widget-social-profile {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;
}

.sidebar .widget-social-profile li {
  display: inline-block;
  vertical-align: middle;
}

.sidebar .widget-social-profile li a {
  display: inline-block;
}

.sidebar .widget-social-profile li a i.fa {
  -webkit-transition: all .3s;
  transition: all .3s;
  font-size: 15px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  text-align: center;
  line-height: 24px;
}

.sidebar .widget-social-profile li + li {
  margin-left: 6px;
}

.sidebar .widget-social-profile.social-icon-bordered li a i.fa {
  border-radius: 50%;
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar .widget-social-profile.social-icon-bordered li a:hover i.fa {
  color: #222;
}

.sidebar .widget-social-profile.social-icon-bordered li + li {
  margin-left: 10px;
}

.sidebar ul.product_list_widget li {
  padding: 0px;
  margin-bottom: 15px;
}

.sidebar ul.product_list_widget li img {
  float: left;
  box-shadow: none;
  width: 70px;
  margin-left: 0px;
  margin-right: 20px;
  border: 1px solid #eee;
}

.sidebar ul.product_list_widget li a {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.sidebar ul.product_list_widget li a:hover {
  text-decoration: none;
}

.sidebar ul.product_list_widget li span.amount {
  font-size: 16px;
  color: #222;
  font-weight: bold;
}

.sidebar ul.product_list_widget li ins {
  text-decoration: none;
  margin-right: 3px;
}

.sidebar ul.product_list_widget li del span.amount {
  font-size: 14px;
  color: #cacaca;
}

.sidebar ul.product_list_widget li .star-rating {
  float: none;
  margin: 0 0 5px 0;
  font-size: 10px;
  width: 66px;
  height: 10px;
  letter-spacing: 3px;
}

.sidebar ul.product_list_widget li:last-child {
  margin-bottom: 0;
}

.sidebar .widget-posts-wrap {
  background-color: #f6f6f6;
  padding: 30px 20px;
}

.sidebar .widget-posts-wrap .widget_posts_item + .widget_posts_item {
  margin-top: 15px;
}

.sidebar .widget-posts-wrap .widget-posts-thumbnail {
  width: 64px;
  float: left;
  margin-right: 15px;
}

.sidebar .widget-posts-wrap .widget-posts-thumbnail .entry-thumbnail {
  position: relative;
}

.sidebar .widget-posts-wrap .widget-posts-thumbnail .entry-thumbnail a.prettyPhoto {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  right: 0;
  line-height: 20px;
  font-size: 10px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.sidebar .widget-posts-wrap .widget-posts-thumbnail .entry-thumbnail:hover a.entry-thumbnail_overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.sidebar .widget-posts-wrap .widget-posts-thumbnail .entry-thumbnail:hover a.prettyPhoto {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sidebar .widget-posts-wrap .widget-posts-thumbnail .entry-thumbnail_overlay img {
  width: 100%;
  display: block;
}

.sidebar .widget-posts-wrap .widget-posts-content-wrap {
  position: relative;
  overflow: hidden;
}

.sidebar .widget-posts-wrap .widget-posts-title {
  font-size: 14px;
  color: #222;
}

.sidebar .widget-posts-wrap .widget-posts-title:hover {
  text-decoration: none;
}

.sidebar .widget-posts-wrap .widget-posts-date {
  font-size: 12px;
  text-transform: uppercase;
}

.sidebar .widget-posts-wrap .owl-theme .owl-controls {
  display: none !important;
}

.sidebar .widget-twitter-wrap .widget-twitter-item + .widget-twitter-item {
  margin-top: 20px;
}

.sidebar .widget-twitter-wrap i {
  font-size: 14px;
}

.sidebar .widget-twitter-wrap span.twitter-time {
  color: #b1b1b1;
  display: block;
}

.sidebar .widget-twitter-wrap span.twitter-content a {
  color: #222;
}

.sidebar .widget-contact-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .widget-contact-info > li > i.fa {
  width: 18px;
}

.sidebar .mail-chimp-wrapper > p {
  line-height: 28px;
}

.sidebar .mail-chimp-wrapper .mail-chimp-button {
  position: relative;
}

.sidebar .mail-chimp-wrapper .mail-chimp-button > input {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  line-height: 1;
  height: 30px;
}

.sidebar .mail-chimp-wrapper .mail-chimp-button > button {
  border: none;
  background: none;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.sidebar .footer-logo .sub-description {
  padding-top: 28px;
  line-height: 28px;
}

.sidebar .widget-text-intro {
  font-size: 30px;
  font-family: "Oswald", sans-serif;
  text-align: center;
  border: dashed 3px #c5c5c5;
  padding: 55px 10px;
  font-weight: 100;
}

.right-sidebar aside,
.left-sidebar aside,
.unused-sidebar aside {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 30px;
}

.right-sidebar aside + aside,
.left-sidebar aside + aside,
.unused-sidebar aside + aside {
  margin-top: 30px;
}

.right-sidebar aside:last-child,
.left-sidebar aside:last-child,
.unused-sidebar aside:last-child {
  margin-bottom: 100px;
  padding-bottom: 0;
  border-bottom: none;
}

h4.widget-title {
  font-size: 21px;
  color: #222;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

h4.widget-title:after {
  content: "";
  width: 50px;
  height: 4px;
  display: block;
  margin-top: 10px;
}

.star-rating > span {
  display: inline-block;
  position: relative;
  width: 1.1em;
  font-size: 20px;
}

.star-rating > span:hover:before,
.star-rating > span:hover ~ span:before {
  content: "\2605";
  position: absolute;
}

/**filter**/
.wcd {
  width: 30%;
  height: 40px;
  float: left;
}

.wcd-logo {
  font-size: 36px;
  color: #fff;
  text-align: center;
  float: left;
  cursor: pointer;
  color: #fff;
  font-size: 32px;
  font-family: "Varela Round", sans-serif, sans-serif;
  -webkit-transition: color 0.3s ease-in;
  -moz-transition: color 0.3s ease-in;
  xآ²-o-transition: color 0.3s ease-in;
  transition: color 0.3s ease-in;
}

.wcd-logo:hover {
  color: #3facff;
}

.wcd-tuto {
  font-family: "Varela Round", sans-serif, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 36px;
}

.webcodo-top {
  width: 100%;
  height: 40px;
  background-color: #232323;
}

.clear {
  clear: both;
}

.prods-cnt .grid,
.prods-cnt .list {
  border-radius: 3px;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  float: left;
  cursor: pointer;
  padding: 5px;
}

.view-cnt {
  width: 70px;
  height: 25px;
  float: right;
  margin-right: 15px;
}

.prods-cnt {
  width: 100%;
  height: auto;
  background-color: transparent;
  border: 0 none;
  overflow: visible;
  margin: 0;
}

/* Category menu Style */
.category-menu {
  padding-right: 15px;
  float: right;
}

.category-menu .dropdown-toggle {
  max-width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding: 0 20px;
  font-size: 14px;
  color: #bababa;
  height: 40px;
  line-height: 1;
}

.category-menu .dropdown-menu {
  width: 100%;
  padding: 10px;
}

.category-menu .dropdown-menu li {
  cursor: pointer;
}

/* -----------------------------------------------------------------------------SHOP ------------------ */
.prod-box {
  width: 31%;
  height: auto;
  margin: 15px 0 15px 15px;
  float: left;
}

@media screen and (max-width: 900px) {
  .prod-box {
    width: 44%;
  }
  .extra-height {
    height: 375px;
  }
}

.prod-box img:first-child,
.prod-box-list img:first-child {
  width: 100%;
  float: left;
}

.prod-box h3 a {
  text-decoration: none;
  width: 140px;
  float: left;
  margin: 5px 0;
  color: #888;
  font: italic normal 14px georgia;
  font-style: italic;
}

.prod-box p {
  display: none;
}

.prod-box .old {
  margin-right: 20px;
  color: #be0000 !important;
  text-decoration: line-through;
}

.prod-box .buy-ico {
  border-radius: 20px;
  width: 35px;
  height: 35px;
  float: right;
  margin: 5px;
  cursor: pointer;
  background: #039fd3 url(../images/bag-ico.html) 50% no-repeat;
  -webkit-transition: background-color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}

.prod-box .buy-ico:hover {
  background-color: #7eb800;
}

/* List View Style */
.prod-box-list {
  height: 190px;
  width: 670px;
  margin: 15px;
  padding: 10px;
  background-color: #fff;
}

.prod-box-list h3 a {
  text-decoration: none;
  width: 320px;
  float: left;
  padding: 0;
  margin: 0 10px;
  color: #3c2e25;
  font: italic normal 22px georgia;
}

.prod-box-list p {
  color: #888;
  width: 360px;
  float: left;
  margin: 20px 10px 10px 10px;
  font: italic 13px georgia;
  line-height: 20px;
}

.prod-box-list .buy-ico {
  border-radius: 5px;
  width: 85px;
  height: 20px;
  color: #fff;
  padding: 5px 5px 5px 30px;
  font: italic 12px georgia;
  float: right;
  margin: 5px;
  cursor: pointer;
  background: #039fd3 url(../images/bag-ico.html) 5% 50% no-repeat;
  -webkit-transition: background-color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}

.prod-box-list .buy-ico:before {
  content: "More Details";
  line-height: 22px;
}

.prod-box-list .buy-ico:hover {
  background-color: #7eb800;
}

.prod-box-list .old {
  color: #be0000 !important;
  float: right;
  font-style: 18px;
  text-decoration: line-through;
}

a.button.product_type_simple.add_to_cart_button.ajax_add_to_cart {
  display: inline-block;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  text-transform: uppercase;
  border: 2px solid;
  padding: 1px 18px;
  font-size: 12px;
  background-color: #222;
  border-color: #222;
  color: #FFF;
  line-height: 2;
  border-radius: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 0;
  visibility: hidden;
  margin: 12px auto;
}

.product-add-to-cart {
  text-align: center;
}

a.product-name {
  display: block;
  font-size: 15px;
  text-align: center;
  color: #222;
  margin: 8px 0 5px;
  letter-spacing: -0.01em;
}

.price {
  width: 100%;
  display: inline;
  float: left;
  color: #181818;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

.view {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.view .mask,
.view .content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.view img {
  display: block;
  position: relative;
}

.view a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
}

.view-tenth img {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.view-tenth .mask {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}

.view-tenth a.info {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  margin: 45% auto;
}

.view-tenth:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  /* -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter: alpha(opacity=0);
   opacity: 0;*/
}

.view-tenth:hover .mask {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.view-tenth:hover a.info {
  -webkit-transform: scale(1);
  transform: scale(1);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.product-add-to-cart a.darna-button.style3 {
  margin-top: 12px;
  display: inline-block !important;
}

.image-container,
.data-container {
  float: left;
}

.image-container img.img-thumbnail {
  width: 100%;
}

.modal-header {
  min-height: 16.43px;
  padding: 2px 0;
  border-bottom: 0 none;
}

.modal-header .popup-close {
  width: 30px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  z-index: 1;
  top: 0;
  right: 0;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  opacity: 1;
}

.modal-content {
  border-radius: 0;
}

.modal-content .price {
  margin: 15px 0 30px;
  text-align: left;
}

.modal-body {
  padding: 15px 15px 30px;
}

/* -----------------------------------------------------------------------------SHOPCART ------------------ */
.shopcart-table thead > tr > th {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 20px;
  padding: 30px 20px;
  text-transform: uppercase;
  color: #fff;
  text-align: left;
  font-weight: normal;
  border-color: #222;
  background-color: #222;
}

.shopcart-table .media:first-child {
  margin-top: 20px;
}

.shopcart-table .thumbnail {
  width: 60px;
  float: left;
  border: 1px solid #ddd;
  margin-right: 20px;
}

.shopcart-table .media-body a:hover {
  text-decoration: none;
}

.shopcart-table .media-body a {
  font-size: 14px;
  color: #2a2a2a;
  text-transform: uppercase;
}

.shopcart-table tbody > tr td {
  border-top: 1px solid #ddd;
  border-right: transparent;
  text-align: left;
  padding: 0 20px 0;
  vertical-align: middle;
}

table.table.table-hover {
  border: 1px solid #ddd;
}

.shopcart-table .btn {
  background: transparent;
  color: #ddd;
  padding: 0;
}

.shopcart-table .btn:hover {
  text-decoration: none;
}

.spinner-control {
  width: 100px;
}

.spinner-control input {
  text-align: right;
}

.spinner-control .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.spinner-control .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
}

.spinner-control .input-group-btn-vertical > .btn:first-child {
  border-top-right-radius: 4px;
}

.spinner-control .input-group-btn-vertical > .btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 4px;
}

.spinner-control .input-group-btn-vertical i {
  position: absolute;
  top: 0;
  left: 4px;
}

.spinner-control input.form-control {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  text-align: left;
  border-radius: 0;
  box-shadow: none;
  padding: 8px;
}

.spinner-control button.btn.btn-default {
  width: auto;
  height: auto;
  line-height: 14px;
  background-color: transparent;
  border: 0 none;
  border-left: none;
  color: #8f8f8f;
  border-radius: 0;
}

.spinner-control .input-group-btn-vertical {
  z-index: 2;
  left: -18px;
}

.coupon {
  float: left;
  padding-bottom: 0;
}

.coupon .input-text {
  width: 320px;
  height: 34px;
  text-align: center;
  border: 1px solid #ddd;
}

.style-0 {
  color: #FFF;
  font-size: 12px;
  background-color: #222;
  padding: 5px 15px;
  line-height: 2;
  border: none;
  border-radius: 0;
  vertical-align: middle;
  text-align: center;
}

td.actions {
  padding: 20px !important;
}

.cart-collaterals-wrap form .form-row input.input-text,
.cart-collaterals-wrap form .form-row textarea,
.cart-collaterals-wrap form .form-row select,
.cart-content input[type=text],
.cart-content select,
textarea {
  height: 40px;
  background-color: #FFF;
  padding: 0 20px;
  font-size: 14px;
  text-transform: none;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
}

.cart-collaterals-wrap form .form-row {
  padding: 0;
  margin-bottom: 15px;
}

.cart-collaterals-wrap {
  padding: 60px 0 100px 0;
}

.cart-collaterals,
.cart-collaterals {
  width: auto;
  margin: 0 -15px;
}

.cart-collaterals h2,
.cart-collaterals h2 {
  font-size: 21px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.cart-collaterals .cart_totals,
.cart-collaterals .cart_totals {
  width: 100%;
  float: none;
  margin: 0;
}

.cart-collaterals .cart_totals_inner,
.cart-collaterals .cart_totals_inner,
.shipping-calculator {
  float: left;
  width: 50%;
  padding: 0 15px;
  margin-top: 40px;
}

.cart-collaterals .cart_totals_inner,
.cart-collaterals .cart_totals_inner {
  float: left;
}

.shipping-calculator {
  float: right;
}

.shipping-calculator-form {
  display: block !important;
}

.shipping-calculator-form .form-row {
  margin-bottom: 6px !important;
}

.shipping-calculator-form p {
  margin-bottom: 0;
}

.cart-collaterals .cart_totals_inner table {
  margin-bottom: 0;
  border: 1px solid #ddd;
  width: 100%;
  background: white;
}

.cart-collaterals .cart_totals_inner table th,
.cart-collaterals .cart_totals_inner table td {
  font-size: 15px;
  color: #2a2a2a;
  font-weight: normal;
  padding: 12px 20px;
}

.cart-collaterals .cart_totals_inner table th strong,
.cart-collaterals .cart_totals_inner table td strong {
  font-weight: normal;
}

.cart-collaterals .cart_totals_inner table td {
  text-align: right;
}

.cart-collaterals-wrap .cross-sells {
  margin-top: 0;
}

.cart-collaterals-wrap .cross-sells:before {
  display: none !important;
}

ul#shipping_method li {
  padding: 0;
  text-indent: 0;
}

ul#shipping_method li input[type=radio] {
  margin: 0;
  vertical-align: middle;
}

ul#shipping_method li label {
  font-weight: normal;
  margin: 0;
}

td.actions {
  border: 1px solid white;
  border-right: solid 1px white !important;
  padding: 60px 0 !important;
}

.cart-collaterals .cart_totals_inner,
.cart-collaterals .cart_totals_inner,
.shipping-calculator {
  float: left;
  width: 50%;
  padding: 0 15px;
  margin-top: 40px;
}

.cart-collaterals .cart_totals_inner,
.cart-collaterals .cart_totals_inner {
  float: left;
}

.shipping-calculator {
  float: right;
}

.shipping-calculator-form {
  display: block !important;
}

.shipping-calculator-form .form-row {
  margin-bottom: 6px !important;
}

.shipping-calculator-form p {
  margin-bottom: 0;
}

.cart .cart-collaterals .cart_totals_inner table {
  margin-bottom: 0;
  border: 1px solid #ddd;
}

.cart .cart-collaterals .cart_totals_inner table th,
.cart .cart-collaterals .cart_totals_inner table td {
  font-size: 15px;
  color: #2a2a2a;
  font-weight: normal;
  padding: 12px 20px;
}

.cart .cart-collaterals .cart_totals_inner table th strong,
.cart .cart-collaterals .cart_totals_inner table td strong {
  font-weight: normal;
}

.cart .cart-collaterals .cart_totals_inner table td {
  text-align: right;
}

.cart-content input[type=text],
.cart-content select,
.cart-content textare {
  margin: 15px 0;
}

.cart-content h3 {
  margin: 0 0 30px 0;
}

.entry-content:after,
.comment-text:after {
  content: "";
  clear: both;
  display: block;
}

.entry-content table,
.entry-content table td,
.comment-text table td,
.entry-content table th,
.comment-text table th {
  background-color: #fff;
}

.entry-content table,
.comment-text table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.entry-content table > thead > tr > th,
.comment-text table > thead > tr > th,
.entry-content table > tbody > tr > th,
.comment-text table > tbody > tr > th,
.entry-content table > tfoot > tr > th,
.comment-text table > tfoot > tr > th,
.entry-content table > thead > tr > td,
.comment-text table > thead > tr > td,
.entry-content table > tbody > tr > td,
.comment-text table > tbody > tr > td,
.entry-content table > tfoot > tr > td,
.comment-text table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #eee;
}

.entry-content table > thead > tr > th,
.comment-text table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #eee;
}

.entry-content table > caption + thead > tr:first-child > th,
.comment-text table > caption + thead > tr:first-child > th,
.entry-content table > colgroup + thead > tr:first-child > th,
.comment-text table > colgroup + thead > tr:first-child > th,
.entry-content table > thead:first-child > tr:first-child > th,
.comment-text table > thead:first-child > tr:first-child > th,
.entry-content table > caption + thead > tr:first-child > td,
.comment-text table > caption + thead > tr:first-child > td,
.entry-content table > colgroup + thead > tr:first-child > td,
.comment-text table > colgroup + thead > tr:first-child > td,
.entry-content table > thead:first-child > tr:first-child > td,
.comment-text table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.entry-content table > tbody + tbody,
.comment-text table > tbody + tbody {
  border-top: 1px solid #eee;
}

.entry-content blockquote,
.comment-text blockquote {
  padding: 20px 30px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  background-color: #f3f3f3;
  color: #2a2a2a;
  border-left-width: 10px;
  border-left-style: solid;
}

.entry-content blockquote cite,
.comment-text blockquote cite {
  font-size: 14px;
}

.entry-content a:hover,
.comment-text a:hover {
  text-decoration: underline;
}

.entry-content input[type=submit],
.comment-text input[type=submit] {
  display: inline-block;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  text-transform: uppercase;
  border: 2px solid;
  padding: 5px 20px;
  font-size: 13px;
  color: #fff;
}

.entry-content input[type=submit]:hover,
.comment-text input[type=submit]:hover,
.entry-content input[type=submit]:active,
.comment-text input[type=submit]:active {
  text-decoration: none !important;
  outline: none !important;
}

.entry-content input[type=submit]:hover,
.comment-text input[type=submit]:hover {
  background-color: #222;
  border-color: #222;
  color: #fff;
}

.entry-content p:last-child,
.comment-text p:last-child {
  margin-bottom: 0;
}

.entry-content dd,
.comment-text dd,
.entry-content dt,
.comment-text dt {
  line-height: 2;
}

form .form-row input.input-text,
form .form-row textarea,
form .form-row select {
  height: 40px;
  background-color: #FFF;
  padding: 0 20px;
  font-size: 14px;
  text-transform: none;
  border: 1px solid #ddd;
}

form .form-row {
  padding: 0;
  margin-bottom: 15px;
}

p.return-to-shop {
  margin-bottom: 80px !important;
}

table.shop_table.cart {
  border: none;
  margin-bottom: 60px;
  border-radius: 0;
}

table.shop_table.cart thead th {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 20px;
  padding: 30px 20px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-weight: normal;
  border-color: #222;
  background-color: #222;
}

table.shop_table.cart thead th.product-name {
  text-align: left;
}

table.shop_table.cart td {
  border-top: 1px solid #ddd;
  border-right: transparent;
  padding: 10px 20px;
}

table.shop_table.cart tbody td.product-name {
  border-left: 1px solid #ddd;
}

table.shop_table.cart tbody td.product-name .product-name-wrap {
  position: relative;
  overflow: hidden;
  height: 60px;
  display: table;
}

table.shop_table.cart tbody td.product-name .product-name-wrap .product-name-inner {
  display: table-cell;
  vertical-align: middle;
}

table.shop_table.cart tbody td.product-name .product-name-wrap .product-name-inner a {
  font-size: 14px;
  color: #2a2a2a;
  text-transform: uppercase;
}

table.shop_table.cart tbody td.product-name .product-name-wrap .product-name-inner a:hover {
  text-decoration: none;
}

td.product-name dl.variation {
  color: #888888;
}

td.product-name dl.variation dt {
  font-weight: normal;
  padding: 0 0 5px;
}

td.product-name dl.variation dd {
  padding: 0 0 5px;
  margin: 0;
}

td.product-name dl.variation {
  margin: 5px 0 0 0;
}

.cart table.cart img {
  width: 60px;
  float: left;
  border: 1px solid #ddd;
  margin-right: 20px;
}

table.shop_table tbody td.product-price,
table.shop_table tbody td.product-subtotal {
  font-size: 14px;
  color: #2a2a2a;
  text-transform: uppercase;
  text-align: center;
}

table.shop_table tbody td.product-quantity,
table.shop_table tbody td.product-remove {
  text-align: center;
}

table.shop_table tbody td.product-quantity .quantity-inner,
table.shop_table tbody td.product-remove .quantity-inner {
  display: inline-block;
}

table.shop_table tbody td.product-remove {
  border-right: 1px solid #ddd;
}

table.shop_table tbody td.product-remove a {
  font-size: 18px;
  color: #dddddd;
}

.cart table.cart td.actions {
  padding: 30px 0 0 0;
}

.cart table.cart td.actions .coupon .input-text {
  height: 34px;
  text-align: center;
}

.cart table.cart td.actions .button,
.shipping-calculator .button,
.cart table.cart td.actions input.button,
.shipping-calculator input.button,
.cart table.cart td.actions button.button,
.shipping-calculator button.button {
  color: #FFF;
  font-size: 12px;
  background-color: #222;
  padding: 5px 15px;
  line-height: 2;
  border: none;
  border-radius: 0;
  vertical-align: middle;
  text-align: center;
}

.cart table.cart td.actions .button:hover,
.shipping-calculator .button:hover,
.cart table.cart td.actions input.button:hover,
.shipping-calculator input.button:hover,
.cart table.cart td.actions button.button:hover,
.shipping-calculator button.button:hover {
  color: #fff;
}

.cart table.cart td.actions .wc-forward:hover,
.shipping-calculator .wc-forward:hover {
  background-color: #222;
}

.shipping-calculator .button,
.shipping-calculator input.button,
.shipping-calculator button.button {
  padding: 8px 15px;
  width: 100%;
}

.cart-collaterals-wrap {
  background-color: #f8f8f8;
  padding: 60px 0 100px 0;
}

.cart-collaterals,
.page .cart-collaterals {
  width: auto;
  margin: 0 -15px;
}

.cart-collaterals h2,
.page .cart-collaterals h2 {
  font-size: 21px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.cart-collaterals .cart_totals,
.page .cart-collaterals .cart_totals {
  width: 100%;
  float: none;
  margin: 0;
}

.cart-collaterals .cart_totals_inner,
.page .cart-collaterals .cart_totals_inner,
.shipping-calculator {
  float: left;
  width: 50%;
  padding: 0 15px;
  margin-top: 40px;
}

.cart-collaterals .cart_totals_inner,
.page .cart-collaterals .cart_totals_inner {
  float: left;
}

.shipping-calculator {
  float: right;
}

.shipping-calculator-form {
  display: block !important;
}

.shipping-calculator-form .form-row {
  margin-bottom: 6px !important;
}

.shipping-calculator-form p {
  margin-bottom: 0;
}

.cart .cart-collaterals .cart_totals_inner table {
  margin-bottom: 0;
  border: 1px solid #ddd;
}

.cart .cart-collaterals .cart_totals_inner table th,
.cart .cart-collaterals .cart_totals_inner table td {
  font-size: 15px;
  color: #2a2a2a;
  font-weight: normal;
  padding: 12px 20px;
}

.cart .cart-collaterals .cart_totals_inner table th strong,
.cart .cart-collaterals .cart_totals_inner table td strong {
  font-weight: normal;
}

.cart .cart-collaterals .cart_totals_inner table td {
  text-align: right;
}

.cross-sells {
  margin-top: 0;
}

.cross-sells:before {
  display: none !important;
}

ul#shipping_method li {
  padding: 0;
  text-indent: 0;
}

ul#shipping_method li input[type=radio] {
  margin: 0;
  vertical-align: middle;
}

ul#shipping_method li label {
  font-weight: normal;
  margin: 0;
}

.checkout .site-content-page {
  margin-bottom: 100px;
}

form.checkout_coupon {
  border: 1px solid #eee;
  border-radius: 0;
}

form.checkout_coupon input.button {
  padding-top: 6px;
  padding-bottom: 6px;
}

form.checkout_coupon .form-row {
  margin: 0;
}

.checkout form.login {
  border: 1px solid #eee;
  border-radius: 0;
}

.checkout form.login input.button {
  padding-top: 6px;
  padding-bottom: 6px;
}

.billing-fields h3,
#ship-to-different-address,
.checkout-review-order h3#order_review_heading,
#checkout-payment_heading {
  font-size: 21px;
  color: #2a2a2a;
  text-transform: uppercase;
  margin-bottom: 30px;
}

form .form-row label {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: normal;
  color: #2a2a2a;
}

.select2-container .select2-choice {
  background-color: #F6F6F6;
  padding: 10px 20px;
  font-size: 12px;
  text-transform: none;
  color: #1a3a4b;
  border: none;
  border-radius: 0;
}

.select2-container .select2-choice:hover {
  text-decoration: none;
}

.select2-drop-active {
  border: none;
  background-color: #F6F6F6;
}

#ship-to-different-address label {
  font-weight: normal;
  margin: 20px 0 0 0;
  display: inline-block;
}

#ship-to-different-address .input-checkbox {
  margin: 0;
  vertical-align: middle;
}

form .form-row textarea {
  height: 145px;
  padding: 20px;
}

table.shop_table.checkout-review-order-table,
table.shop_table.order_details,
table.shop_table.customer_details {
  border: 1px solid #eee;
  margin-bottom: 60px;
}

table.shop_table.checkout-review-order-table thead th,
table.shop_table.order_details thead th,
table.shop_table.customer_details thead th {
  border-bottom: none;
}

table.shop_table.checkout-review-order-table th,
table.shop_table.order_details th,
table.shop_table.customer_details th,
table.shop_table.checkout-review-order-table td,
table.shop_table.order_details td,
table.shop_table.customer_details td {
  font-size: 15px;
  color: #2a2a2a;
  font-weight: normal;
  padding: 12px 20px;
  border-top: 1px solid #eee;
  line-height: 2;
}

table.shop_table.checkout-review-order-table th strong,
table.shop_table.order_details th strong,
table.shop_table.customer_details th strong,
table.shop_table.checkout-review-order-table td strong,
table.shop_table.order_details td strong,
table.shop_table.customer_details td strong {
  font-weight: normal;
}

table.shop_table.checkout-review-order-table tfoot td,
table.shop_table.order_details tfoot td,
table.shop_table.customer_details tfoot td,
table.shop_table.checkout-review-order-table .product-total,
table.shop_table.order_details .product-total,
table.shop_table.customer_details .product-total {
  text-align: right;
}

table.shop_table.customer_details td {
  text-align: right;
}

.checkout #payment {
  background-color: transparent;
  border-radius: 0;
}

.checkout #payment ul.payment_methods {
  padding: 0;
  margin-bottom: 20px;
  border-bottom: none;
}

.checkout #payment ul.payment_methods li {
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
}

.checkout #payment ul.payment_methods li label {
  font-size: 13px;
  color: #222;
  font-weight: normal;
  margin: 0;
}

.checkout #payment ul.payment_methods li input {
  vertical-align: middle;
}

.checkout #payment div.payment_box {
  font-size: 12px;
  color: #a1a1a1;
  line-height: 2;
  background-color: transparent;
  margin: 5px 0 0 0;
  padding: 0;
  border-radius: 0;
}

.checkout #payment div.payment_box:after,
.checkout #payment div.payment_box:before {
  display: none;
}

.checkout #payment div.form-row {
  padding: 0;
  margin-bottom: 0;
}

#payment #place_order,
.page #payment #place_order {
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
}

.checkout .error {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.checkout-confirmation h2,
.account h2,
.checkout-confirmation h3,
.account h3 {
  font-size: 21px;
  color: #2a2a2a;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.space {
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-left {
  text-align: left !important;
}

.two-items > div,
.two-items a {
  float: left;
}

/*portofolio start*/
.portfolio-attribute {
  margin-top: 38px;
}

.portfolio-attribute .portfolio-info {
  background-color: #f8f8f8;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.portfolio-attribute .portfolio-info .portfolio-info-box {
  border-bottom: solid 1px #eee;
  padding-top: 15px;
  padding-bottom: 15px;
}

.portfolio-attribute .portfolio-info .portfolio-info-box:last-child {
  border-bottom: none;
}

.portfolio-info-box.share {
  position: relative;
}

.portfolio-info-box.share img {
  position: absolute;
  top: 11px;
}

.share h6 {
  font-size: 18px;
  display: inline-block;
  font-weight: 300;
}

.share .icon-wrap {
  display: inline-block;
  padding-left: 90px;
}

.share .icon-wrap a {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  background-color: #eee;
}

.share img {
  padding-left: 10px;
  padding-right: 10px;
}

.portfolio-content {
  margin-top: 38px;
}

.portfolio-content h5.title {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.portfolio-content h5.title:before,
.portfolio-content h5.title:after {
  content: '';
  position: absolute;
  width: 35px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.portfolio-content h5.title:before {
  top: 36px;
}

.portfolio-content h5.title:after {
  top: 40px;
  left: 15px;
}

.portfolio-info ul {
  list-style: none;
  padding-left: 0;
  color: #222;
}

.portfolio-info ul li:before {
  content: "::";
  padding-right: 10px;
}

.portfolio-info .portfolio-info-box h6 {
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  margin-bottom: 0;
}

.portfolio-info .portfolio-info-box h6 i {
  width: 25px;
}

.portfolio-info .portfolio-info-box .portfolio-term {
  display: inline-block;
  font-size: 14px;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.primary-font {
  font-family: "Lato", sans-serif;
}

.navbar.easy-sidebar .navbar-collapse {
  margin-left: 0;
  margin-right: 0;
  overflow-x: hidden;
}

.navbar.easy-sidebar .nav.navbar-nav > li {
  border-bottom: 1px solid #ddd;
}

.navbar.easy-sidebar .navbar-nav > .active > a,
.navbar.easy-sidebar .navbar-nav > .active > a:focus,
.navbar.easy-sidebar .navbar-nav > .active > a:hover,
.navbar.easy-sidebar .navbar-nav > li > a:hover,
.navbar.easy-sidebar .nav .open > a,
.nav .open > a:focus,
.navbar.easy-sidebar .open > a:hover {
  border-bottom: 0px none transparent;
}

.easy-sidebar .navbar-nav .open .dropdown-menu > li > a,
.easy-sidebar .navbar-nav .open .dropdown-menu > li > a:hover {
  height: auto;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  padding: 5px 15px 5px;
}

.navbar.easy-sidebar .burger-menu {
  top: 15px;
}

.navbar.easy-sidebar .dropdown-menu {
  position: relative;
  float: none;
  background: transparent;
  width: 100%;
  border: 0;
}

.header-2 .easy-sidebar .navbar-nav .open .dropdown-menu > li > a,
.header-2 .burger-menu:before,
.header-2 .easy-sidebar .navbar-nav .open .dropdown-menu > li > a:hover,
.header-3 .easy-sidebar .navbar-nav .open .dropdown-menu > li > a,
.header-5 .easy-sidebar .navbar-nav .open .dropdown-menu > li > a,
.header-5 .easy-sidebar .navbar-nav .open .dropdown-menu > li > a:hover {
  color: #fff;
}

.easy-sidebar .navbar-nav .open .dropdown-menu > li.active > a {
  color: #222;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-4 {
  margin: 4px;
}

.margin-top-20 {
  margin-top: 20%;
}

.grid-4 a {
  margin: 20px;
  display: inline-block;
}

.interact {
  display: none;
}

/* -----------------------------------------------------------------------------FOOTER AREA ------------------ */
a.back-to-top,
a.back-to-top:active {
  position: fixed;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  bottom: 10px;
  right: 10px;
  width: 44px;
  height: 44px;
  line-height: 38px;
  text-align: center;
  z-index: 100;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  border: 2px solid #1e1e1e;
  color: #1e1e1e;
  background-color: #f5f5f5;
  display: none;
}

form .form-row {
  padding: 0;
  margin-bottom: 15px;
}

form .form-row {
  padding: 3px;
  margin: 0 0 6px;
}

form .form-row-first,
form .form-row-last,
form .form-row-first,
form .form-row-last {
  float: left;
  width: 50%;
  overflow: visible;
}

.form-row label {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
  font-weight: normal;
  color: #2a2a2a;
  display: block;
}

form .form-row input.input-text,
form .form-row textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: 1;
}

label.checkbox {
  display: inline-block;
}

a.about_paypal {
  float: left;
}

.result-count {
  margin-left: 15px;
  float: left;
}

.ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100%;
  border-width: 2px;
  border-style: solid;
  top: -4px;
}

.ui-widget-content {
  border: 0 none;
}

.ui-slider .ui-slider-range {
  border-radius: 0;
}

.price-range,
.price-range input {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  text-align: right;
}

.price-range input {
  width: 80px;
  border: 0 none;
  margin-top: 15px;
}

.price-range-widget {
  display: block;
  padding-bottom: 65px !important;
}

.white-bg .widget-posts-wrap {
  background: #fff;
}

.widget-posts-wrap .price {
  text-align: left;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 16px;
}

.extra-height {
  height: 470px;
}

.pagination {
  display: inline-block;
  padding-left: 30%;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 14px;
  color: #8f8f8f;
  padding: 0;
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #eee;
  border-radius: 0;
  position: relative;
  float: left;
}

.pagination > li + li > a {
  margin-left: 10px;
}

.pagination > li + li > a:focus,
.pagination li a.active,
.pagination > li > a:hover {
  color: #fff;
}

.entry-c-quote blockquote {
  padding: 20px 30px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  background-color: #f3f3f3;
  color: #2a2a2a;
  border-left-width: 10px;
  border-left-style: solid;
  margin-bottom: 0;
}

.entry-c-quote blockquote p {
  font-size: 18px;
  color: #222;
}

blockquote cite {
  font-size: 14px;
  color: #222;
}

.entry-c-link {
  padding: 20px 30px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  background-color: #f3f3f3;
}

.entry-c-link a {
  color: #2a2a2a;
}

footer {
  background: url(../images/footer.jpg) no-repeat fixed;
  background-size: cover;
  padding: 60px 0 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  color: #cccccc;
}

footer a {
  color: #cccccc;
}

.footer-logo {
  margin-bottom: 60px;
}

.footer-logo p {
  padding-top: 32px;
  line-height: 20px;
  font-size: 16px;
  font-style: italic;
}

.footer-widget h5 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #02A8EB;
  margin: 0 0 10px;
}

.footer-widget a {
  font-size: 14px !important;
}

.footer-contact {
  font-size: 14px;
}

.footer-contact li {
  margin-bottom: 5px;
}

.footer-contact li i {
  width: 25px;
  padding-top: 3px;
  display: inline-block;
  vertical-align: top;
  line-height: normal;
  color: #02A8EB;
}

.footer-contact li span {
  display: inline-block;
  vertical-align: top;
  line-height: normal;
}

.footer-newsletter {
  margin-top: -4px;
}

.footer-newsletter p {
  margin-bottom: 20px;
}

.footer-newsletter form {
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}

.footer-newsletter input {
  background: transparent;
  color: #FFF;
  height: 30px;
  border: none;
  width: 100%;
  padding: 0 50px 0 20px;
}

.footer-newsletter button {
  background: transparent;
  border: medium none;
  padding: 0px;
  position: absolute;
  right: 11px;
  top: 4px;
}

.footer-menu li {
  margin-right: 10px;
}

.footer-menu li a {
  font-size: 12px;
  line-height: 42px;
}

.footer-copyright {
  border-top: 1px solid #494848;
  margin-top: 19px;
  padding: 19px 0 17px;
}

.footer-copyright p {
  font-size: 12px;
  padding: 7px 0 0;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
}

footer .overlay-footer {
  opacity: 0.5;
  z-index: -1 !important;
  display: block !important;
}

#tweecool {
  color: #8F8F8F;
  line-height: 23px;
  margin-top: -2px;
}

#tweecool a {
  color: #fff;
}

.tweets_txt span {
  text-transform: uppercase;
  font-size: 11px;
  color: #B1B1B1;
  display: block;
}

.tweets_txt {
  position: relative;
}

.tweets_txt:before {
  content: "\f099";
  font-family: "FontAwesome";
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 2px;
}

.vimeo {
  height: 90%;
  width: 100%;
}

.overlay.hided {
  display: none;
}

.googlframe {
  border: 0px none;
  height: 540px;
  width: 100%;
}

.top-bar .languages .btn-default {
  border: none;
  text-transform: uppercase;
}

.top-bar .languages .btn-default span {
  margin-right: 5px;
}

.top-bar .languages .dropdown-menu {
  min-width: 130px;
}

.top-bar .languages .dropdown-menu li.active a {
  background: #626262;
  color: #fff;
}

.top-bar .languages .dropdown-menu a {
  display: block;
  padding: 5px 10px;
  clear: both;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  background: none;
  font-family: "Roboto Condensed", sans-serif;
}

.top-bar .languages .dropdown-menu a span {
  margin-right: 10px;
}

.top-bar .languages .dropdown-menu a:hover {
  background: #626262;
  color: #fff;
}

.top-bar .languages .dropdown-menu li + li > a {
  border: none;
}

.top-bar .languages .dropdown-menu li a:before {
  content: "";
  padding: 0;
}

/* -----------------------------------------------------------------------------Body Boxed ------------------ */
body.body-boxed,
body.boxed-image,
body.boxed-color,
body.boxed-pattern {
  max-width: 1170px;
  margin: 0 auto;
  box-shadow: 0 0 6px 1px rgba(112, 112, 112, 0.2);
}

body.boxed-pattern {
  background-image: url(../images/pattern.png);
  background-repeat: repeat;
  background-color: #fff;
}

body.boxed-image {
  background-image: url(../images/bg-image.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

body.boxed-color {
  background: #121212;
}

.service-box2,
header,
.home-blog,
.home-info,
.home-projects-full,
.team-wrap,
.home-profile,
.home-process,
.home-features-side {
  background: #fff;
}

body.body-boxed .rev_slider,
body.boxed-image .rev_slider,
body.boxed-color.rev_slider,
body.boxed-pattern .rev_slider {
  width: 100% !important;
  left: 0 !important;
}

.overlay-wrap .overlay {
  z-index: 0;
  display: block !important;
}

.no-overflow {
  overflow: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-tb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-lr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

@media screen and (min-width: 979px) {
  .md-p-0 {
    padding: 0px 0 !important;
  }
  .md-m-t-0 {
    margin-top: 0px !important;
  }
  .md-m-b-0 {
    margin-bottom: 0px !important;
  }
  .md-m-r-0 {
    margin-right: 0px !important;
  }
  .md-m-l-0 {
    margin-left: 0px !important;
  }
  .md-p-t-0 {
    padding-top: 0px !important;
  }
  .md-p-b-0 {
    padding-bottom: 0px !important;
  }
  .md-p-r-0 {
    padding-right: 0px !important;
  }
  .md-p-l-0 {
    padding-left: 0px !important;
  }
  .md-p-tb-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md-m-tb-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .md-p-lr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md-p-0 {
    padding: 0px !important;
  }
  .md-m-0 {
    margin: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-0 {
    padding: 0px 0 !important;
  }
  .xs-m-t-0 {
    margin-top: 0px !important;
  }
  .xs-m-b-0 {
    margin-bottom: 0px !important;
  }
  .xs-m-r-0 {
    margin-right: 0px !important;
  }
  .xs-m-l-0 {
    margin-left: 0px !important;
  }
  .xs-p-t-0 {
    padding-top: 0px !important;
  }
  .xs-p-b-0 {
    padding-bottom: 0px !important;
  }
  .xs-p-r-0 {
    padding-right: 0px !important;
  }
  .xs-p-l-0 {
    padding-left: 0px !important;
  }
  .xs-p-tb-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xs-p-lr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xs-p-0 {
    padding: 0px !important;
  }
  .xs-m-0 {
    margin: 0px !important;
  }
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-tb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-lr-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

@media screen and (min-width: 979px) {
  .md-p-5 {
    padding: 5px 0 !important;
  }
  .md-m-t-5 {
    margin-top: 5px !important;
  }
  .md-m-b-5 {
    margin-bottom: 5px !important;
  }
  .md-m-r-5 {
    margin-right: 5px !important;
  }
  .md-m-l-5 {
    margin-left: 5px !important;
  }
  .md-p-t-5 {
    padding-top: 5px !important;
  }
  .md-p-b-5 {
    padding-bottom: 5px !important;
  }
  .md-p-r-5 {
    padding-right: 5px !important;
  }
  .md-p-l-5 {
    padding-left: 5px !important;
  }
  .md-p-tb-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md-m-tb-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .md-p-lr-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md-p-5 {
    padding: 5px !important;
  }
  .md-m-5 {
    margin: 5px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-5 {
    padding: 5px 0 !important;
  }
  .xs-m-t-5 {
    margin-top: 5px !important;
  }
  .xs-m-b-5 {
    margin-bottom: 5px !important;
  }
  .xs-m-r-5 {
    margin-right: 5px !important;
  }
  .xs-m-l-5 {
    margin-left: 5px !important;
  }
  .xs-p-t-5 {
    padding-top: 5px !important;
  }
  .xs-p-b-5 {
    padding-bottom: 5px !important;
  }
  .xs-p-r-5 {
    padding-right: 5px !important;
  }
  .xs-p-l-5 {
    padding-left: 5px !important;
  }
  .xs-p-tb-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xs-p-lr-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xs-p-5 {
    padding: 5px !important;
  }
  .xs-m-5 {
    margin: 5px !important;
  }
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-tb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

@media screen and (min-width: 979px) {
  .md-p-10 {
    padding: 10px 0 !important;
  }
  .md-m-t-10 {
    margin-top: 10px !important;
  }
  .md-m-b-10 {
    margin-bottom: 10px !important;
  }
  .md-m-r-10 {
    margin-right: 10px !important;
  }
  .md-m-l-10 {
    margin-left: 10px !important;
  }
  .md-p-t-10 {
    padding-top: 10px !important;
  }
  .md-p-b-10 {
    padding-bottom: 10px !important;
  }
  .md-p-r-10 {
    padding-right: 10px !important;
  }
  .md-p-l-10 {
    padding-left: 10px !important;
  }
  .md-p-tb-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md-m-tb-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .md-p-lr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md-p-10 {
    padding: 10px !important;
  }
  .md-m-10 {
    margin: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-10 {
    padding: 10px 0 !important;
  }
  .xs-m-t-10 {
    margin-top: 10px !important;
  }
  .xs-m-b-10 {
    margin-bottom: 10px !important;
  }
  .xs-m-r-10 {
    margin-right: 10px !important;
  }
  .xs-m-l-10 {
    margin-left: 10px !important;
  }
  .xs-p-t-10 {
    padding-top: 10px !important;
  }
  .xs-p-b-10 {
    padding-bottom: 10px !important;
  }
  .xs-p-r-10 {
    padding-right: 10px !important;
  }
  .xs-p-l-10 {
    padding-left: 10px !important;
  }
  .xs-p-tb-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xs-p-lr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xs-p-10 {
    padding: 10px !important;
  }
  .xs-m-10 {
    margin: 10px !important;
  }
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-tb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-lr-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

@media screen and (min-width: 979px) {
  .md-p-15 {
    padding: 15px 0 !important;
  }
  .md-m-t-15 {
    margin-top: 15px !important;
  }
  .md-m-b-15 {
    margin-bottom: 15px !important;
  }
  .md-m-r-15 {
    margin-right: 15px !important;
  }
  .md-m-l-15 {
    margin-left: 15px !important;
  }
  .md-p-t-15 {
    padding-top: 15px !important;
  }
  .md-p-b-15 {
    padding-bottom: 15px !important;
  }
  .md-p-r-15 {
    padding-right: 15px !important;
  }
  .md-p-l-15 {
    padding-left: 15px !important;
  }
  .md-p-tb-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md-m-tb-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .md-p-lr-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md-p-15 {
    padding: 15px !important;
  }
  .md-m-15 {
    margin: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-15 {
    padding: 15px 0 !important;
  }
  .xs-m-t-15 {
    margin-top: 15px !important;
  }
  .xs-m-b-15 {
    margin-bottom: 15px !important;
  }
  .xs-m-r-15 {
    margin-right: 15px !important;
  }
  .xs-m-l-15 {
    margin-left: 15px !important;
  }
  .xs-p-t-15 {
    padding-top: 15px !important;
  }
  .xs-p-b-15 {
    padding-bottom: 15px !important;
  }
  .xs-p-r-15 {
    padding-right: 15px !important;
  }
  .xs-p-l-15 {
    padding-left: 15px !important;
  }
  .xs-p-tb-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xs-p-lr-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xs-p-15 {
    padding: 15px !important;
  }
  .xs-m-15 {
    margin: 15px !important;
  }
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-tb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-lr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

@media screen and (min-width: 979px) {
  .md-p-20 {
    padding: 20px 0 !important;
  }
  .md-m-t-20 {
    margin-top: 20px !important;
  }
  .md-m-b-20 {
    margin-bottom: 20px !important;
  }
  .md-m-r-20 {
    margin-right: 20px !important;
  }
  .md-m-l-20 {
    margin-left: 20px !important;
  }
  .md-p-t-20 {
    padding-top: 20px !important;
  }
  .md-p-b-20 {
    padding-bottom: 20px !important;
  }
  .md-p-r-20 {
    padding-right: 20px !important;
  }
  .md-p-l-20 {
    padding-left: 20px !important;
  }
  .md-p-tb-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md-m-tb-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .md-p-lr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md-p-20 {
    padding: 20px !important;
  }
  .md-m-20 {
    margin: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-20 {
    padding: 20px 0 !important;
  }
  .xs-m-t-20 {
    margin-top: 20px !important;
  }
  .xs-m-b-20 {
    margin-bottom: 20px !important;
  }
  .xs-m-r-20 {
    margin-right: 20px !important;
  }
  .xs-m-l-20 {
    margin-left: 20px !important;
  }
  .xs-p-t-20 {
    padding-top: 20px !important;
  }
  .xs-p-b-20 {
    padding-bottom: 20px !important;
  }
  .xs-p-r-20 {
    padding-right: 20px !important;
  }
  .xs-p-l-20 {
    padding-left: 20px !important;
  }
  .xs-p-tb-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xs-p-lr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xs-p-20 {
    padding: 20px !important;
  }
  .xs-m-20 {
    margin: 20px !important;
  }
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-tb-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-lr-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

@media screen and (min-width: 979px) {
  .md-p-25 {
    padding: 25px 0 !important;
  }
  .md-m-t-25 {
    margin-top: 25px !important;
  }
  .md-m-b-25 {
    margin-bottom: 25px !important;
  }
  .md-m-r-25 {
    margin-right: 25px !important;
  }
  .md-m-l-25 {
    margin-left: 25px !important;
  }
  .md-p-t-25 {
    padding-top: 25px !important;
  }
  .md-p-b-25 {
    padding-bottom: 25px !important;
  }
  .md-p-r-25 {
    padding-right: 25px !important;
  }
  .md-p-l-25 {
    padding-left: 25px !important;
  }
  .md-p-tb-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .md-m-tb-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .md-p-lr-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .md-p-25 {
    padding: 25px !important;
  }
  .md-m-25 {
    margin: 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-25 {
    padding: 25px 0 !important;
  }
  .xs-m-t-25 {
    margin-top: 25px !important;
  }
  .xs-m-b-25 {
    margin-bottom: 25px !important;
  }
  .xs-m-r-25 {
    margin-right: 25px !important;
  }
  .xs-m-l-25 {
    margin-left: 25px !important;
  }
  .xs-p-t-25 {
    padding-top: 25px !important;
  }
  .xs-p-b-25 {
    padding-bottom: 25px !important;
  }
  .xs-p-r-25 {
    padding-right: 25px !important;
  }
  .xs-p-l-25 {
    padding-left: 25px !important;
  }
  .xs-p-tb-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xs-p-lr-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xs-p-25 {
    padding: 25px !important;
  }
  .xs-m-25 {
    margin: 25px !important;
  }
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-tb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-lr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

@media screen and (min-width: 979px) {
  .md-p-30 {
    padding: 30px 0 !important;
  }
  .md-m-t-30 {
    margin-top: 30px !important;
  }
  .md-m-b-30 {
    margin-bottom: 30px !important;
  }
  .md-m-r-30 {
    margin-right: 30px !important;
  }
  .md-m-l-30 {
    margin-left: 30px !important;
  }
  .md-p-t-30 {
    padding-top: 30px !important;
  }
  .md-p-b-30 {
    padding-bottom: 30px !important;
  }
  .md-p-r-30 {
    padding-right: 30px !important;
  }
  .md-p-l-30 {
    padding-left: 30px !important;
  }
  .md-p-tb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md-m-tb-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .md-p-lr-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md-p-30 {
    padding: 30px !important;
  }
  .md-m-30 {
    margin: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-30 {
    padding: 30px 0 !important;
  }
  .xs-m-t-30 {
    margin-top: 30px !important;
  }
  .xs-m-b-30 {
    margin-bottom: 30px !important;
  }
  .xs-m-r-30 {
    margin-right: 30px !important;
  }
  .xs-m-l-30 {
    margin-left: 30px !important;
  }
  .xs-p-t-30 {
    padding-top: 30px !important;
  }
  .xs-p-b-30 {
    padding-bottom: 30px !important;
  }
  .xs-p-r-30 {
    padding-right: 30px !important;
  }
  .xs-p-l-30 {
    padding-left: 30px !important;
  }
  .xs-p-tb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xs-p-lr-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xs-p-30 {
    padding: 30px !important;
  }
  .xs-m-30 {
    margin: 30px !important;
  }
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-tb-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-lr-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

@media screen and (min-width: 979px) {
  .md-p-35 {
    padding: 35px 0 !important;
  }
  .md-m-t-35 {
    margin-top: 35px !important;
  }
  .md-m-b-35 {
    margin-bottom: 35px !important;
  }
  .md-m-r-35 {
    margin-right: 35px !important;
  }
  .md-m-l-35 {
    margin-left: 35px !important;
  }
  .md-p-t-35 {
    padding-top: 35px !important;
  }
  .md-p-b-35 {
    padding-bottom: 35px !important;
  }
  .md-p-r-35 {
    padding-right: 35px !important;
  }
  .md-p-l-35 {
    padding-left: 35px !important;
  }
  .md-p-tb-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md-m-tb-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .md-p-lr-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md-p-35 {
    padding: 35px !important;
  }
  .md-m-35 {
    margin: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-35 {
    padding: 35px 0 !important;
  }
  .xs-m-t-35 {
    margin-top: 35px !important;
  }
  .xs-m-b-35 {
    margin-bottom: 35px !important;
  }
  .xs-m-r-35 {
    margin-right: 35px !important;
  }
  .xs-m-l-35 {
    margin-left: 35px !important;
  }
  .xs-p-t-35 {
    padding-top: 35px !important;
  }
  .xs-p-b-35 {
    padding-bottom: 35px !important;
  }
  .xs-p-r-35 {
    padding-right: 35px !important;
  }
  .xs-p-l-35 {
    padding-left: 35px !important;
  }
  .xs-p-tb-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xs-p-lr-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xs-p-35 {
    padding: 35px !important;
  }
  .xs-m-35 {
    margin: 35px !important;
  }
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-tb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-lr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

@media screen and (min-width: 979px) {
  .md-p-40 {
    padding: 40px 0 !important;
  }
  .md-m-t-40 {
    margin-top: 40px !important;
  }
  .md-m-b-40 {
    margin-bottom: 40px !important;
  }
  .md-m-r-40 {
    margin-right: 40px !important;
  }
  .md-m-l-40 {
    margin-left: 40px !important;
  }
  .md-p-t-40 {
    padding-top: 40px !important;
  }
  .md-p-b-40 {
    padding-bottom: 40px !important;
  }
  .md-p-r-40 {
    padding-right: 40px !important;
  }
  .md-p-l-40 {
    padding-left: 40px !important;
  }
  .md-p-tb-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md-m-tb-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .md-p-lr-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md-p-40 {
    padding: 40px !important;
  }
  .md-m-40 {
    margin: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-40 {
    padding: 40px 0 !important;
  }
  .xs-m-t-40 {
    margin-top: 40px !important;
  }
  .xs-m-b-40 {
    margin-bottom: 40px !important;
  }
  .xs-m-r-40 {
    margin-right: 40px !important;
  }
  .xs-m-l-40 {
    margin-left: 40px !important;
  }
  .xs-p-t-40 {
    padding-top: 40px !important;
  }
  .xs-p-b-40 {
    padding-bottom: 40px !important;
  }
  .xs-p-r-40 {
    padding-right: 40px !important;
  }
  .xs-p-l-40 {
    padding-left: 40px !important;
  }
  .xs-p-tb-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xs-p-lr-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xs-p-40 {
    padding: 40px !important;
  }
  .xs-m-40 {
    margin: 40px !important;
  }
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-tb-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-lr-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

@media screen and (min-width: 979px) {
  .md-p-45 {
    padding: 45px 0 !important;
  }
  .md-m-t-45 {
    margin-top: 45px !important;
  }
  .md-m-b-45 {
    margin-bottom: 45px !important;
  }
  .md-m-r-45 {
    margin-right: 45px !important;
  }
  .md-m-l-45 {
    margin-left: 45px !important;
  }
  .md-p-t-45 {
    padding-top: 45px !important;
  }
  .md-p-b-45 {
    padding-bottom: 45px !important;
  }
  .md-p-r-45 {
    padding-right: 45px !important;
  }
  .md-p-l-45 {
    padding-left: 45px !important;
  }
  .md-p-tb-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .md-m-tb-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .md-p-lr-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md-p-45 {
    padding: 45px !important;
  }
  .md-m-45 {
    margin: 45px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-45 {
    padding: 45px 0 !important;
  }
  .xs-m-t-45 {
    margin-top: 45px !important;
  }
  .xs-m-b-45 {
    margin-bottom: 45px !important;
  }
  .xs-m-r-45 {
    margin-right: 45px !important;
  }
  .xs-m-l-45 {
    margin-left: 45px !important;
  }
  .xs-p-t-45 {
    padding-top: 45px !important;
  }
  .xs-p-b-45 {
    padding-bottom: 45px !important;
  }
  .xs-p-r-45 {
    padding-right: 45px !important;
  }
  .xs-p-l-45 {
    padding-left: 45px !important;
  }
  .xs-p-tb-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xs-p-lr-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xs-p-45 {
    padding: 45px !important;
  }
  .xs-m-45 {
    margin: 45px !important;
  }
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-tb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-lr-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

@media screen and (min-width: 979px) {
  .md-p-50 {
    padding: 50px 0 !important;
  }
  .md-m-t-50 {
    margin-top: 50px !important;
  }
  .md-m-b-50 {
    margin-bottom: 50px !important;
  }
  .md-m-r-50 {
    margin-right: 50px !important;
  }
  .md-m-l-50 {
    margin-left: 50px !important;
  }
  .md-p-t-50 {
    padding-top: 50px !important;
  }
  .md-p-b-50 {
    padding-bottom: 50px !important;
  }
  .md-p-r-50 {
    padding-right: 50px !important;
  }
  .md-p-l-50 {
    padding-left: 50px !important;
  }
  .md-p-tb-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md-m-tb-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .md-p-lr-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md-p-50 {
    padding: 50px !important;
  }
  .md-m-50 {
    margin: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-50 {
    padding: 50px 0 !important;
  }
  .xs-m-t-50 {
    margin-top: 50px !important;
  }
  .xs-m-b-50 {
    margin-bottom: 50px !important;
  }
  .xs-m-r-50 {
    margin-right: 50px !important;
  }
  .xs-m-l-50 {
    margin-left: 50px !important;
  }
  .xs-p-t-50 {
    padding-top: 50px !important;
  }
  .xs-p-b-50 {
    padding-bottom: 50px !important;
  }
  .xs-p-r-50 {
    padding-right: 50px !important;
  }
  .xs-p-l-50 {
    padding-left: 50px !important;
  }
  .xs-p-tb-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xs-p-lr-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xs-p-50 {
    padding: 50px !important;
  }
  .xs-m-50 {
    margin: 50px !important;
  }
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.p-tb-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.p-lr-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.p-55 {
  padding: 55px !important;
}

.m-55 {
  margin: 55px !important;
}

@media screen and (min-width: 979px) {
  .md-p-55 {
    padding: 55px 0 !important;
  }
  .md-m-t-55 {
    margin-top: 55px !important;
  }
  .md-m-b-55 {
    margin-bottom: 55px !important;
  }
  .md-m-r-55 {
    margin-right: 55px !important;
  }
  .md-m-l-55 {
    margin-left: 55px !important;
  }
  .md-p-t-55 {
    padding-top: 55px !important;
  }
  .md-p-b-55 {
    padding-bottom: 55px !important;
  }
  .md-p-r-55 {
    padding-right: 55px !important;
  }
  .md-p-l-55 {
    padding-left: 55px !important;
  }
  .md-p-tb-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .md-m-tb-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .md-p-lr-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .md-p-55 {
    padding: 55px !important;
  }
  .md-m-55 {
    margin: 55px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-55 {
    padding: 55px 0 !important;
  }
  .xs-m-t-55 {
    margin-top: 55px !important;
  }
  .xs-m-b-55 {
    margin-bottom: 55px !important;
  }
  .xs-m-r-55 {
    margin-right: 55px !important;
  }
  .xs-m-l-55 {
    margin-left: 55px !important;
  }
  .xs-p-t-55 {
    padding-top: 55px !important;
  }
  .xs-p-b-55 {
    padding-bottom: 55px !important;
  }
  .xs-p-r-55 {
    padding-right: 55px !important;
  }
  .xs-p-l-55 {
    padding-left: 55px !important;
  }
  .xs-p-tb-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .xs-p-lr-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .xs-p-55 {
    padding: 55px !important;
  }
  .xs-m-55 {
    margin: 55px !important;
  }
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-tb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p-lr-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.m-60 {
  margin: 60px !important;
}

@media screen and (min-width: 979px) {
  .md-p-60 {
    padding: 60px 0 !important;
  }
  .md-m-t-60 {
    margin-top: 60px !important;
  }
  .md-m-b-60 {
    margin-bottom: 60px !important;
  }
  .md-m-r-60 {
    margin-right: 60px !important;
  }
  .md-m-l-60 {
    margin-left: 60px !important;
  }
  .md-p-t-60 {
    padding-top: 60px !important;
  }
  .md-p-b-60 {
    padding-bottom: 60px !important;
  }
  .md-p-r-60 {
    padding-right: 60px !important;
  }
  .md-p-l-60 {
    padding-left: 60px !important;
  }
  .md-p-tb-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .md-m-tb-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .md-p-lr-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .md-p-60 {
    padding: 60px !important;
  }
  .md-m-60 {
    margin: 60px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-60 {
    padding: 60px 0 !important;
  }
  .xs-m-t-60 {
    margin-top: 60px !important;
  }
  .xs-m-b-60 {
    margin-bottom: 60px !important;
  }
  .xs-m-r-60 {
    margin-right: 60px !important;
  }
  .xs-m-l-60 {
    margin-left: 60px !important;
  }
  .xs-p-t-60 {
    padding-top: 60px !important;
  }
  .xs-p-b-60 {
    padding-bottom: 60px !important;
  }
  .xs-p-r-60 {
    padding-right: 60px !important;
  }
  .xs-p-l-60 {
    padding-left: 60px !important;
  }
  .xs-p-tb-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xs-p-lr-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xs-p-60 {
    padding: 60px !important;
  }
  .xs-m-60 {
    margin: 60px !important;
  }
}

.m-t-65 {
  margin-top: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-tb-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.p-lr-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.p-65 {
  padding: 65px !important;
}

.m-65 {
  margin: 65px !important;
}

@media screen and (min-width: 979px) {
  .md-p-65 {
    padding: 65px 0 !important;
  }
  .md-m-t-65 {
    margin-top: 65px !important;
  }
  .md-m-b-65 {
    margin-bottom: 65px !important;
  }
  .md-m-r-65 {
    margin-right: 65px !important;
  }
  .md-m-l-65 {
    margin-left: 65px !important;
  }
  .md-p-t-65 {
    padding-top: 65px !important;
  }
  .md-p-b-65 {
    padding-bottom: 65px !important;
  }
  .md-p-r-65 {
    padding-right: 65px !important;
  }
  .md-p-l-65 {
    padding-left: 65px !important;
  }
  .md-p-tb-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .md-m-tb-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .md-p-lr-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .md-p-65 {
    padding: 65px !important;
  }
  .md-m-65 {
    margin: 65px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-65 {
    padding: 65px 0 !important;
  }
  .xs-m-t-65 {
    margin-top: 65px !important;
  }
  .xs-m-b-65 {
    margin-bottom: 65px !important;
  }
  .xs-m-r-65 {
    margin-right: 65px !important;
  }
  .xs-m-l-65 {
    margin-left: 65px !important;
  }
  .xs-p-t-65 {
    padding-top: 65px !important;
  }
  .xs-p-b-65 {
    padding-bottom: 65px !important;
  }
  .xs-p-r-65 {
    padding-right: 65px !important;
  }
  .xs-p-l-65 {
    padding-left: 65px !important;
  }
  .xs-p-tb-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .xs-p-lr-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .xs-p-65 {
    padding: 65px !important;
  }
  .xs-m-65 {
    margin: 65px !important;
  }
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-tb-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p-lr-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.m-70 {
  margin: 70px !important;
}

@media screen and (min-width: 979px) {
  .md-p-70 {
    padding: 70px 0 !important;
  }
  .md-m-t-70 {
    margin-top: 70px !important;
  }
  .md-m-b-70 {
    margin-bottom: 70px !important;
  }
  .md-m-r-70 {
    margin-right: 70px !important;
  }
  .md-m-l-70 {
    margin-left: 70px !important;
  }
  .md-p-t-70 {
    padding-top: 70px !important;
  }
  .md-p-b-70 {
    padding-bottom: 70px !important;
  }
  .md-p-r-70 {
    padding-right: 70px !important;
  }
  .md-p-l-70 {
    padding-left: 70px !important;
  }
  .md-p-tb-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .md-m-tb-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .md-p-lr-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .md-p-70 {
    padding: 70px !important;
  }
  .md-m-70 {
    margin: 70px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-70 {
    padding: 70px 0 !important;
  }
  .xs-m-t-70 {
    margin-top: 70px !important;
  }
  .xs-m-b-70 {
    margin-bottom: 70px !important;
  }
  .xs-m-r-70 {
    margin-right: 70px !important;
  }
  .xs-m-l-70 {
    margin-left: 70px !important;
  }
  .xs-p-t-70 {
    padding-top: 70px !important;
  }
  .xs-p-b-70 {
    padding-bottom: 70px !important;
  }
  .xs-p-r-70 {
    padding-right: 70px !important;
  }
  .xs-p-l-70 {
    padding-left: 70px !important;
  }
  .xs-p-tb-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .xs-p-lr-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .xs-p-70 {
    padding: 70px !important;
  }
  .xs-m-70 {
    margin: 70px !important;
  }
}

.m-t-75 {
  margin-top: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.p-tb-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.p-lr-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.m-75 {
  margin: 75px !important;
}

@media screen and (min-width: 979px) {
  .md-p-75 {
    padding: 75px 0 !important;
  }
  .md-m-t-75 {
    margin-top: 75px !important;
  }
  .md-m-b-75 {
    margin-bottom: 75px !important;
  }
  .md-m-r-75 {
    margin-right: 75px !important;
  }
  .md-m-l-75 {
    margin-left: 75px !important;
  }
  .md-p-t-75 {
    padding-top: 75px !important;
  }
  .md-p-b-75 {
    padding-bottom: 75px !important;
  }
  .md-p-r-75 {
    padding-right: 75px !important;
  }
  .md-p-l-75 {
    padding-left: 75px !important;
  }
  .md-p-tb-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .md-m-tb-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .md-p-lr-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .md-p-75 {
    padding: 75px !important;
  }
  .md-m-75 {
    margin: 75px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-75 {
    padding: 75px 0 !important;
  }
  .xs-m-t-75 {
    margin-top: 75px !important;
  }
  .xs-m-b-75 {
    margin-bottom: 75px !important;
  }
  .xs-m-r-75 {
    margin-right: 75px !important;
  }
  .xs-m-l-75 {
    margin-left: 75px !important;
  }
  .xs-p-t-75 {
    padding-top: 75px !important;
  }
  .xs-p-b-75 {
    padding-bottom: 75px !important;
  }
  .xs-p-r-75 {
    padding-right: 75px !important;
  }
  .xs-p-l-75 {
    padding-left: 75px !important;
  }
  .xs-p-tb-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .xs-p-lr-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .xs-p-75 {
    padding: 75px !important;
  }
  .xs-m-75 {
    margin: 75px !important;
  }
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-tb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p-lr-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.m-80 {
  margin: 80px !important;
}

@media screen and (min-width: 979px) {
  .md-p-80 {
    padding: 80px 0 !important;
  }
  .md-m-t-80 {
    margin-top: 80px !important;
  }
  .md-m-b-80 {
    margin-bottom: 80px !important;
  }
  .md-m-r-80 {
    margin-right: 80px !important;
  }
  .md-m-l-80 {
    margin-left: 80px !important;
  }
  .md-p-t-80 {
    padding-top: 80px !important;
  }
  .md-p-b-80 {
    padding-bottom: 80px !important;
  }
  .md-p-r-80 {
    padding-right: 80px !important;
  }
  .md-p-l-80 {
    padding-left: 80px !important;
  }
  .md-p-tb-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md-m-tb-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .md-p-lr-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .md-p-80 {
    padding: 80px !important;
  }
  .md-m-80 {
    margin: 80px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-80 {
    padding: 80px 0 !important;
  }
  .xs-m-t-80 {
    margin-top: 80px !important;
  }
  .xs-m-b-80 {
    margin-bottom: 80px !important;
  }
  .xs-m-r-80 {
    margin-right: 80px !important;
  }
  .xs-m-l-80 {
    margin-left: 80px !important;
  }
  .xs-p-t-80 {
    padding-top: 80px !important;
  }
  .xs-p-b-80 {
    padding-bottom: 80px !important;
  }
  .xs-p-r-80 {
    padding-right: 80px !important;
  }
  .xs-p-l-80 {
    padding-left: 80px !important;
  }
  .xs-p-tb-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xs-p-lr-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xs-p-80 {
    padding: 80px !important;
  }
  .xs-m-80 {
    margin: 80px !important;
  }
}

.m-t-85 {
  margin-top: 85px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.p-tb-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.p-lr-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.p-85 {
  padding: 85px !important;
}

.m-85 {
  margin: 85px !important;
}

@media screen and (min-width: 979px) {
  .md-p-85 {
    padding: 85px 0 !important;
  }
  .md-m-t-85 {
    margin-top: 85px !important;
  }
  .md-m-b-85 {
    margin-bottom: 85px !important;
  }
  .md-m-r-85 {
    margin-right: 85px !important;
  }
  .md-m-l-85 {
    margin-left: 85px !important;
  }
  .md-p-t-85 {
    padding-top: 85px !important;
  }
  .md-p-b-85 {
    padding-bottom: 85px !important;
  }
  .md-p-r-85 {
    padding-right: 85px !important;
  }
  .md-p-l-85 {
    padding-left: 85px !important;
  }
  .md-p-tb-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .md-m-tb-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .md-p-lr-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .md-p-85 {
    padding: 85px !important;
  }
  .md-m-85 {
    margin: 85px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-85 {
    padding: 85px 0 !important;
  }
  .xs-m-t-85 {
    margin-top: 85px !important;
  }
  .xs-m-b-85 {
    margin-bottom: 85px !important;
  }
  .xs-m-r-85 {
    margin-right: 85px !important;
  }
  .xs-m-l-85 {
    margin-left: 85px !important;
  }
  .xs-p-t-85 {
    padding-top: 85px !important;
  }
  .xs-p-b-85 {
    padding-bottom: 85px !important;
  }
  .xs-p-r-85 {
    padding-right: 85px !important;
  }
  .xs-p-l-85 {
    padding-left: 85px !important;
  }
  .xs-p-tb-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .xs-p-lr-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .xs-p-85 {
    padding: 85px !important;
  }
  .xs-m-85 {
    margin: 85px !important;
  }
}

.m-t-90 {
  margin-top: 90px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-tb-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p-lr-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.m-90 {
  margin: 90px !important;
}

@media screen and (min-width: 979px) {
  .md-p-90 {
    padding: 90px 0 !important;
  }
  .md-m-t-90 {
    margin-top: 90px !important;
  }
  .md-m-b-90 {
    margin-bottom: 90px !important;
  }
  .md-m-r-90 {
    margin-right: 90px !important;
  }
  .md-m-l-90 {
    margin-left: 90px !important;
  }
  .md-p-t-90 {
    padding-top: 90px !important;
  }
  .md-p-b-90 {
    padding-bottom: 90px !important;
  }
  .md-p-r-90 {
    padding-right: 90px !important;
  }
  .md-p-l-90 {
    padding-left: 90px !important;
  }
  .md-p-tb-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .md-m-tb-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .md-p-lr-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .md-p-90 {
    padding: 90px !important;
  }
  .md-m-90 {
    margin: 90px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-90 {
    padding: 90px 0 !important;
  }
  .xs-m-t-90 {
    margin-top: 90px !important;
  }
  .xs-m-b-90 {
    margin-bottom: 90px !important;
  }
  .xs-m-r-90 {
    margin-right: 90px !important;
  }
  .xs-m-l-90 {
    margin-left: 90px !important;
  }
  .xs-p-t-90 {
    padding-top: 90px !important;
  }
  .xs-p-b-90 {
    padding-bottom: 90px !important;
  }
  .xs-p-r-90 {
    padding-right: 90px !important;
  }
  .xs-p-l-90 {
    padding-left: 90px !important;
  }
  .xs-p-tb-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xs-p-lr-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xs-p-90 {
    padding: 90px !important;
  }
  .xs-m-90 {
    margin: 90px !important;
  }
}

.m-t-95 {
  margin-top: 95px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.p-tb-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.p-lr-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.p-95 {
  padding: 95px !important;
}

.m-95 {
  margin: 95px !important;
}

@media screen and (min-width: 979px) {
  .md-p-95 {
    padding: 95px 0 !important;
  }
  .md-m-t-95 {
    margin-top: 95px !important;
  }
  .md-m-b-95 {
    margin-bottom: 95px !important;
  }
  .md-m-r-95 {
    margin-right: 95px !important;
  }
  .md-m-l-95 {
    margin-left: 95px !important;
  }
  .md-p-t-95 {
    padding-top: 95px !important;
  }
  .md-p-b-95 {
    padding-bottom: 95px !important;
  }
  .md-p-r-95 {
    padding-right: 95px !important;
  }
  .md-p-l-95 {
    padding-left: 95px !important;
  }
  .md-p-tb-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .md-m-tb-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .md-p-lr-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .md-p-95 {
    padding: 95px !important;
  }
  .md-m-95 {
    margin: 95px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-95 {
    padding: 95px 0 !important;
  }
  .xs-m-t-95 {
    margin-top: 95px !important;
  }
  .xs-m-b-95 {
    margin-bottom: 95px !important;
  }
  .xs-m-r-95 {
    margin-right: 95px !important;
  }
  .xs-m-l-95 {
    margin-left: 95px !important;
  }
  .xs-p-t-95 {
    padding-top: 95px !important;
  }
  .xs-p-b-95 {
    padding-bottom: 95px !important;
  }
  .xs-p-r-95 {
    padding-right: 95px !important;
  }
  .xs-p-l-95 {
    padding-left: 95px !important;
  }
  .xs-p-tb-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .xs-p-lr-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .xs-p-95 {
    padding: 95px !important;
  }
  .xs-m-95 {
    margin: 95px !important;
  }
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-tb-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.p-lr-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.m-100 {
  margin: 100px !important;
}

@media screen and (min-width: 979px) {
  .md-p-100 {
    padding: 100px 0 !important;
  }
  .md-m-t-100 {
    margin-top: 100px !important;
  }
  .md-m-b-100 {
    margin-bottom: 100px !important;
  }
  .md-m-r-100 {
    margin-right: 100px !important;
  }
  .md-m-l-100 {
    margin-left: 100px !important;
  }
  .md-p-t-100 {
    padding-top: 100px !important;
  }
  .md-p-b-100 {
    padding-bottom: 100px !important;
  }
  .md-p-r-100 {
    padding-right: 100px !important;
  }
  .md-p-l-100 {
    padding-left: 100px !important;
  }
  .md-p-tb-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .md-m-tb-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .md-p-lr-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .md-p-100 {
    padding: 100px !important;
  }
  .md-m-100 {
    margin: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-100 {
    padding: 100px 0 !important;
  }
  .xs-m-t-100 {
    margin-top: 100px !important;
  }
  .xs-m-b-100 {
    margin-bottom: 100px !important;
  }
  .xs-m-r-100 {
    margin-right: 100px !important;
  }
  .xs-m-l-100 {
    margin-left: 100px !important;
  }
  .xs-p-t-100 {
    padding-top: 100px !important;
  }
  .xs-p-b-100 {
    padding-bottom: 100px !important;
  }
  .xs-p-r-100 {
    padding-right: 100px !important;
  }
  .xs-p-l-100 {
    padding-left: 100px !important;
  }
  .xs-p-tb-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .xs-p-lr-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .xs-p-100 {
    padding: 100px !important;
  }
  .xs-m-100 {
    margin: 100px !important;
  }
}

.m-t-105 {
  margin-top: 105px !important;
}

.m-b-105 {
  margin-bottom: 105px !important;
}

.m-r-105 {
  margin-right: 105px !important;
}

.m-l-105 {
  margin-left: 105px !important;
}

.p-t-105 {
  padding-top: 105px !important;
}

.p-b-105 {
  padding-bottom: 105px !important;
}

.p-r-105 {
  padding-right: 105px !important;
}

.p-l-105 {
  padding-left: 105px !important;
}

.p-tb-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.p-lr-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.p-105 {
  padding: 105px !important;
}

.m-105 {
  margin: 105px !important;
}

@media screen and (min-width: 979px) {
  .md-p-105 {
    padding: 105px 0 !important;
  }
  .md-m-t-105 {
    margin-top: 105px !important;
  }
  .md-m-b-105 {
    margin-bottom: 105px !important;
  }
  .md-m-r-105 {
    margin-right: 105px !important;
  }
  .md-m-l-105 {
    margin-left: 105px !important;
  }
  .md-p-t-105 {
    padding-top: 105px !important;
  }
  .md-p-b-105 {
    padding-bottom: 105px !important;
  }
  .md-p-r-105 {
    padding-right: 105px !important;
  }
  .md-p-l-105 {
    padding-left: 105px !important;
  }
  .md-p-tb-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  .md-m-tb-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  .md-p-lr-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  .md-p-105 {
    padding: 105px !important;
  }
  .md-m-105 {
    margin: 105px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-105 {
    padding: 105px 0 !important;
  }
  .xs-m-t-105 {
    margin-top: 105px !important;
  }
  .xs-m-b-105 {
    margin-bottom: 105px !important;
  }
  .xs-m-r-105 {
    margin-right: 105px !important;
  }
  .xs-m-l-105 {
    margin-left: 105px !important;
  }
  .xs-p-t-105 {
    padding-top: 105px !important;
  }
  .xs-p-b-105 {
    padding-bottom: 105px !important;
  }
  .xs-p-r-105 {
    padding-right: 105px !important;
  }
  .xs-p-l-105 {
    padding-left: 105px !important;
  }
  .xs-p-tb-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  .xs-p-lr-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  .xs-p-105 {
    padding: 105px !important;
  }
  .xs-m-105 {
    margin: 105px !important;
  }
}

.m-t-110 {
  margin-top: 110px !important;
}

.m-b-110 {
  margin-bottom: 110px !important;
}

.m-r-110 {
  margin-right: 110px !important;
}

.m-l-110 {
  margin-left: 110px !important;
}

.p-t-110 {
  padding-top: 110px !important;
}

.p-b-110 {
  padding-bottom: 110px !important;
}

.p-r-110 {
  padding-right: 110px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.p-tb-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.p-lr-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.p-110 {
  padding: 110px !important;
}

.m-110 {
  margin: 110px !important;
}

@media screen and (min-width: 979px) {
  .md-p-110 {
    padding: 110px 0 !important;
  }
  .md-m-t-110 {
    margin-top: 110px !important;
  }
  .md-m-b-110 {
    margin-bottom: 110px !important;
  }
  .md-m-r-110 {
    margin-right: 110px !important;
  }
  .md-m-l-110 {
    margin-left: 110px !important;
  }
  .md-p-t-110 {
    padding-top: 110px !important;
  }
  .md-p-b-110 {
    padding-bottom: 110px !important;
  }
  .md-p-r-110 {
    padding-right: 110px !important;
  }
  .md-p-l-110 {
    padding-left: 110px !important;
  }
  .md-p-tb-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .md-m-tb-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .md-p-lr-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .md-p-110 {
    padding: 110px !important;
  }
  .md-m-110 {
    margin: 110px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-110 {
    padding: 110px 0 !important;
  }
  .xs-m-t-110 {
    margin-top: 110px !important;
  }
  .xs-m-b-110 {
    margin-bottom: 110px !important;
  }
  .xs-m-r-110 {
    margin-right: 110px !important;
  }
  .xs-m-l-110 {
    margin-left: 110px !important;
  }
  .xs-p-t-110 {
    padding-top: 110px !important;
  }
  .xs-p-b-110 {
    padding-bottom: 110px !important;
  }
  .xs-p-r-110 {
    padding-right: 110px !important;
  }
  .xs-p-l-110 {
    padding-left: 110px !important;
  }
  .xs-p-tb-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .xs-p-lr-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .xs-p-110 {
    padding: 110px !important;
  }
  .xs-m-110 {
    margin: 110px !important;
  }
}

.m-t-115 {
  margin-top: 115px !important;
}

.m-b-115 {
  margin-bottom: 115px !important;
}

.m-r-115 {
  margin-right: 115px !important;
}

.m-l-115 {
  margin-left: 115px !important;
}

.p-t-115 {
  padding-top: 115px !important;
}

.p-b-115 {
  padding-bottom: 115px !important;
}

.p-r-115 {
  padding-right: 115px !important;
}

.p-l-115 {
  padding-left: 115px !important;
}

.p-tb-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.p-lr-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.p-115 {
  padding: 115px !important;
}

.m-115 {
  margin: 115px !important;
}

@media screen and (min-width: 979px) {
  .md-p-115 {
    padding: 115px 0 !important;
  }
  .md-m-t-115 {
    margin-top: 115px !important;
  }
  .md-m-b-115 {
    margin-bottom: 115px !important;
  }
  .md-m-r-115 {
    margin-right: 115px !important;
  }
  .md-m-l-115 {
    margin-left: 115px !important;
  }
  .md-p-t-115 {
    padding-top: 115px !important;
  }
  .md-p-b-115 {
    padding-bottom: 115px !important;
  }
  .md-p-r-115 {
    padding-right: 115px !important;
  }
  .md-p-l-115 {
    padding-left: 115px !important;
  }
  .md-p-tb-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .md-m-tb-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .md-p-lr-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .md-p-115 {
    padding: 115px !important;
  }
  .md-m-115 {
    margin: 115px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-115 {
    padding: 115px 0 !important;
  }
  .xs-m-t-115 {
    margin-top: 115px !important;
  }
  .xs-m-b-115 {
    margin-bottom: 115px !important;
  }
  .xs-m-r-115 {
    margin-right: 115px !important;
  }
  .xs-m-l-115 {
    margin-left: 115px !important;
  }
  .xs-p-t-115 {
    padding-top: 115px !important;
  }
  .xs-p-b-115 {
    padding-bottom: 115px !important;
  }
  .xs-p-r-115 {
    padding-right: 115px !important;
  }
  .xs-p-l-115 {
    padding-left: 115px !important;
  }
  .xs-p-tb-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .xs-p-lr-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .xs-p-115 {
    padding: 115px !important;
  }
  .xs-m-115 {
    margin: 115px !important;
  }
}

.m-t-120 {
  margin-top: 120px !important;
}

.m-b-120 {
  margin-bottom: 120px !important;
}

.m-r-120 {
  margin-right: 120px !important;
}

.m-l-120 {
  margin-left: 120px !important;
}

.p-t-120 {
  padding-top: 120px !important;
}

.p-b-120 {
  padding-bottom: 120px !important;
}

.p-r-120 {
  padding-right: 120px !important;
}

.p-l-120 {
  padding-left: 120px !important;
}

.p-tb-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.p-lr-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.p-120 {
  padding: 120px !important;
}

.m-120 {
  margin: 120px !important;
}

@media screen and (min-width: 979px) {
  .md-p-120 {
    padding: 120px 0 !important;
  }
  .md-m-t-120 {
    margin-top: 120px !important;
  }
  .md-m-b-120 {
    margin-bottom: 120px !important;
  }
  .md-m-r-120 {
    margin-right: 120px !important;
  }
  .md-m-l-120 {
    margin-left: 120px !important;
  }
  .md-p-t-120 {
    padding-top: 120px !important;
  }
  .md-p-b-120 {
    padding-bottom: 120px !important;
  }
  .md-p-r-120 {
    padding-right: 120px !important;
  }
  .md-p-l-120 {
    padding-left: 120px !important;
  }
  .md-p-tb-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .md-m-tb-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .md-p-lr-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .md-p-120 {
    padding: 120px !important;
  }
  .md-m-120 {
    margin: 120px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-120 {
    padding: 120px 0 !important;
  }
  .xs-m-t-120 {
    margin-top: 120px !important;
  }
  .xs-m-b-120 {
    margin-bottom: 120px !important;
  }
  .xs-m-r-120 {
    margin-right: 120px !important;
  }
  .xs-m-l-120 {
    margin-left: 120px !important;
  }
  .xs-p-t-120 {
    padding-top: 120px !important;
  }
  .xs-p-b-120 {
    padding-bottom: 120px !important;
  }
  .xs-p-r-120 {
    padding-right: 120px !important;
  }
  .xs-p-l-120 {
    padding-left: 120px !important;
  }
  .xs-p-tb-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .xs-p-lr-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .xs-p-120 {
    padding: 120px !important;
  }
  .xs-m-120 {
    margin: 120px !important;
  }
}

.m-t-125 {
  margin-top: 125px !important;
}

.m-b-125 {
  margin-bottom: 125px !important;
}

.m-r-125 {
  margin-right: 125px !important;
}

.m-l-125 {
  margin-left: 125px !important;
}

.p-t-125 {
  padding-top: 125px !important;
}

.p-b-125 {
  padding-bottom: 125px !important;
}

.p-r-125 {
  padding-right: 125px !important;
}

.p-l-125 {
  padding-left: 125px !important;
}

.p-tb-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.p-lr-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.p-125 {
  padding: 125px !important;
}

.m-125 {
  margin: 125px !important;
}

@media screen and (min-width: 979px) {
  .md-p-125 {
    padding: 125px 0 !important;
  }
  .md-m-t-125 {
    margin-top: 125px !important;
  }
  .md-m-b-125 {
    margin-bottom: 125px !important;
  }
  .md-m-r-125 {
    margin-right: 125px !important;
  }
  .md-m-l-125 {
    margin-left: 125px !important;
  }
  .md-p-t-125 {
    padding-top: 125px !important;
  }
  .md-p-b-125 {
    padding-bottom: 125px !important;
  }
  .md-p-r-125 {
    padding-right: 125px !important;
  }
  .md-p-l-125 {
    padding-left: 125px !important;
  }
  .md-p-tb-125 {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
  }
  .md-m-tb-125 {
    margin-top: 125px !important;
    margin-bottom: 125px !important;
  }
  .md-p-lr-125 {
    padding-left: 125px !important;
    padding-right: 125px !important;
  }
  .md-p-125 {
    padding: 125px !important;
  }
  .md-m-125 {
    margin: 125px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-125 {
    padding: 125px 0 !important;
  }
  .xs-m-t-125 {
    margin-top: 125px !important;
  }
  .xs-m-b-125 {
    margin-bottom: 125px !important;
  }
  .xs-m-r-125 {
    margin-right: 125px !important;
  }
  .xs-m-l-125 {
    margin-left: 125px !important;
  }
  .xs-p-t-125 {
    padding-top: 125px !important;
  }
  .xs-p-b-125 {
    padding-bottom: 125px !important;
  }
  .xs-p-r-125 {
    padding-right: 125px !important;
  }
  .xs-p-l-125 {
    padding-left: 125px !important;
  }
  .xs-p-tb-125 {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
  }
  .xs-p-lr-125 {
    padding-left: 125px !important;
    padding-right: 125px !important;
  }
  .xs-p-125 {
    padding: 125px !important;
  }
  .xs-m-125 {
    margin: 125px !important;
  }
}

.m-t-130 {
  margin-top: 130px !important;
}

.m-b-130 {
  margin-bottom: 130px !important;
}

.m-r-130 {
  margin-right: 130px !important;
}

.m-l-130 {
  margin-left: 130px !important;
}

.p-t-130 {
  padding-top: 130px !important;
}

.p-b-130 {
  padding-bottom: 130px !important;
}

.p-r-130 {
  padding-right: 130px !important;
}

.p-l-130 {
  padding-left: 130px !important;
}

.p-tb-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.p-lr-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.p-130 {
  padding: 130px !important;
}

.m-130 {
  margin: 130px !important;
}

@media screen and (min-width: 979px) {
  .md-p-130 {
    padding: 130px 0 !important;
  }
  .md-m-t-130 {
    margin-top: 130px !important;
  }
  .md-m-b-130 {
    margin-bottom: 130px !important;
  }
  .md-m-r-130 {
    margin-right: 130px !important;
  }
  .md-m-l-130 {
    margin-left: 130px !important;
  }
  .md-p-t-130 {
    padding-top: 130px !important;
  }
  .md-p-b-130 {
    padding-bottom: 130px !important;
  }
  .md-p-r-130 {
    padding-right: 130px !important;
  }
  .md-p-l-130 {
    padding-left: 130px !important;
  }
  .md-p-tb-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .md-m-tb-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .md-p-lr-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .md-p-130 {
    padding: 130px !important;
  }
  .md-m-130 {
    margin: 130px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-130 {
    padding: 130px 0 !important;
  }
  .xs-m-t-130 {
    margin-top: 130px !important;
  }
  .xs-m-b-130 {
    margin-bottom: 130px !important;
  }
  .xs-m-r-130 {
    margin-right: 130px !important;
  }
  .xs-m-l-130 {
    margin-left: 130px !important;
  }
  .xs-p-t-130 {
    padding-top: 130px !important;
  }
  .xs-p-b-130 {
    padding-bottom: 130px !important;
  }
  .xs-p-r-130 {
    padding-right: 130px !important;
  }
  .xs-p-l-130 {
    padding-left: 130px !important;
  }
  .xs-p-tb-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .xs-p-lr-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .xs-p-130 {
    padding: 130px !important;
  }
  .xs-m-130 {
    margin: 130px !important;
  }
}

.m-t-135 {
  margin-top: 135px !important;
}

.m-b-135 {
  margin-bottom: 135px !important;
}

.m-r-135 {
  margin-right: 135px !important;
}

.m-l-135 {
  margin-left: 135px !important;
}

.p-t-135 {
  padding-top: 135px !important;
}

.p-b-135 {
  padding-bottom: 135px !important;
}

.p-r-135 {
  padding-right: 135px !important;
}

.p-l-135 {
  padding-left: 135px !important;
}

.p-tb-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.p-lr-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.p-135 {
  padding: 135px !important;
}

.m-135 {
  margin: 135px !important;
}

@media screen and (min-width: 979px) {
  .md-p-135 {
    padding: 135px 0 !important;
  }
  .md-m-t-135 {
    margin-top: 135px !important;
  }
  .md-m-b-135 {
    margin-bottom: 135px !important;
  }
  .md-m-r-135 {
    margin-right: 135px !important;
  }
  .md-m-l-135 {
    margin-left: 135px !important;
  }
  .md-p-t-135 {
    padding-top: 135px !important;
  }
  .md-p-b-135 {
    padding-bottom: 135px !important;
  }
  .md-p-r-135 {
    padding-right: 135px !important;
  }
  .md-p-l-135 {
    padding-left: 135px !important;
  }
  .md-p-tb-135 {
    padding-top: 135px !important;
    padding-bottom: 135px !important;
  }
  .md-m-tb-135 {
    margin-top: 135px !important;
    margin-bottom: 135px !important;
  }
  .md-p-lr-135 {
    padding-left: 135px !important;
    padding-right: 135px !important;
  }
  .md-p-135 {
    padding: 135px !important;
  }
  .md-m-135 {
    margin: 135px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-135 {
    padding: 135px 0 !important;
  }
  .xs-m-t-135 {
    margin-top: 135px !important;
  }
  .xs-m-b-135 {
    margin-bottom: 135px !important;
  }
  .xs-m-r-135 {
    margin-right: 135px !important;
  }
  .xs-m-l-135 {
    margin-left: 135px !important;
  }
  .xs-p-t-135 {
    padding-top: 135px !important;
  }
  .xs-p-b-135 {
    padding-bottom: 135px !important;
  }
  .xs-p-r-135 {
    padding-right: 135px !important;
  }
  .xs-p-l-135 {
    padding-left: 135px !important;
  }
  .xs-p-tb-135 {
    padding-top: 135px !important;
    padding-bottom: 135px !important;
  }
  .xs-p-lr-135 {
    padding-left: 135px !important;
    padding-right: 135px !important;
  }
  .xs-p-135 {
    padding: 135px !important;
  }
  .xs-m-135 {
    margin: 135px !important;
  }
}

.m-t-140 {
  margin-top: 140px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.m-r-140 {
  margin-right: 140px !important;
}

.m-l-140 {
  margin-left: 140px !important;
}

.p-t-140 {
  padding-top: 140px !important;
}

.p-b-140 {
  padding-bottom: 140px !important;
}

.p-r-140 {
  padding-right: 140px !important;
}

.p-l-140 {
  padding-left: 140px !important;
}

.p-tb-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.p-lr-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.p-140 {
  padding: 140px !important;
}

.m-140 {
  margin: 140px !important;
}

@media screen and (min-width: 979px) {
  .md-p-140 {
    padding: 140px 0 !important;
  }
  .md-m-t-140 {
    margin-top: 140px !important;
  }
  .md-m-b-140 {
    margin-bottom: 140px !important;
  }
  .md-m-r-140 {
    margin-right: 140px !important;
  }
  .md-m-l-140 {
    margin-left: 140px !important;
  }
  .md-p-t-140 {
    padding-top: 140px !important;
  }
  .md-p-b-140 {
    padding-bottom: 140px !important;
  }
  .md-p-r-140 {
    padding-right: 140px !important;
  }
  .md-p-l-140 {
    padding-left: 140px !important;
  }
  .md-p-tb-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .md-m-tb-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .md-p-lr-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .md-p-140 {
    padding: 140px !important;
  }
  .md-m-140 {
    margin: 140px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-140 {
    padding: 140px 0 !important;
  }
  .xs-m-t-140 {
    margin-top: 140px !important;
  }
  .xs-m-b-140 {
    margin-bottom: 140px !important;
  }
  .xs-m-r-140 {
    margin-right: 140px !important;
  }
  .xs-m-l-140 {
    margin-left: 140px !important;
  }
  .xs-p-t-140 {
    padding-top: 140px !important;
  }
  .xs-p-b-140 {
    padding-bottom: 140px !important;
  }
  .xs-p-r-140 {
    padding-right: 140px !important;
  }
  .xs-p-l-140 {
    padding-left: 140px !important;
  }
  .xs-p-tb-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .xs-p-lr-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .xs-p-140 {
    padding: 140px !important;
  }
  .xs-m-140 {
    margin: 140px !important;
  }
}

.m-t-145 {
  margin-top: 145px !important;
}

.m-b-145 {
  margin-bottom: 145px !important;
}

.m-r-145 {
  margin-right: 145px !important;
}

.m-l-145 {
  margin-left: 145px !important;
}

.p-t-145 {
  padding-top: 145px !important;
}

.p-b-145 {
  padding-bottom: 145px !important;
}

.p-r-145 {
  padding-right: 145px !important;
}

.p-l-145 {
  padding-left: 145px !important;
}

.p-tb-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.p-lr-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.p-145 {
  padding: 145px !important;
}

.m-145 {
  margin: 145px !important;
}

@media screen and (min-width: 979px) {
  .md-p-145 {
    padding: 145px 0 !important;
  }
  .md-m-t-145 {
    margin-top: 145px !important;
  }
  .md-m-b-145 {
    margin-bottom: 145px !important;
  }
  .md-m-r-145 {
    margin-right: 145px !important;
  }
  .md-m-l-145 {
    margin-left: 145px !important;
  }
  .md-p-t-145 {
    padding-top: 145px !important;
  }
  .md-p-b-145 {
    padding-bottom: 145px !important;
  }
  .md-p-r-145 {
    padding-right: 145px !important;
  }
  .md-p-l-145 {
    padding-left: 145px !important;
  }
  .md-p-tb-145 {
    padding-top: 145px !important;
    padding-bottom: 145px !important;
  }
  .md-m-tb-145 {
    margin-top: 145px !important;
    margin-bottom: 145px !important;
  }
  .md-p-lr-145 {
    padding-left: 145px !important;
    padding-right: 145px !important;
  }
  .md-p-145 {
    padding: 145px !important;
  }
  .md-m-145 {
    margin: 145px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-145 {
    padding: 145px 0 !important;
  }
  .xs-m-t-145 {
    margin-top: 145px !important;
  }
  .xs-m-b-145 {
    margin-bottom: 145px !important;
  }
  .xs-m-r-145 {
    margin-right: 145px !important;
  }
  .xs-m-l-145 {
    margin-left: 145px !important;
  }
  .xs-p-t-145 {
    padding-top: 145px !important;
  }
  .xs-p-b-145 {
    padding-bottom: 145px !important;
  }
  .xs-p-r-145 {
    padding-right: 145px !important;
  }
  .xs-p-l-145 {
    padding-left: 145px !important;
  }
  .xs-p-tb-145 {
    padding-top: 145px !important;
    padding-bottom: 145px !important;
  }
  .xs-p-lr-145 {
    padding-left: 145px !important;
    padding-right: 145px !important;
  }
  .xs-p-145 {
    padding: 145px !important;
  }
  .xs-m-145 {
    margin: 145px !important;
  }
}

.m-t-150 {
  margin-top: 150px !important;
}

.m-b-150 {
  margin-bottom: 150px !important;
}

.m-r-150 {
  margin-right: 150px !important;
}

.m-l-150 {
  margin-left: 150px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

.p-r-150 {
  padding-right: 150px !important;
}

.p-l-150 {
  padding-left: 150px !important;
}

.p-tb-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.p-lr-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.p-150 {
  padding: 150px !important;
}

.m-150 {
  margin: 150px !important;
}

@media screen and (min-width: 979px) {
  .md-p-150 {
    padding: 150px 0 !important;
  }
  .md-m-t-150 {
    margin-top: 150px !important;
  }
  .md-m-b-150 {
    margin-bottom: 150px !important;
  }
  .md-m-r-150 {
    margin-right: 150px !important;
  }
  .md-m-l-150 {
    margin-left: 150px !important;
  }
  .md-p-t-150 {
    padding-top: 150px !important;
  }
  .md-p-b-150 {
    padding-bottom: 150px !important;
  }
  .md-p-r-150 {
    padding-right: 150px !important;
  }
  .md-p-l-150 {
    padding-left: 150px !important;
  }
  .md-p-tb-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .md-m-tb-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .md-p-lr-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .md-p-150 {
    padding: 150px !important;
  }
  .md-m-150 {
    margin: 150px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-p-150 {
    padding: 150px 0 !important;
  }
  .xs-m-t-150 {
    margin-top: 150px !important;
  }
  .xs-m-b-150 {
    margin-bottom: 150px !important;
  }
  .xs-m-r-150 {
    margin-right: 150px !important;
  }
  .xs-m-l-150 {
    margin-left: 150px !important;
  }
  .xs-p-t-150 {
    padding-top: 150px !important;
  }
  .xs-p-b-150 {
    padding-bottom: 150px !important;
  }
  .xs-p-r-150 {
    padding-right: 150px !important;
  }
  .xs-p-l-150 {
    padding-left: 150px !important;
  }
  .xs-p-tb-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .xs-p-lr-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .xs-p-150 {
    padding: 150px !important;
  }
  .xs-m-150 {
    margin: 150px !important;
  }
}

/*Margin for small devices*/
@media screen and (max-width: 991px) {
  /*margin top*/
  .mt-sm-30 {
    margin-top: 30px;
  }
  .mt-sm-50 {
    margin-top: 50px;
  }
  /*margin-bottom*/
  .mb-sm-30 {
    margin-bottom: 30px;
  }
  .mb-sm-50 {
    margin-bottom: 50px;
  }
}

.h-0 {
  height: 0px !important;
}

.h-5 {
  height: 5px !important;
}

.h-10 {
  height: 10px !important;
}

.h-15 {
  height: 15px !important;
}

.h-20 {
  height: 20px !important;
}

.h-25 {
  height: 25px !important;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-40 {
  height: 40px !important;
}

.h-45 {
  height: 45px !important;
}

.h-50 {
  height: 50px !important;
}

.h-55 {
  height: 55px !important;
}

.h-60 {
  height: 60px !important;
}

.h-65 {
  height: 65px !important;
}

.h-70 {
  height: 70px !important;
}

.h-75 {
  height: 75px !important;
}

.h-80 {
  height: 80px !important;
}

.h-85 {
  height: 85px !important;
}

.h-90 {
  height: 90px !important;
}

.h-95 {
  height: 95px !important;
}

.h-100 {
  height: 100px !important;
}

.h-105 {
  height: 105px !important;
}

.h-110 {
  height: 110px !important;
}

.h-115 {
  height: 115px !important;
}

.h-120 {
  height: 120px !important;
}

.h-125 {
  height: 125px !important;
}

.h-130 {
  height: 130px !important;
}

.h-135 {
  height: 135px !important;
}

.h-140 {
  height: 140px !important;
}

.h-145 {
  height: 145px !important;
}

.h-150 {
  height: 150px !important;
}

.h-155 {
  height: 155px !important;
}

.h-160 {
  height: 160px !important;
}

.h-165 {
  height: 165px !important;
}

.h-170 {
  height: 170px !important;
}

.h-175 {
  height: 175px !important;
}

.h-180 {
  height: 180px !important;
}

.h-185 {
  height: 185px !important;
}

.h-190 {
  height: 190px !important;
}

.h-195 {
  height: 195px !important;
}

.h-200 {
  height: 200px !important;
}

.h-205 {
  height: 205px !important;
}

.h-210 {
  height: 210px !important;
}

.h-215 {
  height: 215px !important;
}

.h-220 {
  height: 220px !important;
}

.h-225 {
  height: 225px !important;
}

.h-230 {
  height: 230px !important;
}

.h-235 {
  height: 235px !important;
}

.h-240 {
  height: 240px !important;
}

.h-245 {
  height: 245px !important;
}

.h-250 {
  height: 250px !important;
}

.h-255 {
  height: 255px !important;
}

.h-260 {
  height: 260px !important;
}

.h-265 {
  height: 265px !important;
}

.h-270 {
  height: 270px !important;
}

.h-275 {
  height: 275px !important;
}

.h-280 {
  height: 280px !important;
}

.h-285 {
  height: 285px !important;
}

.h-290 {
  height: 290px !important;
}

.h-295 {
  height: 295px !important;
}

.h-300 {
  height: 300px !important;
}

.h-305 {
  height: 305px !important;
}

.h-310 {
  height: 310px !important;
}

.h-315 {
  height: 315px !important;
}

.h-320 {
  height: 320px !important;
}

.h-325 {
  height: 325px !important;
}

.h-330 {
  height: 330px !important;
}

.h-335 {
  height: 335px !important;
}

.h-340 {
  height: 340px !important;
}

.h-345 {
  height: 345px !important;
}

.h-350 {
  height: 350px !important;
}

.h-355 {
  height: 355px !important;
}

.h-360 {
  height: 360px !important;
}

.h-365 {
  height: 365px !important;
}

.h-370 {
  height: 370px !important;
}

.h-375 {
  height: 375px !important;
}

.h-380 {
  height: 380px !important;
}

.h-385 {
  height: 385px !important;
}

.h-390 {
  height: 390px !important;
}

.h-395 {
  height: 395px !important;
}

.h-400 {
  height: 400px !important;
}

.h-405 {
  height: 405px !important;
}

.h-410 {
  height: 410px !important;
}

.h-415 {
  height: 415px !important;
}

.h-420 {
  height: 420px !important;
}

.h-425 {
  height: 425px !important;
}

.h-430 {
  height: 430px !important;
}

.h-435 {
  height: 435px !important;
}

.h-440 {
  height: 440px !important;
}

.h-445 {
  height: 445px !important;
}

.h-450 {
  height: 450px !important;
}

.h-455 {
  height: 455px !important;
}

.h-460 {
  height: 460px !important;
}

.h-465 {
  height: 465px !important;
}

.h-470 {
  height: 470px !important;
}

.h-475 {
  height: 475px !important;
}

.h-480 {
  height: 480px !important;
}

.h-485 {
  height: 485px !important;
}

.h-490 {
  height: 490px !important;
}

.h-495 {
  height: 495px !important;
}

.h-500 {
  height: 500px !important;
}

.h-505 {
  height: 505px !important;
}

.h-510 {
  height: 510px !important;
}

.h-515 {
  height: 515px !important;
}

.h-520 {
  height: 520px !important;
}

.h-525 {
  height: 525px !important;
}

.h-530 {
  height: 530px !important;
}

.h-535 {
  height: 535px !important;
}

.h-540 {
  height: 540px !important;
}

.h-545 {
  height: 545px !important;
}

.h-550 {
  height: 550px !important;
}

.h-555 {
  height: 555px !important;
}

.h-560 {
  height: 560px !important;
}

.h-565 {
  height: 565px !important;
}

.h-570 {
  height: 570px !important;
}

.h-575 {
  height: 575px !important;
}

.h-580 {
  height: 580px !important;
}

.h-585 {
  height: 585px !important;
}

.h-590 {
  height: 590px !important;
}

.h-595 {
  height: 595px !important;
}

.h-600 {
  height: 600px !important;
}

.h-605 {
  height: 605px !important;
}

.h-610 {
  height: 610px !important;
}

.h-615 {
  height: 615px !important;
}

.h-620 {
  height: 620px !important;
}

.h-625 {
  height: 625px !important;
}

.h-630 {
  height: 630px !important;
}

.h-635 {
  height: 635px !important;
}

.h-640 {
  height: 640px !important;
}

.h-645 {
  height: 645px !important;
}

.h-650 {
  height: 650px !important;
}

.h-655 {
  height: 655px !important;
}

.h-660 {
  height: 660px !important;
}

.h-665 {
  height: 665px !important;
}

.h-670 {
  height: 670px !important;
}

.h-675 {
  height: 675px !important;
}

.h-680 {
  height: 680px !important;
}

.h-685 {
  height: 685px !important;
}

.h-690 {
  height: 690px !important;
}

.h-695 {
  height: 695px !important;
}

.h-700 {
  height: 700px !important;
}

.h-705 {
  height: 705px !important;
}

.h-710 {
  height: 710px !important;
}

.h-715 {
  height: 715px !important;
}

.h-720 {
  height: 720px !important;
}

.h-725 {
  height: 725px !important;
}

.h-730 {
  height: 730px !important;
}

.h-735 {
  height: 735px !important;
}

.h-740 {
  height: 740px !important;
}

.h-745 {
  height: 745px !important;
}

.h-750 {
  height: 750px !important;
}

.h-755 {
  height: 755px !important;
}

.h-760 {
  height: 760px !important;
}

.h-765 {
  height: 765px !important;
}

.h-770 {
  height: 770px !important;
}

.h-775 {
  height: 775px !important;
}

.h-780 {
  height: 780px !important;
}

.h-785 {
  height: 785px !important;
}

.h-790 {
  height: 790px !important;
}

.h-795 {
  height: 795px !important;
}

.h-800 {
  height: 800px !important;
}

.h-805 {
  height: 805px !important;
}

.h-810 {
  height: 810px !important;
}

.h-815 {
  height: 815px !important;
}

.h-820 {
  height: 820px !important;
}

.h-825 {
  height: 825px !important;
}

.h-830 {
  height: 830px !important;
}

.h-835 {
  height: 835px !important;
}

.h-840 {
  height: 840px !important;
}

.h-845 {
  height: 845px !important;
}

.h-850 {
  height: 850px !important;
}

.h-855 {
  height: 855px !important;
}

.h-860 {
  height: 860px !important;
}

.h-865 {
  height: 865px !important;
}

.h-870 {
  height: 870px !important;
}

.h-875 {
  height: 875px !important;
}

.h-880 {
  height: 880px !important;
}

.h-885 {
  height: 885px !important;
}

.h-890 {
  height: 890px !important;
}

.h-895 {
  height: 895px !important;
}

.h-900 {
  height: 900px !important;
}

.h-905 {
  height: 905px !important;
}

.h-910 {
  height: 910px !important;
}

.h-915 {
  height: 915px !important;
}

.h-920 {
  height: 920px !important;
}

.h-925 {
  height: 925px !important;
}

.h-930 {
  height: 930px !important;
}

.h-935 {
  height: 935px !important;
}

.h-940 {
  height: 940px !important;
}

.h-945 {
  height: 945px !important;
}

.h-950 {
  height: 950px !important;
}

.h-955 {
  height: 955px !important;
}

.h-960 {
  height: 960px !important;
}

.h-965 {
  height: 965px !important;
}

.h-970 {
  height: 970px !important;
}

.h-975 {
  height: 975px !important;
}

.h-980 {
  height: 980px !important;
}

.h-985 {
  height: 985px !important;
}

.h-990 {
  height: 990px !important;
}

.h-995 {
  height: 995px !important;
}

.h-1000 {
  height: 1000px !important;
}

button#feedback {
  background-color: #031b54;
  color: #ebebeb;
  font-size: 20px !important;
  text-transform: uppercase;
  position: fixed;
  z-index: 9999;
  bottom: calc(50% + 50px);
  right: 0;
  padding-right: 60px;
  height: 45px !important;
  display: inline-block;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px;
  border-radius: 4px 4px 0 0;
  -webkit-transform: rotate(-90deg) translate(0, -100%);
  -moz-transform: rotate(-90deg) translate(0, -100%);
  -ms-transform: rotate(-90deg) translate(0, -100%);
  -o-transform: rotate(-90deg) translate(0, -100%);
  transform: rotate(-90deg) translate(0, -100%);
  transform-origin: 100% 0;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

button#feedback i {
  background-color: white;
  position: fixed;
  top: -1px;
  right: -1px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 27px;
  color: #031b54;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.feedback-box {
  z-index: 9999;
  position: fixed;
  bottom: calc(50% - 236px);
  right: 0;
  display: inline-block;
  width: 500px;
  -moz-perspective: 1000;
  -webkit-perspective: 1000;
  perspective: 1000;
  pointer-events: none;
}

.feedback-box .row {
  margin-bottom: 10px;
}

.feedback-box .content {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  background: white;
  border: 1px solid #ddd;
  border-right: none;
  border-bottom: none;
  overflow: hidden;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -moz-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -moz-transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  -o-transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  -webkit-transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.feedback-box .confirm {
  z-index: 2;
  color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #41C289;
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -moz-transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  -o-transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  -webkit-transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
  transition: all 0.4s cubic-bezier(0.685, -0.245, 0.19, 1.315);
}

.feedback-box .confirm h1 {
  text-align: center;
  font-weight: 600;
  Text-transform: uppercase;
  font-size: 70px;
  margin-top: 115px;
  letter-spacing: -.08em;
  line-height: 1.2;
}

.feedback-box .confirm h1 i {
  vertical-align: 3px;
}

.feedback-box .confirm h1 span {
  display: block;
  font-size: 40%;
  font-weight: 300;
  text-transform: none;
  letter-spacing: .02em;
}

.feedback-box.show {
  pointer-events: auto;
}

.feedback-box.show .content {
  -moz-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}

.feedback-box.show-confirm .confirm {
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.feedback-box.error textarea {
  border-color: red;
}

.feedback-box .header {
  z-index: 10;
  background-color: #031b54;
  color: white;
  font-weight: 400;
  font-size: 1.5em;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.feedback-box section {
  padding: 30px;
  overflow: hidden;
  -moz-transition: all ease;
  -o-transition: all ease;
  -webkit-transition: all ease;
  transition: all ease;
}

.feedback-box section textarea {
  z-index: 0;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  resize: none;
  color: #333;
  border: 1px solid #ddd;
  height: 150px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.feedback-box section textarea:focus {
  outline: none;
  border: 1px solid #999;
}

.feedback-box section button {
  display: block;
  text-align: center;
  width: 100%;
}

.feedback-box .close {
  line-height: 50px;
  margin-right: 10px;
  color: white;
}

@media (max-width: 768px) {
  .feedback-box {
    width: 100%;
    top: 0;
    z-index: 9999;
  }
  .feedback-box input {
    margin-bottom: 10px;
  }
}

.contact-container {
  padding: 50px 0;
}

.contact-container .title {
  margin-bottom: 20px;
}

.contact-container ul li {
  margin-bottom: 20px;
  clear: left;
  font-size: 16px;
  display: block;
}

.contact-container .icon-box {
  float: left;
  display: inline-block;
  margin-right: 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #fcf2f2;
  background-color: #02A8EB;
  color: #fff;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
}

.contact-container .icon-content {
  overflow: hidden;
  padding: 0;
  display: block;
  min-height: 40px;
}

.map-container {
  border-top: 2px solid #ebebeb;
}

.map-container .container {
  position: relative;
}

.map-container .container .icons {
  position: absolute;
  z-index: 9999;
  height: 50px;
  width: 50px;
  left: 0;
  top: -25px;
  background-color: #ebebeb;
  color: #02A8EB;
  font-size: 20px;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
}

footer {
  border-top: 2px solid #02A8EB;
}

.process-box h2 {
  margin-bottom: 15px;
}

.process-box span {
  color: #fff;
}
