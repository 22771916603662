/* -----------------------------------------------------------------------------NAVIGATION ------------------ */

.navbar-brand {
  float: left;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 999999;
  position: relative;
}

.navbar-brand img {
  max-height:60px;
}

.navbar {
  position: relative;
  min-height: auto;
  margin-bottom: 0px;
  border: 0px solid transparent;
  border-radius: 0px;
}

.navbar-default {
  border-color: #fff;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-nav>li>a:hover,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.navbar-nav>li>a {
  text-transform: uppercase;
  color: #222222;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}

.navbar-default .navbar-nav > li a i {
  color: #333;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.navbar-default .navbar-nav > li a:hover i {
  opacity: 0.8;
}

.searchlink {
  display: block;
  position: relative;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 70px;
  line-height: 75px;
  margin-left: 15px;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.searchlink:hover,
.searchlink.open {
  color: #ccc;
}

.searchlink.open > .searchform {
  display: block;
}


/** search field **/

.searchform {
  display: none;
  position: absolute;
  width: 300px;
  height: 50px;
  line-height: 30px;
  top: 78px;
  right: -25px;
  padding: 0 15px;
  cursor: default;
  z-index: 10;
}

.searchform:hover i {
  color: #000;
}

.searchlink.open .searchform {
  display: block;
}

#search {
  display: block;
  position: relative;
  font-size: 16px;
}

#s {
  width: 270px;
  background: #f5f5f5;
  padding: 8px 11px;
  border: 0;
  color: #000;
  border-radius: 2px;
}

.sbtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 7px;
  background: none;
  border: none;
  color: #bcbcbc;
  font-size: 20px;
  cursor: pointer;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
  font-size: 12px;
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a {
  font-size: 12px;
}

.navigation-02 ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.navigation-02 ul.social-icons li {
  font-size: 16px;
  color: #d9d9d9;
  display: inline-block;
  list-style-type: none;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}

.navigation-02 ul.social-icons li a {
  font-size: 16px;
  color: #d9d9d9;
  padding-right: 0px;
  padding-left: 0px;
}

.navigation-03 .navbar-nav>li>a {
  padding-top: 18px;
  padding-bottom: 13px;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  border-top: 3px solid transparent;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  border-top: 3px solid #fff;
}

.navigation-03.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
  color: #fff;
  font-size: 12px;
}

.navigation-03 ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.navigation-03 ul.social-icons li {
  color: #d9d9d9;
  display: inline-block;
  list-style-type: none;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}

.navigation-03 ul.social-icons li a {
  font-size: 16px;
  color: #fff;
  padding-right: 0px;
  padding-left: 0px;
}

.navigation-03 ul.social-icons li a:hover {
  color: #fff;
}

.navigation-04 .navbar-nav>li>a {
  padding-top: 18px;
  padding-bottom: 13px;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  border-top: 3px solid transparent;
}

.navigation-04 .navbar-inverse .navbar-nav>.active>a,
.navigation-04 .navbar-inverse .navbar-nav>.active>a:focus,
.navigation-04 .navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  border-top: 3px solid #fff;
}

.navigation-04.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
  color: #fff;
  font-size: 12px;
}

.navigation-04 ul.social-icons {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.navigation-04 ul.social-icons li {
  color: #d9d9d9;
  display: inline-block;
  list-style-type: none;
  padding-right: 10px;
  padding-left: 8px;
  text-align: center;
}

.navigation-04 ul.social-icons li a {
  font-size: 16px;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 8px;
  padding-right: 0px;
  padding-left: 0px;
}

.navigation-04 ul.social-icons li a:hover {
  color: #fff;
}

.navigation-04 .searchlink {
  display: block;
  position: relative;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin-left: 15px;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.navigation-04 .searchlink:hover,
.navigation-04 .searchlink.open {
  color: #ccc;
}

.navigation-04 .searchlink.open > .searchform {
  display: block;
}


/** search field **/

.navigation-04 .searchform {
  display: none;
  position: absolute;
  width: 300px;
  height: 50px;
  line-height: 30px;
  top: 55px;
  right: -25px;
  padding: 0 15px;
  cursor: default;
  z-index: 10;
}

.navigation-04 .searchform:hover i {
  color: #000;
}

.navigation-04 .searchlink.open .searchform {
  display: block;
}

nav.navigation-07 {
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
}

nav.navigation-07.navbar-default {
  background-color: transparent;
  border-bottom: 1px solid;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

nav.navigation-07.navbar-default .navbar-nav {
  width: 100%;
  text-align: center;
}

nav.navigation-07.navbar-default .navbar-nav > li {
  float: none;
  display: inline-block;
}

nav.navigation-07.navbar-default .navbar-nav > li:first-child {
  min-width: 200px;
  padding-right: 30px;
  border-right: 1px solid;
  border-right-color: rgba(255, 255, 255, 0.5);
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child {
  min-width: 100px;
  margin-left: 150px;
  border-left: 1px solid;
  border-left-color: rgba(255, 255, 255, 0.5);
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child ul li {
  display: inline-block;
  text-align: right;
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child ul li a i {
  color: #fff!important;
  font-size: 20px;
}

nav.navigation-07.navbar-default .navbar-nav > li:last-child ul li a:hover {
  opacity: 0.8;
}

nav.navigation-07.navbar-default .navbar-nav .searchlink {
  display: block;
  position: relative;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  height: 80px;
  line-height: 80px;
  margin-left: 15px;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

nav.navigation-07.navbar-default .navbar-nav>li>a.navbar-brand {
  padding-top: 20px;
  padding-bottom: 16px;
}

nav.navigation-07.navbar-default .navbar-nav>li>a {
  color: #fff;
}

nav.navigation-07.navbar-default .navbar-nav>.active>a,
nav.navigation-07.navbar-default .navbar-nav>.active>a:focus,
nav.navigation-07.navbar-default .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: transparent;
  border-bottom: 4px solid #fff;
}

nav.fill-black.normal {
  background: rgba(20, 20, 20, 0);
  position: fixed;
  width: 100%;
  z-index: 999999;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

nav.fill-black.sticky {
  position: fixed;
  width: 100%;
  background: rgba(20, 20, 20, 1);
  z-index: 999999;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

nav.navigation-07.navbar-default ul.nav .active a {
  color: #fff;
  background-color: transparent;
  border-bottom: 4px solid #fff;
}

nav.navigation-10 {
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
}

nav.navigation-10.navbar-default {
  background-color: transparent;
  border-bottom: 1px solid;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

#search {
  display: block;
  position: relative;
  font-size: 16px;
}

#s {
  width: 225px;
  background: #f5f5f5;
  padding: 8px 11px;
  border: 0;
  color: #000;
  border-radius: 2px;
}

.sbtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 7px;
  background: none;
  border: none;
  color: #bcbcbc;
  font-size: 20px;
  cursor: pointer;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  background-color: transparent;
}

.search-form {
  position: relative
}

.search-form input[type=text] {
  width: 100%;
  padding-right: 48px;
  height: 38px;
  border-color: #F3F3F3
}

.search-form input[type=text]:focus + button {
  background-color: #222
}

.search-form button[type=submit] {
  position: absolute;
  border: none;
  right: 0;
  top: 0;
  padding: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  font-size: 14px
}

.search-form button[type=submit]:hover {
  background-color: #222
}

@media screen and (max-width:500px) {
  .navbar-brand img {
    margin: 5px 0;
    height: 40px;
  }
}
