
/* -----------------------------------------------------------------------------QUOTES ------------------ */

.quote-info2 i {
  border-radius: 50%;
  color: #FFF;
  font-size: 27px;
  display: inline-block;
  width: 63px;
  height: 63px;
  line-height: 63px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  margin: auto;
}
