
/* -----------------------------------------------------------------------------MESSAGES ------------------ */
#error-wrap {
  background: #fff url(../images/404.jpg) no-repeat center;
  background-size: cover;
}
#demo-wrap{    background: #fff url(../images/demo.jpg) no-repeat center;
  background-size: cover;}
.error-content {
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
}

.error-content h2 {
  font-size: 162px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0px;
}

.error-content h2 span {
  display: block;
  font-weight: 400;
  font-size: 32px;
  color: #222;
  line-height: 1;
  display: block;
  letter-spacing: 6px;
  margin: 10px 0 0;
}

.error-content img {
  padding: 40px 0 60px;
}

.error-search {
  height: 38px;
  position: relative;
  width: 100%;
  max-width: 35%;
  margin: 85px auto 60px;
}

.error-search input {
  width: 100%;
  padding: 0 48px 0 15px;
  height: 38px;
  border: 1px solid #F3F3F3;
}

.error-search button {
  position: absolute;
  border: medium none;
  right: 0px;
  top: 0px;
  padding: 0px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #FFF;
  font-size: 14px;
}

.error-search input:focus + button,
.error-search button:hover {
  background-color: #222;
  color: #fff;
}

.error-social a {
  padding: 0 15px;
  font-size: 22px;
  color: #8F8F8F;
}

.error-content .copyright {
  color: #000;
  font-size: 14px !important;
}

.lmore {
  padding: 5px 55px;
}
