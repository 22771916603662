

/* -----------------------------------------------------------------------------FEATURES ------------------ */

.home-features {
  padding: 43px 0px 106px;
  background: #fff;
}

.feature-box {
  margin-top: 56px;
}

.feature-box-ico {
  display: block;
  height: 68px;
  width: 68px;
  float: left;
  text-align: center;
  background-color: transparent;
  border: 1px solid #DDD;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  font-size: 30px;
  line-height: 66px;
  color: #DDD;
}

.feature-box:hover .feature-box-ico {
  border: 1px solid #DDD;
  color: #fff;
}

.feature-box h3 {
  padding-left: 100px;
  font-size: 21px;
  color: #222;
  margin: 0 0 15px;
  line-height: 0.95;
  padding-top: 5px;
  font-weight: 400;
  text-transform: uppercase;
}

.feature-box h3 a {
  color: #222;
}

.feature-box p {
  padding-left: 100px;
  line-height: 24px;
  margin-bottom: -21px;
}
