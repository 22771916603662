


/* -----------------------------------------------------------------------------CONTACT ------------------ */

.contact-info {
  padding: 90px 0;
}

.contact-info i {
  font-size: 32px;
  line-height: 110px;
  color: #FFF;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background-color: #222;
  margin: 0 auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact-info h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  color: #222;
  margin: 30px 0 12px;
  line-height: 1.11;
}

.contact-info h3 a {
  color: #222;
}

.contact-info p {
  line-height: 24px;
  margin: 0;
}

.google-map {
  background: #000;
  height: 540px;
}

.contact-form {
  padding: 43px 0 100px;
}

.contact-form form input,
.contact-form form textarea {
  padding: 0px 20px;
  font-size: 14px;
  color: #8F8F8F;
  width: 100%;
  height: 40px;
  line-height: 1;
  background-color: #FFF;
  border: 1px solid #EEE;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  margin-bottom: 30px;
}

.contact-form form textarea {
  height: auto;
  padding: 15px 20px;
}

.contact-form form input:focus,
.contact-form form textarea:focus {
  border-width: 1px;
  border-style: solid;
}

.ct-block {
  font-family: $roboto-sans;
  display: inline-block;
  position: relative;
  border: 1px solid #fff;
  margin: 0 10px;
  font-size: 68px;
  line-height: 1;
  padding: 35px 35px 0;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.ct-block span {
  padding: 18px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  display: block;
  margin: 35px -35px 0;
}

#countdown-wrap {
  background: url(../images/countdown.jpg) no-repeat top center;
  background-size: cover;
  height: 100vh;
}

#countdown-wrap img {
  margin: 65px auto 60px;
}

#countdown-wrap p {
  font-weight: 400;
  margin: 0px 0px 70px;
  color: #fff;
  line-height: 1.4;
  font-size: 20px;
}

.copyright {
  color: #fff;
  margin-top: 70px;
  font-size: 12px;
}
