
/* -----------------------------------------------------------------------------COUNTER ------------------ */
.counter-box-info .count {
  font-family: $oswald-sans;
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
}

.counter-box-info p {
  font-family: $oswald-sans;
  font-size: 18px;
  font-weight: 400;
  color: #FFF;
  line-height: 1;
  padding-top: 21px;
}

.team-wrap {
  padding: 43px 0px 90px;
}

.staff-pic {
  position: relative;
}

.staff-info h3 {
  font-size: 18px;
  color: #292929;
  line-height: 1.25;
  letter-spacing: -0.01em;
  font-weight: 400;
  margin: 0px;
  padding-top: 30px;
}

.staff-info p {
  font-size: 11px;
  color: #8F8F8F;
  line-height: 1;
  font-weight: bold;
  margin: 0px;
  display: block;
  padding: 12px 0px 30px;
}
