/* -----------------------------------------------------------------------------BUTTONS ------------------ */

.bttn {
  color: #FFF;
  display: table;
  text-transform: uppercase;
  -webkit-transition: .4s;
  transition: .4s;
  border-style: solid;
  border-width: 2px;
}

.bttn-xs {
  padding: 4px 18px;
  font-size: 12px;
}

.bttn-sm {
  padding: 5px 12px;
  font-size: 14px;
}

.bttn-md {
  padding: 9px 20px;
  font-size: 13px;
}

.bttn:hover {
  background-color: #222;
  border: 2px solid #222;
  color: #FFF;
  -webkit-transition: .4s;
  transition: .4s;
}

.bttn-black {
  background: #222222;
  border-color: #222222;
}

.bttn-bdr-black {
  background-color: #FFF;
  border-color: #222;
  color: #222;
}

.bttn-bdr-white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.bttn-bdr-white:hover,
.bttn-bdr-black:hover {
  color: #fff;
}

.service-box1 {
  background: #000 url(../images/bg/1.jpg) no-repeat center top;
  background-size: cover;
  padding: 100px 0 90px;
}

.s1-box p {
  color: #FFF;
  line-height: 24px;
}

.overlay-wrap {
  position: relative;
}

.overlay-wrap .container {
  position: relative;
  z-index: 55;
}

.darna-button {
  display: inline-block;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  text-transform: uppercase;
  border: 2px solid
}

.darna-button:hover,
.darna-button:active {
  text-decoration: none !important;
  outline: none !important
}

.darna-button.size-xs {
  padding: 1px 18px;
  font-size: 12px
}

.darna-button.size-sm {
  padding: 2px 20px;
  font-size: 13px
}

.darna-button.size-md {
  padding: 6px 20px;
  font-size: 13px
}

.darna-button.size-lg {
  padding: 6px 22px;
  font-size: 14px
}

.darna-button.style1 {
  color: #fff
}

.darna-button.style1:hover {
  background-color: #222;
  border-color: #222;
  color: #fff
}

.darna-button.style2 {
  background-color: transparent;
  border-color: #fff;
  color: #fff
}

.darna-button.style2:hover {
  color: #fff
}

.darna-button.style3 {
  background-color: #222;
  border-color: #222;
  color: #FFFFFF
}

.darna-button.style3:hover {
  color: #fff
}

.darna-button.style4 {
  background-color: #FFFFFF;
  border-color: #222;
  color: #222
}

.darna-button.style4:hover {
  color: #fff
}

.darna-button i {
  margin-right: 5px
}

.darna-button.icon-right i {
  margin-right: 0;
  margin-left: 5px
}
