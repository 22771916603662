a,
.top-bar ul.links li a i,
.top-bar ul.social-icons li a:hover,
.top-bar-02 ul.links li a i,
.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-nav>li>a:hover,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover,
.navbar-default .navbar-nav > li a:hover i,
.searchlink,
.navigation-02 ul.social-icons li a:hover,
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.mega-dropdown-menu > li > ul > li > a:hover,
.dropdown-submenu a:hover,
.dropdown-submenu:hover,
.nav .open.dropdown-submenu>a,
.nav .open.dropdown-submenu>a:focus,
.nav .open.dropdown-submenu>a:hover.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover,
.header-3 .main-menu-custom-text>i.fa,
.tb-left ul li i,
.tb-right ul li i,
.tp-caption.darnaverylargetextprimary,
.darnaverylargetextprimary,
.s1-box h5 a,
.project-filter a:hover,
.project-filter a.active,
.project-filter a i,
.project-overlay h4 a:hover,
.project-overlay .project-expand,
.video-content h4,
.blog-excerpt h3 a:hover,
.blog-meta a:hover,
.home-info-content h4 a:hover,
.home-info-content h4 i,
.home-info-thumb:hover i,
.counter-box-info .count,
.list li:before,
.s1-box h5,
.s3-box-ico,
.project-overlay-dark .project-expand:hover,
.error-content h2,
.error-social a:hover,
.sidebar ul.product_list_widget li a:hover,
.shopcart-table .media-body a:hover,
.shopcart-table .btn:hover,
table.shop_table.cart tbody td.product-name .product-name-wrap .product-name-inner a:hover,
.primary-color,
.result-count b,
.footer-widget a:hover,
.footer-newsletter button,
.footer-menu li.active a,
.footer-menu li a:hover,
.footer-copyright p a:hover,
.tweets_txt:before,
.home-features-side .feature-box-ico,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: $default-theme-color;
}

.navigation-03.navbar-inverse,
.navigation-03.navbar-inverse,
.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover,
.navigation-04.navbar-inverse,
.navigation-04 .navbar-inverse .navbar-nav>.active>a,
.navigation-04 .navbar-inverse .navbar-nav>.active>a:focus,
.navigation-04 .navbar-inverse .navbar-nav>.active>a:hover,
.search-form button[type=submit],
.header-customize-item .get-a-quote-button,
.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button:hover,
.header-5 .navbar-brand,
ul.vertical-images li,
.darna-button.style1,
.darna-button.style2:hover,
.darna-button.style3:hover,
.darna-button.style4:hover,
.bttn,
.project-overlay .project-view,
#home-posts .owl-controls .owl-page.active span,
.quote-info i,
.home-profile h3 i,
.s2-box-ico,
.quote-info2 i,
.error-search button,
.process-box:hover,
.spinner,
.blog-inner .entry-p-format-icon,
.darna-post .darna-post-image .entry-p-format-icon,
.blog-inner .entry-p-format-icon:after,
.darna-post .darna-post-image .entry-p-format-icon:after,
.blog-paging-wrapper .pagination > li > a.current,
.unused-pagination .pagination > li > a.current,
.blog-paging-wrapper .pagination > li > span.current,
.unused-pagination .pagination > li > span.current,
.blog-paging-wrapper .pagination > li > a:hover,
.unused-pagination .pagination > li > a:hover,
.blog-paging-wrapper .pagination > li > span:hover,
.unused-pagination .pagination > li > span:hover,
.blog-paging-wrapper .pagination > li > a:active,
.unused-pagination .pagination > li > a:active,
.blog-paging-wrapper .pagination > li > span:active,
.unused-pagination .pagination > li > span:active,
.blog-paging-wrapper .pagination > li > a:focus,
.unused-pagination .pagination > li > a:focus,
.blog-paging-wrapper .pagination > li > span:focus,
.unused-pagination .pagination > li > span:focus,
.sidebar .widget-social-profile.social-icon-bordered li a:hover i.fa,
h4.widget-title:after,
.modal-header .popup-close,
.entry-content input[type=submit],
.comment-text input[type=submit],
.cart table.cart td.actions .button:hover,
.shipping-calculator .button:hover,
.cart table.cart td.actions input.button:hover,
.shipping-calculator input.button:hover,
.cart table.cart td.actions button.button:hover,
.shipping-calculator button.button:hover,
.cart table.cart td.actions .wc-forward,
.shipping-calculator .wc-forward,
.pagination > li + li > a:focus,
.pagination li a.active,
.pagination>li>a:hover {
  background-color: $default-theme-color;
}

body::-webkit-scrollbar-thumb {
  background-color: $default-theme-color;
}

.shopping-cart-wrapper .widget_shopping_cart_content .widget_shopping_cart_icon>i.fa-shopping-cart+span,
#search .btn,
#quote .btn,
#search .close,
#quote .close,
#search .close,
#quote .close,
.bttn-black:hover,
.bttn-bdr-white:hover,
.bttn-bdr-black:hover,
#home-projects .owl-prev:hover,
#home-projects .owl-next:hover,
.blog-meta:after,
.team-social li a:hover,
.team-staff:hover .staff-info,
#team-wrap .owl-prev:hover,
#team-wrap .owl-next:hover,
#service-box3 .owl-prev:hover,
#service-box3 .owl-next:hover,
.folio-filter li a.active,
.folio-filter li a:hover,
.nav-tabs li.active:after,
#accordion .panel-heading.actives a,
.feature-box:hover .feature-box-ico,
.sidebar .tagcloud a:hover,
.ui-slider .ui-slider-range {
  background: $default-theme-color;
}

.navigation-03.navbar-inverse,
.navigation-04.navbar-inverse,
.search-form input[type=text]:focus,
.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper p.buttons .button:hover,
#search .btn,
#quote .btn,
.darna-button.style1,
.darna-button.style2:hover,
.darna-button.style3:hover,
.darna-button.style4:hover,
.bttn-black:hover,
.bttn-bdr-white:hover,
.bttn-bdr-black:hover,
.error-search input:focus,
.blog-paging-wrapper .pagination > li > a.current,
.unused-pagination .pagination > li > a.current,
.blog-paging-wrapper .pagination > li > span.current,
.unused-pagination .pagination > li > span.current,
.blog-paging-wrapper .pagination > li > a:hover,
.unused-pagination .pagination > li > a:hover,
.blog-paging-wrapper .pagination > li > span:hover,
.unused-pagination .pagination > li > span:hover,
.blog-paging-wrapper .pagination > li > a:active,
.unused-pagination .pagination > li > a:active,
.blog-paging-wrapper .pagination > li > span:active,
.unused-pagination .pagination > li > span:active,
.blog-paging-wrapper .pagination > li > a:focus,
.unused-pagination .pagination > li > a:focus,
.blog-paging-wrapper .pagination > li > span:focus,
.unused-pagination .pagination > li > span:focus,
.sidebar .widget_archive li.current-cat:before,
.sidebar .widget_categories li.current-cat:before,
.sidebar .widget_product_categories li.current-cat:before,
.sidebar .widget_nav_menu > div li.current-cat:before,
.sidebar .widget_meta li.current-cat:before,
.sidebar .widget_pages li.current-cat:before,
.sidebar .widget_recent_comments li.current-cat:before,
.sidebar .widget_recent_entries li.current-cat:before,
.sidebar .widget_rss li.current-cat:before,
.sidebar .widget_archive li.current-menu-item:before,
.sidebar .widget_categories li.current-menu-item:before,
.sidebar .widget_product_categories li.current-menu-item:before,
.sidebar .widget_nav_menu > div li.current-menu-item:before,
.sidebar .widget_meta li.current-menu-item:before,
.sidebar .widget_pages li.current-menu-item:before,
.sidebar .widget_recent_comments li.current-menu-item:before,
.sidebar .widget_recent_entries li.current-menu-item:before,
.sidebar .widget_rss li.current-menu-item:before,
.sidebar .widget-social-profile.social-icon-bordered li a:hover i.fa,
.entry-content input[type=submit],
.comment-text input[type=submit],
.border-primary-color,
.border-primary-color:after,
.border-primary-color:before,
.pagination > li + li > a:focus,
.pagination li a.active,
.pagination>li>a:hover,
.bttn,
.content-head:before,
.content-head:after,
.ui-slider .ui-slider-handle {
  border-color: $default-theme-color;
}

.nav-tabs li.active:before,
.shopping-cart-wrapper .widget_shopping_cart_content .cart_list_wrapper,
.project-filter:before,
.project-filter:after,
#accordion .panel-heading.actives a,
.contact-form form input:focus,
.contact-form form textarea:focus {
  border-top-color: $default-theme-color;
}

.blog-inner article.format-quote .entry-c-quote blockquote,
.darna-post .darna-post-image article.format-quote .entry-c-quote blockquote,
.entry-content blockquote,
.comment-text blockquote,
.entry-c-quote blockquote {
  border-left-color: $default-theme-color;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-nav>li>a:hover,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  border-bottom-color: $default-theme-color;
}

.bg-theme-color {
  background-color: $default-theme-color !important;
}

.bg-theme-color h2 a {
  color: #fff !important;
  text-shadow: 2px 2px darken($default-theme-color, 20%);
}

.bg-theme-color i {
  color: #000 !important;
  opacity: 0.1;
}

.bg-theme-color:hover i {
  color: #fff !important;
  opacity: 0.5;
}