/* -----------------------------------------------------------------------------PAGE ------------------ */
.page-head-shop.overlay-wrap {
  /*height: 300px;*/
  width: 100%;
  position: relative;
  color: #fff;
  margin-bottom: 100px;
  background-color: #2a2a2a
}

.page-head-shop.overlay-wrap .container {
  height: 100%
}

.page-head-shop.overlay-wrap .block-center {
  text-align: left
}

.overlay {
  background: $overlay;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
}

.content-head-lite h4 {
  color: #fff;
}

.content-head-lite p {
  color: #979797;
}



/* -----------------------------------------------------------------------------PAGE HEAD------------------ */

.page-head,
.page-head-blog,
.page-head-shop,
.page-head-project {
  background: #000 url(../images/head/bg-page-title.jpg) no-repeat center;
  background-size: cover;
  @include page-overlay-margin();
}

.page-head-blog {
  background: #000 url(../images/head/bg-blog-title.jpg) no-repeat center;
}

.page-head-shop {
  background: #000 url(../images/head/bg-shop-title.jpg) no-repeat center;
}

.page-head-project {
  background: #000 url(../images/head/bg-project.title.jpg) no-repeat center;
}

.page-head h1 {
  font-size: 30px;
  margin: -7px 0 10px;
  color: #fff;
  font-weight: bold;
}




/* -----------------------------------------------------------------------------BREAD CRUMBS ------------------ */

.bcrumbs li {
  font-size: 14px;
  color: #fff;
  float: left;
}

.bcrumbs li + li::before {
  content: "\/";
  display: inline-block;
  padding: 0px 5px;
}

.page-head .overlay {
  opacity: 0.8;
}